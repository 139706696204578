import { Checkbox } from "antd";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { APIStatus } from "../../../constants";
import { Locations } from "../../../constants/locations";
import { useAppDispatch, useAppSelector } from "../../../modal/hooks";
import { publish } from "../../../services/admin-product/admin-product.service";
import { clearPublish } from "../../../services/admin-product/admin-product.slice";
import { ProductList } from "../../../services/product/product.slice";
import { createLocation } from "../../../utils/util.fns";

type ProductCardProps = {
	adminProduct: ProductList;
	onProductCheck(e: number[]): void;
	checkedProduct: number[];
	loading: boolean;
	setLoading(loading: boolean): void;
};

export const AdminProductCard: React.FunctionComponent<ProductCardProps> = (props) => {
	const navigate = useNavigate();
	const adminProduct = useAppSelector((store) => store.adminProduct);
	const dispatch = useAppDispatch();

	const productChecked = (id: number) => {
		const checkedItems = [...props.checkedProduct];
		const index = checkedItems.indexOf(id);
		if (index !== -1) {
			checkedItems.splice(index, 1);
		} else {
			checkedItems.push(id);
		}
		props.onProductCheck([...checkedItems]);
	};

	const onPublish = (id: number) => {
		if (!props.loading) {
			props.setLoading(true);
			dispatch(publish([id]));
		}
	};

	useEffect(() => {
		if (adminProduct.publishCompleted === APIStatus.FULFILLED) {
			props.setLoading(false);
			dispatch(clearPublish());
		}
		if (adminProduct.publishCompleted === APIStatus.REJECTED) {
			props.setLoading(false);
			dispatch(clearPublish());
		}
	}, [adminProduct.publishCompleted]);

	return (
		<div
			className="product_list_item_container align-items-start"
			onClick={() => {
				navigate(createLocation(Locations.ADMIN_PRODUCT_EDIT, { batchId: `${props.adminProduct.batchId}` }));
			}}
		>
			<div className="flex_0_0_25 pt-2">
				<Checkbox
					checked={props.checkedProduct.includes(props.adminProduct.id)}
					onClick={(e) => {
						e.stopPropagation();
					}}
					onChange={() => productChecked(props.adminProduct.id)}
				/>
			</div>
			<div className="product_list_item_img_container pt-2">
				<img
					src={
						props.adminProduct && props.adminProduct.images && props.adminProduct.images.length
							? props.adminProduct.images[0]
							: "/images/broken-doble-img.png"
					}
				/>
			</div>
			<div className="product_list_item_label_container">
				<div className="product_list_item_label_main font_weight_bold">
					{props.adminProduct && props.adminProduct.title ? props.adminProduct.title : ""}
				</div>
				<div className="product_list_item_label_sub">
					{props.adminProduct && props.adminProduct.description ? props.adminProduct.description : ""}
				</div>
			</div>
			<div className="product_list_item_more admin_product_list_item_more pt-2 ml-2">
				<svg className="mr-2" width="23" height="22" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path
						d="M22.3287 3.01194C22.2192 3.47528 21.9164 3.8045 21.5902 4.12991C18.5157 7.19603 15.446 10.2672 12.3789 13.341C12.1848 13.5353 11.9755 13.657 11.7024 13.708C10.7732 13.8818 9.84801 14.0764 8.92005 14.257C8.40539 14.3571 8.03502 13.9849 8.13536 13.4731C8.32512 12.5034 8.51259 11.5333 8.71759 10.5667C8.75087 10.4095 8.84054 10.2411 8.95358 10.1275C12.1447 6.926 15.3429 3.73185 18.5365 0.53288C18.7766 0.292316 19.0687 0.16454 19.3814 0.0644531C19.5382 0.0644531 19.6946 0.0644531 19.8514 0.0644531C20.301 0.160984 20.6404 0.426951 20.9442 0.757187C21.1601 0.991908 21.3867 1.21825 21.6212 1.43442C21.956 1.74307 22.2303 2.08499 22.3287 2.54199C22.3287 2.69872 22.3287 2.85546 22.3287 3.01194ZM9.57697 12.8027C9.6486 12.7981 9.68975 12.8001 9.72913 12.7923C10.2725 12.684 10.8166 12.5791 11.3577 12.4602C11.4456 12.4409 11.5317 12.3701 11.5985 12.3033C14.121 9.78483 16.641 7.26411 19.1612 4.74339C19.2138 4.69081 19.2628 4.63467 19.2923 4.60317C18.7817 4.09232 18.2906 3.60077 17.7897 3.09983C17.7478 3.13971 17.6909 3.19154 17.6363 3.24615C15.2164 5.66577 12.8006 8.0897 10.3716 10.5002C10.0769 10.7926 9.87418 11.0918 9.82286 11.512C9.77104 11.9385 9.6641 12.3579 9.57697 12.8027ZM20.256 3.66199C20.4923 3.42118 20.726 3.1829 20.9594 2.94462C21.0654 2.8364 21.0847 2.72031 20.9729 2.60753C20.5774 2.2087 20.1842 1.80759 19.7764 1.42172C19.7221 1.37041 19.5491 1.35796 19.4993 1.40293C19.2326 1.644 18.9877 1.90869 18.7461 2.15434C19.2486 2.6563 19.7406 3.14759 20.256 3.66199Z"
						fill="#2C5638"
					/>
					<path
						d="M0.458808 12.7427C0.458808 10.4791 0.45576 8.21541 0.460078 5.95202C0.462111 4.8188 1.18482 3.89516 2.26901 3.63529C2.46055 3.58931 2.66301 3.57026 2.86039 3.57C5.94962 3.56594 9.03911 3.56695 12.1283 3.56772C12.6427 3.56797 12.9682 3.98026 12.8041 4.42277C12.7078 4.68188 12.5122 4.81779 12.2411 4.84598C12.1636 4.85411 12.0846 4.84878 12.0064 4.84878C8.98119 4.84878 5.95597 4.84878 2.93075 4.84903C2.16867 4.84903 1.74038 5.27656 1.74038 6.03839C1.74012 10.5159 1.74012 14.9931 1.74038 19.4706C1.74038 20.2177 2.17324 20.6534 2.91653 20.6534C7.40088 20.6536 11.8855 20.6539 16.3698 20.6534C17.1129 20.6534 17.5445 20.2177 17.5445 19.4693C17.5447 16.4228 17.5445 13.3762 17.5452 10.3294C17.5452 10.2372 17.5427 10.1425 17.5622 10.0536C17.6324 9.73729 17.9235 9.52365 18.2357 9.54931C18.5593 9.57573 18.8105 9.8389 18.8245 10.1732C18.8311 10.3294 18.826 10.4864 18.826 10.6429C18.826 13.6041 18.8276 16.5653 18.8253 19.5265C18.8243 20.678 18.1125 21.6001 17.0174 21.8648C16.8192 21.9128 16.6094 21.9319 16.4051 21.9321C11.8992 21.936 7.39351 21.9362 2.88757 21.9342C1.48102 21.9337 0.461095 20.9158 0.45957 19.5123C0.456776 17.2558 0.458808 14.9992 0.458808 12.7427Z"
						fill="#2C5638"
					/>
				</svg>
				<svg
					className="ml-2"
					onClick={(e) => {
						e.stopPropagation();
						onPublish(props.adminProduct.id);
					}}
					width="27"
					height="22"
					viewBox="0 0 27 22"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M26.4079 19.1106C26.3704 19.2585 26.3349 19.407 26.2954 19.5544C25.9177 20.9702 24.6183 21.9939 23.1473 21.995C16.5215 22.0009 9.89543 22.0024 3.26966 21.9947C1.47238 21.9927 0.0140644 20.5187 0.00550165 18.7194C-0.00335633 16.889 0.000186835 15.0584 0.00432056 13.228C0.00845428 11.3776 1.43725 9.9296 3.29329 9.89387C3.82595 9.88354 4.3589 9.88708 4.89156 9.89328C5.57746 9.90155 6.05461 10.3675 6.04752 11.0112C6.04073 11.6389 5.55709 12.0924 4.88624 12.096C4.37071 12.0986 3.85488 12.0933 3.33935 12.0974C2.67972 12.1028 2.21291 12.5539 2.21055 13.213C2.20376 15.0347 2.20405 16.8568 2.21055 18.6786C2.21291 19.3412 2.66821 19.7832 3.33817 19.7918C3.90537 19.7989 4.47258 19.7935 5.03978 19.7935C11.0039 19.7935 16.9679 19.7927 22.9323 19.7962C23.2798 19.7965 23.6008 19.7469 23.86 19.4956C24.0723 19.2898 24.199 19.0409 24.1993 18.7412C24.2008 16.8763 24.2073 15.0114 24.1954 13.1468C24.1916 12.5524 23.7106 12.1072 23.1071 12.0986C22.583 12.0912 22.0586 12.0986 21.5345 12.0966C20.8489 12.0939 20.3626 11.6359 20.3629 10.9967C20.3629 10.3645 20.8362 9.89299 21.5082 9.89683C22.2544 9.90126 23.0138 9.85697 23.7437 9.97802C25.1205 10.2063 26.1058 11.2722 26.3612 12.6534C26.3686 12.693 26.3919 12.7296 26.4079 12.7677C26.4079 14.8818 26.4079 16.9962 26.4079 19.1106Z"
						fill="#3E724C"
					/>
					<path
						d="M15.952 7.70064C15.952 7.84503 15.952 7.9466 15.952 8.04817C15.952 9.95559 15.9207 11.8636 15.9641 13.7701C15.9892 14.8653 15.2463 15.457 14.336 15.4068C13.5308 15.3625 12.7212 15.3994 11.9136 15.397C11.0358 15.3944 10.4627 14.8251 10.4609 13.9446C10.4571 11.977 10.4597 10.0096 10.4597 8.04197C10.4597 7.9404 10.4597 7.83883 10.4597 7.71452C10.3452 7.70921 10.2539 7.70153 10.1627 7.70123C9.26923 7.70005 8.37546 7.70862 7.48198 7.69769C6.83889 7.68972 6.42877 7.10332 6.67148 6.55324C6.72197 6.43868 6.80582 6.33268 6.89322 6.24085C8.78322 4.25637 10.675 2.27307 12.5694 0.292721C12.9432 -0.0982111 13.4685 -0.0982109 13.8461 0.296559C15.7409 2.27661 17.6309 4.26139 19.5244 6.24292C19.7863 6.51692 19.8896 6.82607 19.7402 7.18393C19.5867 7.55212 19.2852 7.70153 18.8975 7.70123C18.0212 7.70064 17.1448 7.70123 16.2685 7.70123C16.1749 7.70064 16.0816 7.70064 15.952 7.70064Z"
						fill="#3E724C"
					/>
				</svg>
			</div>
		</div>
	);
};
