import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import FarmerPemUpload, { FarmerPemUploadHandles } from "../../../components/common/farmer-pem-upload";
import { Footer } from "../../../components/mobile/footer";
import { Header } from "../../../components/mobile/header";
import { YahooLoginBtn } from "../../../components/mobile/yahoo-login-btn";
import { Config } from "../../../config";
import { APIStatus, emailRegex, phoneNumberRegex } from "../../../constants";
import { Locations } from "../../../constants/locations";
import { useAppDispatch, useAppSelector } from "../../../modal/hooks";
import { deleteModal } from "../../../plugins/delete-modal";
import I18 from "../../../plugins/i18";
import { ProfileUpload } from "../../../plugins/profile-upload";
import {
	deleteFarmer,
	FarmerUpdatePayload,
	fetchOneFarmer,
	updateFarmer,
} from "../../../services/farmer/farmer.service";
import { clearDeleteFarmer, clearFetchOneFarmer, clearUpdateFarmer } from "../../../services/farmer/farmer.slice";
import { showError, showSuccess } from "../../../utils/util.fns";
import "./farmer-edit.scss";

type FormData = {
	name: string;
	email: string;
	contact: string;
	availableTime: string;
	sellerId: string;
	yahooPassword: string;
	yahooShoppingId: string;
	clientId: string;
	clientSecret: string;
	password: string;
	confirmPassword: string;
	base64UserImg: string;
	isTokenAboutToExpire: boolean;
	user: { id: number };
};

type InvalidProps = {
	name: boolean;
	email: boolean;
	contact: boolean;
	availableTime: boolean;
	sellerId: boolean;
	yahooPassword: boolean;
	yahooShoppingId: boolean;
	clientId: boolean;
	clientSecret: boolean;
	password: boolean;
	confirmPassword: boolean;
	pemFile: boolean;
};

export const FarmerDetails: React.FunctionComponent = () => {
	const [profileImage, setProfileImage] = useState<string>("");
	const [formData, setFormData] = useState<FormData>({
		name: "",
		contact: "",
		availableTime: "",
		email: "",
		sellerId: "",
		yahooPassword: "",
		yahooShoppingId: "",
		clientId: "",
		clientSecret: "",
		password: "",
		confirmPassword: "",
		base64UserImg: "",
		isTokenAboutToExpire: false,
		user: { id: 0 },
	});
	const [invalid, setInvalid] = useState<InvalidProps>({
		name: false,
		email: false,
		contact: false,
		availableTime: false,
		sellerId: false,
		yahooPassword: false,
		yahooShoppingId: false,
		clientId: false,
		clientSecret: false,
		password: false,
		confirmPassword: false,
		pemFile: false,
	});
	const [loading, setLoading] = useState<boolean>(false);
	const [enableSaveBtn, setEnableSaveBtn] = useState<boolean>(false);
	const [deleteLoading, setDeleteLoading] = useState<boolean>(false);
	const [showResetPassword, setShowResetPassword] = useState<boolean>(false);
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const farmer = useAppSelector((store) => store.farmer);
	const params = useParams();
	const pemUploadRef = useRef<FarmerPemUploadHandles>(null);

	useEffect(() => {
		if (params.id) {
			dispatch(fetchOneFarmer(params.id));
		}
	}, [params.id]);

	useEffect(() => {
		if (farmer.fetchOneFarmerComplete === APIStatus.FULFILLED) {
			setFormData((prevState) => ({
				...prevState,
				user: { id: farmer.farmer.user.id },
				name: farmer.farmer.user.firstName,
				email: farmer.farmer.user.email,
				contact: farmer.farmer.contact,
				yahooShoppingId: farmer.farmer.yahooShoppingId,
				availableTime: farmer.farmer.availableTimeForCall,
				sellerId: farmer.farmer.sellerId,
				yahooPassword: farmer.farmer.yahooShoppingPassword,
				clientId: farmer.farmer.clientId,
				clientSecret: farmer.farmer.clientSecret,
				isTokenAboutToExpire: farmer.farmer.isTokenAboutToExpire,
			}));
			setProfileImage(farmer.farmer.base64UserImg);
			dispatch(clearFetchOneFarmer());
		}
		if (farmer.fetchOneFarmerComplete === APIStatus.REJECTED) {
			navigate(Locations.FARMER_LIST);
			dispatch(clearFetchOneFarmer());
		}
	}, [farmer.fetchOneFarmerComplete]);

	useEffect(() => {
		if (farmer.updateFarmerComplete === APIStatus.FULFILLED) {
			setEnableSaveBtn(false);
			pemUploadRef.current?.uploadPem(farmer.farmer.id);
			dispatch(clearUpdateFarmer());
		}
		if (farmer.updateFarmerComplete === APIStatus.REJECTED) {
			setLoading(false);
			dispatch(clearUpdateFarmer());
		}
	}, [farmer.updateFarmerComplete]);

	useEffect(() => {
		if (farmer.deleteFarmerComplete === APIStatus.FULFILLED) {
			setDeleteLoading(false);
			navigate(Locations.FARMER_LIST);
			dispatch(clearDeleteFarmer());
		}
		if (farmer.deleteFarmerComplete === APIStatus.REJECTED) {
			setDeleteLoading(false);
			dispatch(clearDeleteFarmer());
		}
	}, [farmer.deleteFarmerComplete]);

	const inputChanged = (label: string, e: React.ChangeEvent<HTMLInputElement>) => {
		setEnableSaveBtn(true);
		setFormData((prevState) => ({ ...prevState, [label]: e.target.value }));
		setInvalid((prevState) => ({ ...prevState, [label]: false }));
	};

	const validate = (): boolean => {
		const prevState: InvalidProps = JSON.parse(JSON.stringify(invalid));
		if (!formData.name || !formData.name.trim()) {
			prevState.name = true;
		}
		if (!formData.email || !formData.email.trim() || !emailRegex.test(formData.email)) {
			prevState.email = true;
		}
		if (!formData.contact || !formData.contact.trim() || !phoneNumberRegex.test(formData.contact)) {
			prevState.contact = true;
		}
		if (!formData.availableTime || !formData.availableTime.trim()) {
			prevState.availableTime = true;
		}
		if (!formData.yahooPassword || !formData.yahooPassword.trim()) {
			prevState.yahooPassword = true;
		}
		if (!formData.sellerId || !formData.sellerId.trim()) {
			prevState.sellerId = true;
		}
		if (!formData.yahooShoppingId || !formData.yahooShoppingId.trim()) {
			prevState.yahooShoppingId = true;
		}
		if (!formData.clientId || !formData.clientId.trim()) {
			prevState.clientId = true;
		}
		if (!formData.clientSecret || !formData.clientSecret.trim()) {
			prevState.clientSecret = true;
		}
		if (showResetPassword && (!formData.password || !formData.password.trim())) {
			prevState.password = true;
		} else if (showResetPassword && formData.password && formData.password.length < 8) {
			showError("PASSWORD_MIN_LENGTH");
			prevState.password = true;
		}
		if (
			showResetPassword &&
			(!formData.confirmPassword || !formData.confirmPassword.trim() || formData.password !== formData.confirmPassword)
		) {
			prevState.confirmPassword = true;
		}
		prevState.pemFile =
			pemUploadRef.current && pemUploadRef.current?.validate() ? pemUploadRef.current?.validate() : false;
		setInvalid(prevState);
		return !(
			prevState.name ||
			prevState.email ||
			prevState.contact ||
			prevState.availableTime ||
			prevState.sellerId ||
			prevState.yahooPassword ||
			prevState.yahooShoppingId ||
			prevState.clientId ||
			prevState.clientSecret ||
			prevState.password ||
			prevState.confirmPassword ||
			prevState.pemFile
		);
	};

	const update = () => {
		if (validate()) {
			setLoading(true);
			const payload: FarmerUpdatePayload = {
				contact: formData.contact ?? "",
				yahooShoppingId: formData.yahooShoppingId.trim() ?? "",
				availableTimeForCall: formData.availableTime ?? "",
				sellerId: formData.sellerId.trim() ?? "",
				yahooShoppingPassword: formData.yahooPassword.trim() ?? "",
				clientId: formData.clientId.trim() ?? "",
				clientSecret: formData.clientSecret.trim() ?? "",
				user: {
					firstName: formData.name ?? "",
					lastName: "",
					email: formData.email.trim() ?? "",
					password: formData.password.trim() ?? "",
				},
				base64UserImg: profileImage ? profileImage : "",
			};
			dispatch(updateFarmer({ farmerId: params.id ? parseInt(params.id) : 0, payload: payload }));
		}
	};

	const deleteClicked = (id: number) => {
		setDeleteLoading(true);
		dispatch(deleteFarmer(id));
	};

	const copyToClipboard = (value: string) => {
		window.navigator.clipboard.writeText(value).then(
			() => {
				showSuccess("TEXT_COPIED");
			},
			() => {
				console.log("Clipboard copy failed");
			}
		);
	};

	return (
		<>
			<Header headerText="FARMER_DETAILS_HEADER_MOB" />
			<div className="main_container">
				{farmer.farmerLoading ? (
					<div className="farmer_create_form p-4 farmer_details d-flex align-items-center justify-content-center h-100">
						<div className="button_spinner">
							<div></div>
							<div></div>
							<div></div>
							<div></div>
						</div>
					</div>
				) : null}
				{!farmer.farmerLoading ? (
					<>
						<div className="farmer_create_form px-4 pt-4 pb-2 farmer_details">
							<ProfileUpload
								image={profileImage}
								onImageChange={(image: string) => {
									setProfileImage(image), setEnableSaveBtn(true);
								}}
							/>
							<div className="mb-2">
								<div className="farmer_form_label font_weight_medium">
									<I18 tkey="NAME_INPUT" />
								</div>
								<div className="farmer_form_input_container">
									<input
										className={`${invalid.name ? "invalid_input" : ""}`}
										value={formData.name}
										type="text"
										maxLength={100}
										onChange={(e: React.ChangeEvent<HTMLInputElement>) => inputChanged("name", e)}
									/>
								</div>
							</div>
							<div className="mb-2">
								<div className="farmer_form_label font_weight_medium">
									<I18 tkey="EMAIL_INPUT" />
								</div>
								<div className="farmer_form_input_container">
									<input
										className={`${invalid.email ? "invalid_input" : ""}`}
										value={formData.email}
										type="email"
										inputMode="email"
										maxLength={100}
										onChange={(e: React.ChangeEvent<HTMLInputElement>) => inputChanged("email", e)}
									/>
								</div>
							</div>
							<div className="mb-2">
								<div className="farmer_form_label font_weight_medium">
									<I18 tkey="CONTACT_INPUT" />
								</div>
								<div className="farmer_form_input_container">
									<input
										className={`${invalid.contact ? "invalid_input" : ""}`}
										value={formData.contact}
										type="tel"
										inputMode="tel"
										maxLength={100}
										onChange={(e: React.ChangeEvent<HTMLInputElement>) => inputChanged("contact", e)}
									/>
								</div>
							</div>
							<div className="mb-2">
								<div className="farmer_form_label font_weight_medium">
									<I18 tkey="AVAILABLE_TIME_INPUT" />
								</div>
								<div className="farmer_form_input_container">
									<input
										className={`${invalid.availableTime ? "invalid_input" : ""}`}
										value={formData.availableTime}
										type="text"
										maxLength={100}
										onChange={(e: React.ChangeEvent<HTMLInputElement>) => inputChanged("availableTime", e)}
									/>
								</div>
							</div>
							<div className="mb-2">
								<div className="farmer_form_label font_weight_medium">
									<I18 tkey="YAHOO_ID_INPUT" />
								</div>
								<div className="farmer_form_input_container d-flex align-items-center">
									<input
										className={`${invalid.yahooShoppingId ? "invalid_input" : ""}`}
										value={formData.yahooShoppingId}
										type="text"
										maxLength={100}
										onChange={(e: React.ChangeEvent<HTMLInputElement>) => inputChanged("yahooShoppingId", e)}
									/>
									<button
										hidden={!window.navigator || !window.navigator.clipboard}
										className="primary_btn ml-2 border_radius_2 min_width_85"
										onClick={() => {
											copyToClipboard(formData.yahooShoppingId);
										}}
									>
										<I18 tkey="COPY" />
									</button>
								</div>
							</div>
							<div className="mb-2">
								<div className="farmer_form_label font_weight_medium">
									<I18 tkey="YAHOO_PASSWORD_INPUT" />
								</div>
								<div className="farmer_form_input_container d-flex align-items-center">
									<input
										className={`${invalid.yahooPassword ? "invalid_input" : ""}`}
										value={formData.yahooPassword}
										type="text"
										maxLength={100}
										onChange={(e: React.ChangeEvent<HTMLInputElement>) => inputChanged("yahooPassword", e)}
									/>
									<button
										hidden={!window.navigator || !window.navigator.clipboard}
										className="primary_btn ml-2 border_radius_2 min_width_85"
										onClick={() => {
											copyToClipboard(formData.yahooPassword);
										}}
									>
										<I18 tkey="COPY" />
									</button>
								</div>
							</div>
							<div className="mb-2">
								<div className="farmer_form_label font_weight_medium">
									<I18 tkey="SELLER_ID_INPUT" />
								</div>
								<div className="farmer_form_input_container">
									<input
										className={`${invalid.sellerId ? "invalid_input" : ""}`}
										value={formData.sellerId}
										type="text"
										maxLength={100}
										onChange={(e: React.ChangeEvent<HTMLInputElement>) => inputChanged("sellerId", e)}
									/>
								</div>
							</div>
							<div className="mb-2">
								<div className="farmer_form_label font_weight_medium">
									<I18 tkey="CLIENT_ID_INPUT" />
								</div>
								<div className="farmer_form_input_container">
									<input
										className={`${invalid.clientId ? "invalid_input" : ""}`}
										value={formData.clientId}
										type="text"
										maxLength={100}
										onChange={(e: React.ChangeEvent<HTMLInputElement>) => inputChanged("clientId", e)}
									/>
								</div>
							</div>
							<div className="mb-2">
								<div className="farmer_form_label font_weight_medium">
									<I18 tkey="CLIENT_SECRET_INPUT" />
								</div>
								<div className="farmer_form_input_container">
									<input
										className={`${invalid.clientSecret ? "invalid_input" : ""}`}
										value={formData.clientSecret}
										type="text"
										maxLength={100}
										onChange={(e: React.ChangeEvent<HTMLInputElement>) => inputChanged("clientSecret", e)}
									/>
								</div>
							</div>
							<div className="mb-2">
								<div className="farmer_form_label font_weight_medium">
									<I18 tkey="REDIRECT_URL" />
								</div>
								<div className="farmer_form_input_container d-flex align-items-center">
									<input
										className="textarea_input"
										value={Config.domain + "/token/user/" + formData.user.id}
										type="text"
										disabled
									/>
									<div className="ml-2">
										<button
											hidden={!window.navigator || !window.navigator.clipboard}
											className="primary_btn  border_radius_2 min_width_132"
											onClick={() => {
												copyToClipboard(Config.domain + "/token/user/" + formData.user.id);
											}}
										>
											<I18 tkey="COPY" />
										</button>
										<a
											className="primary_btn mt-2 border_radius_2 min_width_132 d-flex align-items-center justify-content-center color_white"
											target="_blank"
											rel="noreferrer"
											href={`${Config.yahooAppManagementUrl}`}
										>
											<I18 tkey="APPLICATION_PAGE_LINK" />
										</a>
									</div>
								</div>
							</div>
							<div className="mb-2">
								<div className="farmer_form_label font_weight_medium">
									<I18 tkey="PEM_FILE" />
								</div>
								<div className="farmer_form_input_container d-flex align-items-center">
									<FarmerPemUpload
										ref={pemUploadRef}
										showInvalidMsg={false}
										keyUpdateTime={farmer.farmer && farmer.farmer.keyUpdateTime ? farmer.farmer.keyUpdateTime : ""}
										loading={loading}
										sellerId={formData.sellerId}
										setEnableSaveBtn={setEnableSaveBtn}
										setLoading={setLoading}
									/>
								</div>
							</div>
						</div>
						<div className="px-2 reset_password_farmer_layout">
							<div className="reset_password_farmer_container">
								<div className="text-center">
									<button
										className="primary_btn width_335_max_100"
										onClick={() => {
											setShowResetPassword(!showResetPassword);
											setInvalid((prevState) => ({ ...prevState, password: false, confirmPassword: false }));
										}}
									>
										<I18 tkey="RESET_PASSWORD" />
									</button>
								</div>
								{showResetPassword ? (
									<div className="farmer_create_form mt-3">
										<div className="mb-2">
											<div className="farmer_form_label">
												<I18 tkey="PASSWORD_INPUT" />
											</div>
											<div className="farmer_form_input_container">
												<input
													className={`${invalid.password ? "invalid_input" : ""}`}
													value={formData.password}
													type="text"
													maxLength={100}
													onChange={(e: React.ChangeEvent<HTMLInputElement>) => inputChanged("password", e)}
												/>
											</div>
										</div>
										<div className="mb-2">
											<div className="farmer_form_label">
												<I18 tkey="CONFIRM_PASSWORD_INPUT" />
											</div>
											<div className="farmer_form_input_container">
												<input
													className={`${invalid.confirmPassword ? "invalid_input" : ""}`}
													value={formData.confirmPassword}
													type="text"
													maxLength={100}
													onChange={(e: React.ChangeEvent<HTMLInputElement>) => inputChanged("confirmPassword", e)}
												/>
											</div>
										</div>
									</div>
								) : null}
							</div>
						</div>
					</>
				) : null}
				<div className="p-3 d-flex align-items-center justify-content-center ">
					{formData.isTokenAboutToExpire ? (
						<YahooLoginBtn
							clientId={formData.clientId}
							userId={farmer.farmer && farmer.farmer.user && farmer.farmer.user.id ? farmer.farmer.user.id : 0}
						/>
					) : (
						""
					)}
					<button
						className={`primary_border_btn ${formData.isTokenAboutToExpire ? "min_width_160" : "width_335_max_100"}`}
						disabled={deleteLoading || farmer.farmerLoading}
						hidden={deleteLoading}
						onClick={() =>
							deleteModal({
								id: params.id ? parseInt(params.id) : 0,
								content: "DELETE_FARMER_CONTENT",
								delete: deleteClicked,
							})
						}
					>
						<I18 tkey="REMOVE_FARMER_BTN" />
					</button>
					<button className="primary_border_btn min_width_160" hidden={!deleteLoading} disabled>
						<div className="button_spinner">
							<div></div>
							<div></div>
							<div></div>
							<div></div>
						</div>
					</button>
				</div>
			</div>
			<Footer
				primaryText="SAVE_BTN"
				secondaryText="CANCEL_BTN"
				primaryBtnChange={update}
				secondaryBtnChange={() => navigate(Locations.FARMER_LIST)}
				primaryBtnDisable={loading || farmer.farmerLoading || !enableSaveBtn}
				secondaryBtnDisable={loading || farmer.farmerLoading}
				showPrimaryLoader={loading}
			/>
		</>
	);
};
