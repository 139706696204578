import React, { forwardRef, useState, ForwardRefRenderFunction, useImperativeHandle, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Config } from "../../../config";
import { APIStatus } from "../../../constants";
import { Locations } from "../../../constants/locations";
import { useAppDispatch, useAppSelector } from "../../../modal/hooks";
import I18, { i18Get } from "../../../plugins/i18";
import { uploadPemFile } from "../../../services/farmer/farmer.service";
import { clearUploadPemFile } from "../../../services/farmer/farmer.slice";
import { validatePemFile } from "../../../utils/util.fns";

type FarmerPemUploadProps = {
	showInvalidMsg: boolean;
	loading: boolean;
	keyUpdateTime: string;
	sellerId: string;
	setLoading(loading: boolean): void;
	setEnableSaveBtn?(enable: boolean): void;
};

export type FarmerPemUploadHandles = {
	uploadPem(farmerId: number): void;
	validate(): boolean;
};

const FarmerPemUpload: ForwardRefRenderFunction<FarmerPemUploadHandles, FarmerPemUploadProps> = (props, ref) => {
	const [file, setFile] = useState<File | null>(null);
	const [data, setData] = useState<string | undefined>(undefined);
	const [invalid, setInvalid] = useState<boolean>(false);
	const fileRef = useRef<HTMLInputElement>(null);
	const dispatch = useAppDispatch();
	const farmer = useAppSelector((store) => store.farmer);
	const navigate = useNavigate();

	useImperativeHandle(ref, () => ({
		uploadPem: (farmerId: number) => {
			if (file) {
				const formData = new FormData();
				formData.append("file", file);
				dispatch(uploadPemFile({ id: farmerId, formData: formData }));
			} else if (data) {
				const formData = new FormData();
				formData.append("key", data);
				dispatch(uploadPemFile({ id: farmerId, formData: formData }));
			} else {
				props.setLoading(false);
				navigate(Locations.FARMER_LIST);
			}
		},
		validate: () => {
			setInvalid(!file && !data && !props.keyUpdateTime ? true : false);
			return !file && !data && !props.keyUpdateTime ? true : false;
		},
	}));

	useEffect(() => {
		if (farmer.uploadPemFileComplete === APIStatus.FULFILLED) {
			props.setLoading(false);
			navigate(Locations.FARMER_LIST);
			dispatch(clearUploadPemFile());
		}
		if (farmer.uploadPemFileComplete === APIStatus.REJECTED) {
			props.setLoading(false);
			dispatch(clearUploadPemFile());
		}
	}, [farmer.uploadPemFileComplete]);

	const textChanged = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
		setData(e.target.value ? e.target.value : undefined);
		setInvalid(false);
		setFile(null);
		props.setEnableSaveBtn && props.setEnableSaveBtn(true);
	};

	const fileChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (e.target.files && validatePemFile(e.target.files[0])) {
			setInvalid(false);
			props.setEnableSaveBtn && props.setEnableSaveBtn(true);
			setFile(e.target.files ? e.target.files[0] : null);
			setData("");
		}
	};

	return (
		<>
			<textarea
				className={`${invalid ? "invalid_input" : ""}`}
				placeholder={
					props.keyUpdateTime
						? `${i18Get("LAST_UPDATED", Config.defaultLanguage)}: ` + new Date(props.keyUpdateTime).toString()
						: i18Get("NO_ENCRYPTION_KEY", Config.defaultLanguage)
				}
				value={file && file.name ? file.name : data ? data : undefined}
				onChange={textChanged}
			/>
			<div className="ml-2">
				<button
					className="primary_btn border_radius_2_mob_4_web min_width_135"
					onClick={() => fileRef.current?.click()}
				>
					<I18 tkey="UPLOAD_BTN" />
				</button>
				{props.sellerId && (
					<a
						className="primary_btn mt-2 border_radius_2 min_width_142 d-flex align-items-center color_white justify-content-center"
						target="_blank"
						rel="noreferrer"
						href={Config.proStoreLink + `/pro.${props.sellerId}/setting/key_manage`}
					>
						<I18 tkey="ENCRYPTION_PAGE_LINK" />
					</a>
				)}
			</div>
			<input ref={fileRef} hidden type="file" onChange={fileChanged} accept=".pem" />
			{invalid && props.showInvalidMsg ? (
				<span className="invalid_message">
					<I18 tkey="ENTER_PEM_FILE" />
				</span>
			) : (
				""
			)}
		</>
	);
};

export default forwardRef(FarmerPemUpload);
