import React, { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { APIStatus, LocalStorageKeys, Roles } from "../../../constants";
import { Locations } from "../../../constants/locations";
import { useAppDispatch, useAppSelector } from "../../../modal/hooks";
import { getCurrentUSerDetails } from "../../../services/user/user.service";
import { clearCurrentUser, clearGetCurrentUser } from "../../../services/user/user.slice";

export const MainContainer: React.FunctionComponent = () => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const location = useLocation();
	const user = useAppSelector((store) => store.user);

	useEffect(() => {
		if ((!user.user || !user.user.id) && localStorage.getItem(LocalStorageKeys.TOKEN)) {
			dispatch(getCurrentUSerDetails());
		}
		if (location.pathname !== window.location.pathname) {
			navigate(window.location.pathname);
		}
	}, []);

	useEffect(() => {
		if((user.getCurrentUserComplete === APIStatus.FULFILLED) && (window.location.pathname === Locations.BASE)) {
			(user.user.role === Roles.FARMER) ? navigate(Locations.UPLOAD_MODE) : navigate(Locations.ITEM_LIST);
		}
		if(user.getCurrentUserComplete === APIStatus.REJECTED) {
			dispatch(clearCurrentUser());
			localStorage.removeItem(LocalStorageKeys.TOKEN);
			dispatch(clearGetCurrentUser());
		}
	}, [user.getCurrentUserComplete]);

	return (
		<>
			<Outlet />
		</>
	);
};
