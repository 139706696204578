import { RootReducer } from "../modal/root-reducer";
import productReducer from "../services/product/product.slice";
import itemReducer from "../services/item/item.slice";
import userReducer from "../services/user/user.slice";
import farmerReducer from "../services/farmer/farmer.slice";
import farmerSupportedItemReducer from "../services/farmer-supported-items/farmer-supported-items.slice";
import adminProductReducer from "../services/admin-product/admin-product.slice";

export const rootReducer: RootReducer = {
	product: productReducer,
	item: itemReducer,
	user: userReducer,
	farmer: farmerReducer,
	farmerSupportedItem: farmerSupportedItemReducer,
	adminProduct: adminProductReducer,
};
