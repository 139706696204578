import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { SingleValue } from "react-select";
import { FarmerFilter } from "../../../components/mobile/farmer-filter";
import { AdminProductListTable } from "../../../components/web/admin-product-list-table";
import { useAppDispatch } from "../../../modal/hooks";
import I18 from "../../../plugins/i18";
import { publish } from "../../../services/admin-product/admin-product.service";
import "./admin-product-list.scss";

type Option = {
	label: string | undefined;
	value: string | undefined;
};

export const AdminProductList: React.FunctionComponent = () => {
	const [farmer, setFarmer] = useState<SingleValue<Option>>();
	const [checkedProduct, setCheckedProducts] = useState<number[]>([]);
	const [loading, setLoading] = useState<boolean>(false);
	const dispatch = useAppDispatch();
	const [searchParams] = useSearchParams();

	useEffect(() => {
		const farmer = searchParams.get("farmer");
		if (farmer) {
			setFarmer(JSON.parse(farmer));
		}
	}, []);

	const onPublish = () => {
		setLoading(true);
		dispatch(publish(checkedProduct));
	};

	return (
		<div className="main_content_container main_content_container_no_footer admin_product_list_container">
			<div className="px-3">
				<div className="main_content_heading mb-0 d-flex align-items-center justify-content-between">
					<div className="min_width_150">
						<I18 tkey="ADMIN_PRODUCTS_HEADER_WEB" />
					</div>
					<div className="font-body z_index_9 d-flex align-items-center">
						<button
							className={`primary_btn mr-2  min_width_150 ${!checkedProduct.length || loading ? "disabled_btn" : ""}`}
							disabled={!checkedProduct.length || loading}
							onClick={onPublish}
						>
							<I18 tkey="PUBLISH_ALL" />
						</button>
						<div className="min_width_250">
							<FarmerFilter farmer={farmer} onFarmerChange={(e: SingleValue<Option>) => setFarmer(e)} />
						</div>
					</div>
				</div>
			</div>
			<div className="product_list_container">
				<AdminProductListTable
					checkedProduct={checkedProduct}
					onProductCheck={(e: number[]) => setCheckedProducts(e)}
					farmerId={farmer && farmer.value ? farmer.value : null}
					loading={loading}
					setLoading={setLoading}
				/>
			</div>
		</div>
	);
};
