import React, { useEffect, useState } from "react";
import Select, { SingleValue, components, DropdownIndicatorProps } from "react-select";
import { useAppDispatch, useAppSelector } from "../../../modal/hooks";
import I18 from "../../../plugins/i18";
import { fetchCategory } from "../../../services/item/item.service";
import { clearCategory } from "../../../services/item/item.slice";

interface CategorySelectProps {
	categoryName: string | undefined;
	categoryId: number | undefined;
	disabled?: boolean;
	categoryChanged(categoryName: string | undefined, categoryId: number | undefined): void;
	invalid: boolean;
}

type Option = {
	label: string | undefined;
	value: number | undefined;
};

export const CategorySelect: React.FunctionComponent<CategorySelectProps> = (props) => {
	const [pagination, setPagination] = useState<{ pageNumber: number; numResults: number }>({
		pageNumber: 0,
		numResults: 100,
	});
	const dispatch = useAppDispatch();
	const item = useAppSelector((store) => store.item);

	useEffect(() => {
		return () => {
			dispatch(clearCategory());
		};
	}, []);

	useEffect(() => {
		dispatch(fetchCategory({ pageNumber: pagination.pageNumber, numResults: pagination.numResults }));
	}, [pagination.pageNumber]);

	const onScroll = () => {
		if ((item.categoryList.length < item.categoryCount) && !item.categoryListLoading) {
			setPagination((prevState) => ({ ...prevState, pageNumber: ++prevState.pageNumber }));
		}
	};

	const DropdownIndicator = (props: DropdownIndicatorProps<Option, false>) => {
		return (
			<components.DropdownIndicator {...props}>
				<i className="fas fa-caret-down"></i>
			</components.DropdownIndicator>
		);
	};

	return (
		<Select
			className={`react_select ${props.invalid ? "invalid_react_select" : ""}`}
			isDisabled={props.disabled}
			isLoading={item.categoryListLoading}
			components={{ DropdownIndicator }}
			value={{
				label: props.categoryName,
				value: props.categoryId,
			}}
			menuPlacement="auto"
			options={item.categoryList.map((el) => ({ label: el.categoryName, value: el.id }))}
			loadingMessage={() => <><I18 tkey="LOADING"/>...</>}
			noOptionsMessage={() => <I18 tkey="NO_OPTIONS"/>}
			placeholder={<I18 tkey="SELECT"/>}
			onMenuScrollToBottom={onScroll}
			onChange={(e: SingleValue<Option>) => {
				props.categoryChanged(e?.label, e?.value);
			}}
		/>
	);
};
