import React from "react";
import { useAppSelector } from "../../../modal/hooks";
import { HeaderProps } from "../modal";
import "./header.scss";

export const Header: React.FunctionComponent<HeaderProps> = (props) => {
	const user = useAppSelector((store) => store.user.user);

	return (
		<div className={`custom_header ${props.class}`}>
			<div className="header_logo_container font_weight_medium">OJEN</div>
			<div className="header_main_content d-flex align-items-center justify-content-end">
				<div className="header_user_drop_down">
					<span className="header_user_drop_down_logo">
						<div className="profile_pic_container">
							<img
								src={
									user && user.base64UserImg
										? user.base64UserImg
										: user.role === "ROLE_SITE_OPERATOR"
											? "/images/admin_user.png"
											: "/images/user.png"
								}
							/>
						</div>
					</span>
					<span className="header_user_drop_down_label">{user && user.firstName ? user.firstName : "User"}</span>
				</div>
			</div>
		</div>
	);
};
