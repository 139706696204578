import React, { forwardRef, useState, ForwardRefRenderFunction, useImperativeHandle } from "react";
import { CategorySelect } from "../../common/category-select";
import I18 from "../../../plugins/i18";
import { numberRegex } from "../../../constants";

interface Item {
	itemName: string | undefined;
	title: string;
	description: string;
	itemConceptName: string;
	category: { categoryName: string | undefined; categoryId: number | undefined };
	salesPeriod: string;
}

interface ItemInvalid {
	itemName: boolean;
	title: boolean;
	description: boolean;
	itemConceptName: boolean;
	category: boolean;
	salesPeriod: boolean;
}

export interface ItemFormHandles {
	getFormData(): Item;
	validate(): boolean;
	setLoading(status: boolean): void;
	setFormData(data: Item): void;
}

const ItemForm: ForwardRefRenderFunction<ItemFormHandles> = (props, ref) => {
	const [formData, setFormData] = useState<Item>({
		itemName: undefined,
		title: "",
		description: "",
		itemConceptName: "",
		category: { categoryName: undefined, categoryId: undefined },
		salesPeriod: "",
	});
	const [invalid, setInvalid] = useState<ItemInvalid>({
		itemName: false,
		title: false,
		description: false,
		itemConceptName: false,
		category: false,
		salesPeriod: false,
	});
	const [loading, setLoading] = useState<boolean>(false);

	useImperativeHandle(ref, () => ({
		getFormData: (): Item => formData,
		setFormData: (data: Item) => {
			setFormData(data);
		},
		setLoading: (status: boolean) => {
			setLoading(status);
		},
		validate: () => {
			const prevState: ItemInvalid = JSON.parse(JSON.stringify(invalid));
			if (!formData.itemName) {
				prevState.itemName = true;
			}
			if (!formData.title || !formData.title.trim()) {
				prevState.title = true;
			}
			if (!formData.description || !formData.description.trim()) {
				prevState.description = true;
			}
			if (!formData.itemConceptName) {
				prevState.itemConceptName = true;
			}
			if (!formData.category.categoryId) {
				prevState.category = true;
			}
			if (
				!formData.salesPeriod ||
				!formData.salesPeriod.toString().trim() ||
				!numberRegex.test(formData.salesPeriod) ||
				parseInt(formData.salesPeriod) < 1 ||
				parseInt(formData.salesPeriod) > 2147483647
			) {
				prevState.salesPeriod = true;
			}
			setInvalid(prevState);
			return !(
				prevState.itemName ||
				prevState.title ||
				prevState.description ||
				prevState.itemConceptName ||
				prevState.category ||
				prevState.salesPeriod
			);
		},
	}));

	const categoryChanged = (categoryName: string | undefined, categoryId: number | undefined) => {
		setFormData((prevState) => ({
			...prevState,
			category: { categoryName: categoryName, categoryId: categoryId },
		}));
		setInvalid((prevState) => ({ ...prevState, category: false }));
	};

	return (
		<div className="product_form_container">
			<div className="mb-2">
				<div className="product_form_label font_weight_medium">
					<I18 tkey="NAME" />
				</div>
				<div className="product_form_input_container">
					<input
						className={`${invalid.itemName ? "invalid_input" : ""}`}
						type="text"
						maxLength={100}
						disabled={loading}
						value={formData.itemName ? formData.itemName : undefined}
						onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
							setFormData((prevState) => ({ ...prevState, itemName: e.target.value }));
							setInvalid((prevState) => ({ ...prevState, itemName: false }));
						}}
					/>
				</div>
			</div>
			<div className="mb-2">
				<div className="product_form_label font_weight_medium">
					<I18 tkey="ITEM_CONCEPT_NAME" />
				</div>
				<div className="product_form_input_container">
					<input
						className={`${invalid.itemConceptName ? "invalid_input" : ""}`}
						disabled={loading}
						maxLength={100}
						value={formData.itemConceptName}
						onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
							setFormData((prevState) => ({ ...prevState, itemConceptName: e.target.value }));
							setInvalid((prevState) => ({ ...prevState, itemConceptName: false }));
						}}
					/>
				</div>
			</div>
			<div className="mb-2">
				<div className="product_form_label font_weight_medium">
					<I18 tkey="TITLE_INPUT" />
				</div>
				<div className="product_form_input_container">
					<input
						className={`${invalid.title ? "invalid_input" : ""}`}
						type="text"
						maxLength={75}
						disabled={loading}
						value={formData.title ? formData.title : undefined}
						onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
							setFormData((prevState) => ({ ...prevState, title: e.target.value }));
							setInvalid((prevState) => ({ ...prevState, title: false }));
						}}
					/>
				</div>
			</div>
			<div className="mb-2">
				<div className="product_form_label font_weight_medium">
					<I18 tkey="DESCRIPTION_INPUT" />
				</div>
				<div className="product_form_input_container position-relative">
					<div className="remaining_characters">
						{formData.description && formData.description.length ? formData.description.length : 0}/500
					</div>
					<textarea
						maxLength={500}
						className={`${invalid.description ? "invalid_input" : ""}`}
						disabled={loading}
						value={formData.description}
						onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
							setFormData((prevState) => ({ ...prevState, description: e.target.value }));
							setInvalid((prevState) => ({ ...prevState, description: false }));
						}}
					></textarea>
				</div>
			</div>
			<div className="mb-2">
				<div className="product_form_label font_weight_medium">
					<I18 tkey="CATEGORY" />
				</div>
				<div className="product_form_input_container position-relative">
					<CategorySelect
						categoryId={formData.category.categoryId}
						categoryName={formData.category.categoryName}
						disabled={loading}
						categoryChanged={categoryChanged}
						invalid={invalid.category}
					/>
				</div>
			</div>
			<div className="mb-2">
				<div className="product_form_label font_weight_medium">
					<I18 tkey="SALES_PERIOD" />
				</div>
				<div className="product_form_input_container position-relative">
					<input
						className={`${invalid.salesPeriod ? "invalid_input" : ""}`}
						type="number"
						inputMode="numeric"
						min={1}
						maxLength={2147483647}
						disabled={loading}
						value={formData.salesPeriod ? formData.salesPeriod : undefined}
						onKeyDown={(e) => {
							if (e.code === "Minus" || e.code === "NumpadSubtract") {
								e.preventDefault();
							}
						}}
						onPaste={(e) => {
							const clipboardData = e.clipboardData || window.Clipboard;
							const pastedData = parseFloat(clipboardData.getData("text"));
							if (pastedData < 0) {
								e.preventDefault();
							}
						}}
						onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
							if (!e.target.value || (e.target.value && numberRegex.test(e.target.value))) {
								setFormData((prevState) => ({ ...prevState, salesPeriod: e.target.value }));
								setInvalid((prevState) => ({ ...prevState, salesPeriod: false }));
							}
						}}
					/>
				</div>
			</div>
		</div>
	);
};

export default forwardRef(ItemForm);
