import React from "react";
import { Select } from "antd";
import { i18Get } from "../../../plugins/i18";
import { Config } from "../../../config";

interface InventoryCancellationProps {
	value: number | null;
	disabled: boolean;
	valueChanged(value: number | null): void;
}

export const inventoryCancellationOptions = [
	{ value: 0, label: i18Get("INVENTORY_CANCELLATION_0", Config.defaultLanguage) },
	{ value: 1, label: i18Get("INVENTORY_CANCELLATION_1", Config.defaultLanguage) },
];

export const InventoryCancellation: React.FunctionComponent<InventoryCancellationProps> = (props) => {
	return (
		<Select
			className="w-100"
			defaultValue={inventoryCancellationOptions[0].value}
			disabled={props.disabled}
			value={props.value ?? inventoryCancellationOptions[0].value}
			options={inventoryCancellationOptions}
			onChange={props.valueChanged}
		/>
	);
};
