import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import FarmerSupportedItemForm, {
	FarmerSupportedItemHandles,
} from "../../../components/web/farmer-supported-item-form";
import { APIStatus } from "../../../constants";
import { Locations } from "../../../constants/locations";
import { useAppDispatch, useAppSelector } from "../../../modal/hooks";
import { deleteModal } from "../../../plugins/delete-modal";
import I18 from "../../../plugins/i18";
import {
	deleteFarmerSupportedItem,
	fetchOneFarmerSupportedItem,
	updateFarmerSupportedItem,
} from "../../../services/farmer-supported-items/farmer-supported-items.service";
import {
	clearDeleteFarmerSupportedItem,
	clearFarmerSupportedItem,
	clearFetchOneFarmerSupportedItem,
	clearUpdateFarmerSupportedItem,
} from "../../../services/farmer-supported-items/farmer-supported-items.slice";

export const FarmerSupportedItemDetails: React.FunctionComponent = () => {
	const [loading, setLoading] = useState<string>("");
	const formRef = useRef<FarmerSupportedItemHandles>(null);
	const farmerSupportedItem = useAppSelector((store) => store.farmerSupportedItem);
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const params = useParams();

	useEffect(() => {
		if (params.id) {
			dispatch(fetchOneFarmerSupportedItem(params.id));
		} else {
			navigate(-1);
		}
		return () => {
			dispatch(clearFarmerSupportedItem());
		};
	}, [params.id]);

	useEffect(() => {
		if (farmerSupportedItem.fetchOneFarmerSupportedItemComplete === APIStatus.FULFILLED && formRef.current) {
			formRef.current.setLoading(false);
			formRef.current.setFormData({
				itemName: farmerSupportedItem.farmerSupportedItem.item.itemName,
				itemId: farmerSupportedItem.farmerSupportedItem.item.id,
				title: farmerSupportedItem.farmerSupportedItem.title,
				description: farmerSupportedItem.farmerSupportedItem.description,
				categoryId: farmerSupportedItem.farmerSupportedItem.itemCategory.id,
				categoryName: farmerSupportedItem.farmerSupportedItem.itemCategory.categoryName,
				salesPeriod: farmerSupportedItem.farmerSupportedItem.itemNumberOfActiveDays,
			});
			dispatch(clearFetchOneFarmerSupportedItem());
		}
		if (farmerSupportedItem.fetchOneFarmerSupportedItemComplete === APIStatus.REJECTED) {
			navigate(Locations.FARMER_SUPPORTED_ITEMS_LIST);
			dispatch(clearFetchOneFarmerSupportedItem());
		}
	}, [farmerSupportedItem.fetchOneFarmerSupportedItemComplete]);

	useEffect(() => {
		if (farmerSupportedItem.updateFarmerSupportedItemComplete === APIStatus.FULFILLED) {
			formRef.current?.setLoading(false);
			setLoading("");
			dispatch(clearUpdateFarmerSupportedItem());
			navigate(Locations.FARMER_SUPPORTED_ITEMS_LIST);
		}
		if (farmerSupportedItem.updateFarmerSupportedItemComplete === APIStatus.REJECTED) {
			formRef.current?.setLoading(false);
			setLoading("");
			dispatch(clearUpdateFarmerSupportedItem());
		}
	}, [farmerSupportedItem.updateFarmerSupportedItemComplete]);

	useEffect(() => {
		if (farmerSupportedItem.deleteFarmerSupportedItemComplete === APIStatus.FULFILLED) {
			formRef.current?.setLoading(false);
			setLoading("");
			navigate(Locations.FARMER_SUPPORTED_ITEMS_LIST);
			dispatch(clearDeleteFarmerSupportedItem());
		}
		if (farmerSupportedItem.deleteFarmerSupportedItemComplete === APIStatus.REJECTED) {
			formRef.current?.setLoading(false);
			setLoading("");
			dispatch(clearDeleteFarmerSupportedItem());
		}
	}, [farmerSupportedItem.deleteFarmerSupportedItemComplete]);

	const update = () => {
		if (formRef.current?.validate() && params.id) {
			formRef.current.setLoading(true);
			setLoading("UPDATE");
			const formData = formRef.current.getFormData();
			dispatch(
				updateFarmerSupportedItem({
					settingsId: params.id,
					settings: {
						itemName: formData.itemName ? formData.itemName : "",
						id: formData.itemId ? formData.itemId : 0,
						title: formData.title ? formData.title : "",
						description: formData.description ? formData.description : "",
						itemCategory: { id: formData.categoryId },
						itemNumberOfActiveDays: formData.salesPeriod ? formData.salesPeriod : "",
					},
				})
			);
		}
	};

	const farmerSupportedItemDelete = () => {
		if (formRef.current) {
			formRef.current.setLoading(true);
			setLoading("DELETE");
			dispatch(deleteFarmerSupportedItem(farmerSupportedItem.farmerSupportedItem.id));
		}
	};

	return (
		<>
			<div className="main_content_container">
				<div className="main_content_section ">
					<div className="main_content_heading">
						{farmerSupportedItem.farmerSupportedItem &&
						farmerSupportedItem.farmerSupportedItem.item &&
						farmerSupportedItem.farmerSupportedItem.item.itemName
							? farmerSupportedItem.farmerSupportedItem.item.itemName
							: ""}
					</div>
					{farmerSupportedItem.farmerSupportedItemLoading ? (
						<div className="farmer_create_form px-4 py-3 d-flex align-items-center justify-content-center">
							<div>
								<div className="button_spinner">
									<div></div>
									<div></div>
									<div></div>
									<div></div>
								</div>
							</div>
						</div>
					) : null}
					{!farmerSupportedItem.farmerSupportedItemLoading ? <FarmerSupportedItemForm ref={formRef} /> : null}
				</div>
				<div className="main_footer_section d-flex align-items-center justify-content-end">
					<button
						className="primary_border_btn min_width_120 mr-3"
						onClick={() => navigate(Locations.FARMER_SUPPORTED_ITEMS_LIST)}
					>
						<I18 tkey="CANCEL_BTN" />
					</button>
					<button
						className={`primary_border_btn min_width_120 mr-3 ${
							loading !== "" || farmerSupportedItem.farmerSupportedItemLoading ? "disabled_btn" : ""
						}`}
						hidden={loading === "DELETE"}
						disabled={loading !== "" || farmerSupportedItem.farmerSupportedItemLoading}
						onClick={() => {
							deleteModal({
								id: farmerSupportedItem.farmerSupportedItem.id,
								content: "DELETE_MODAL_CONTENT",
								delete: farmerSupportedItemDelete,
							});
						}}
					>
						<I18 tkey="DELETE_BTN" />
					</button>
					<button className="primary_border_btn min_width_120 mr-3" hidden={loading !== "DELETE"} disabled>
						<div className="button_spinner">
							<div></div>
							<div></div>
							<div></div>
							<div></div>
						</div>
					</button>
					<button
						className="primary_btn min_width_120"
						hidden={loading === "UPDATE"}
						disabled={farmerSupportedItem.farmerSupportedItemLoading}
						onClick={update}
					>
						<I18 tkey="SAVE_BTN" />
					</button>
					<button className="primary_btn min_width_120" hidden={loading !== "UPDATE"} disabled>
						<div className="button_spinner">
							<div></div>
							<div></div>
							<div></div>
							<div></div>
						</div>
					</button>
				</div>
			</div>
		</>
	);
};
