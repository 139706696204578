import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Footer } from "../../../components/mobile/footer";
import { Header } from "../../../components/mobile/header";
import { APIStatus } from "../../../constants";
import { useAppDispatch, useAppSelector } from "../../../modal/hooks";
import { uploadFile } from "../../../services/product/product.service";
import { clearImage, clearUploadFile, saveImage } from "../../../services/product/product.slice";
import "./image-capture.scss";
import { Locations } from "../../../constants/locations";
import { createLocation } from "../../../utils/util.fns";


export const ImageCapture: React.FunctionComponent = () => {
	const [captureComplete, setCaptureComplete] = useState<boolean>(true);
	const [uploading, setUploading] = useState<boolean>(false);
	const inputRef = useRef<HTMLInputElement>(null);
	const navigate = useNavigate();
	const product = useAppSelector((store) => store.product);
	const dispatch = useAppDispatch();

	useEffect(() => {
		if (!product.uploadImage) {
			navigate(Locations.UPLOAD_MODE);
		} 
	}, []);

	useEffect(() => {
		if(!captureComplete && inputRef.current) {
			inputRef.current.click();
		}
	}, [captureComplete]);

	useEffect(() => {
		if (product.fileUploadComplete === APIStatus.FULFILLED) {
			navigate(createLocation(Locations.PRODUCT_CREATE, { batchId: product.batchId ? `${product.batchId}` : "" }));
			dispatch(clearUploadFile());
			setUploading(false);
		}
		if (product.fileUploadComplete === APIStatus.REJECTED) {
			setUploading(false);
			dispatch(clearUploadFile());
		}
	}, [product.fileUploadComplete]);

	const clearPhoto = () => {
		setCaptureComplete(false);
	};

	const onFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		if(e.target.files && e.target.files.length) {
			dispatch(saveImage(e.target.files[0]));
			setCaptureComplete(true);
		}
	};

	const fileUpload = () => {
		const payload: FormData = new FormData();
		if (product.uploadImage) {
			payload.append("file", product.uploadImage);
			dispatch(uploadFile(payload));
			setUploading(true);
		}
	};

	const cancelClicked = () => {
		dispatch(clearImage());
		navigate(Locations.UPLOAD_MODE);
	};

	return (
		<>
			<Header headerText="UPLOAD_MODE_HEADER" />
			<div className="main_container p-3">
				<input 
					ref={inputRef} 
					hidden
					type="file" 
					accept="image/*"
					onChange={onFileChange} 
					capture="environment">
				</input>
				<div className="h-100 d-flex align-items-center justify-content-center">
					<img
						className="image_capture_container_img"
						alt=""
						src={product.uploadImage ? URL.createObjectURL(product.uploadImage) : ""}
					/>
				</div>
			</div>
			<Footer
				primaryText={captureComplete ? "UPLOAD_BTN" : "CAPTURE_BTN"}
				secondaryText={captureComplete ? "REUPLOAD_BTN" : "CANCEL_BTN"}
				primaryBtnDisable={uploading || !captureComplete}
				secondaryBtnDisable={uploading}
				showPrimaryLoader={uploading}
				primaryBtnChange={fileUpload}
				secondaryBtnChange={captureComplete ? clearPhoto : cancelClicked}
			/>
		</>
	);
};
