import React, { useEffect } from "react";
import { RouterProvider } from "react-router-dom";
import { Config } from "../config";
import { mobileRouter } from "../locations/mobile";
import { webRouter } from "../locations/web";
import { AuthRouter } from "../plugins/auth-router";
import { checkIsMobile } from "../utils/util.fns";
import { Language } from "../constants";
import { ConfigProvider } from "antd";
import enUS from "antd/locale/en_US";
import jaJP from "antd/locale/ja_JP";
import dayjs from "dayjs";
import "dayjs/locale/ja";

export const Router: React.FunctionComponent = () => {
	const lang: string = Config.defaultLanguage;

	useEffect(() => {
		dayjs.locale((lang === Language.ENGLISH) ? "en" : "ja");
	}, [lang]);

	return (
		<ConfigProvider locale={(lang === Language.ENGLISH) ? enUS : jaJP}>
			<div className={`h-100 ${lang === Language.ENGLISH ? "roboto_font" : "noto_sans_font"}`}>
				<AuthRouter>
					{checkIsMobile() ? (
						<div className="is_mobile h-100">
							<RouterProvider router={mobileRouter} />
						</div>
					) : (
						<div className="h-100 is_web">
							<RouterProvider router={webRouter} />
						</div>
					)}
				</AuthRouter>
			</div>
		</ConfigProvider>
	);
};
