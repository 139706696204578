import { Modal } from "antd";
import React, { forwardRef, ForwardRefRenderFunction, useImperativeHandle, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Locations } from "../../../constants/locations";
import I18 from "../../../plugins/i18";
import { checkIsMobile } from "../../../utils/util.fns";

export interface ProductCreateSuccessModalHandles {
	openModal(): void;
	closeModal(): void;
}

const ProductCreateSuccessModal: ForwardRefRenderFunction<ProductCreateSuccessModalHandles> = (props, ref) => {
	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
	const navigate = useNavigate();

	useImperativeHandle(ref, () => ({
		openModal: () => {
			setIsModalOpen(true);
		},
		closeModal: () => {
			setIsModalOpen(false);
		},
	}));

	const changeLocation = (path: string) => {
		setIsModalOpen(false);
		navigate(path);
	};

	return (
		<Modal
			className={`product_created_modal ${checkIsMobile() ? "is_mobile_modal" : "is_web_modal"}`}
			title={null}
			open={isModalOpen}
			footer={null}
		>
			<div className="product_created_modal_content">
				<div>
					<div className="product_created_modal_head font_weight_bold mb-3">
						<I18 tkey="PRODUCT_SUCCESS_MODAL_TITLE" />
					</div>
					<div className="product_created_modal_dec mb-2">
						<I18 tkey="PRODUCT_REFLECT_INFO_1" />
					</div>
					<div className="product_created_modal_dec mb-2">
						<I18 tkey="PRODUCT_REFLECT_INFO_2" />
					</div>
					<div className="product_created_modal_dec mb-2">
						<I18 tkey="PRODUCT_SUCCESS_MODAL_DESCRIPTION" />
					</div>
				</div>
				<div className="mb-3">
					<button
						className="primary_btn width_100_max_350_btn border_radius_10 height_40"
						onClick={() => {
							changeLocation(Locations.UPLOAD_MODE);
						}}
					>
						<I18 tkey="MAKE_NEW_PRODUCT_BTN" />
					</button>
				</div>
				<div>
					<button
						className="primary_border_btn width_100_max_350_btn border_radius_10 height_40"
						onClick={() => {
							changeLocation(Locations.PRODUCT_LIST);
						}}
					>
						<I18 tkey="PRODUCT_LIST_BTN" />
					</button>
				</div>
			</div>
		</Modal>
	);
};

export default forwardRef(ProductCreateSuccessModal);
