import React from "react";
import { Select } from "antd";
import { i18Get } from "../../../plugins/i18";
import { Config } from "../../../config";

interface ProductDeliveryProps {
	delivery: number | null;
    disabled: boolean;
	deliveryChanged(delivery: number | null): void;
}

export const productDeliveryOptions = [
	{ value: 0, label: i18Get("DELIVERY_0", Config.defaultLanguage) },
	{ value: 1, label: i18Get("DELIVERY_1", Config.defaultLanguage) },
	{ value: 2, label: i18Get("DELIVERY_2", Config.defaultLanguage) },
	{ value: 3, label: i18Get("DELIVERY_3", Config.defaultLanguage) }
];

export const ProductDelivery: React.FunctionComponent<ProductDeliveryProps> = (props) => {

	return (
		<Select
			className="w-100"
			defaultValue={productDeliveryOptions[0].value}
			disabled={props.disabled}
			value={props.delivery ?? productDeliveryOptions[0].value}
			options={productDeliveryOptions}
			onChange={props.deliveryChanged}/>
	);
};
