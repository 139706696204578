import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ItemForm, { ItemFormHandles } from "../../../components/web/item-form";
import { APIStatus } from "../../../constants";
import { Locations } from "../../../constants/locations";
import { useAppDispatch, useAppSelector } from "../../../modal/hooks";
import I18 from "../../../plugins/i18";
import { fetchOneItemById, updateItem } from "../../../services/item/item.service";
import { clearFetchOneItemById, clearUpdateItem } from "../../../services/item/item.slice";

export const ItemDetails: React.FunctionComponent = () => {
	const [loading, setLoading] = useState<boolean>(false);
	const itemFormRef = useRef<ItemFormHandles>(null);
	const item = useAppSelector((store) => store.item);
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const params = useParams();

	useEffect(() => {
		if (params.itemId) {
			dispatch(fetchOneItemById(params.itemId));
		} else {
			navigate(-1);
		}
		return () => {
			dispatch(clearFetchOneItemById());
		};
	}, [params.batchId]);

	useEffect(() => {
		if (item.fetchOneItemByIdComplete === APIStatus.FULFILLED && itemFormRef.current) {
			itemFormRef.current.setLoading(false);
			itemFormRef.current.setFormData({
				itemName: item.itemById.itemName,
				title: item.itemById.title,
				description: item.itemById.description,
				itemConceptName: item.itemById.itemConceptName,
				category: { categoryId: item.itemById.itemCategory.id, categoryName: item.itemById.itemCategory.categoryName },
				salesPeriod: `${item.itemById.itemNumberOfActiveDays}`,
			});
			dispatch(clearFetchOneItemById());
		}
		if (item.fetchOneItemByIdComplete === APIStatus.REJECTED) {
			navigate(Locations.ITEM_LIST);
			dispatch(clearFetchOneItemById());
		}
	}, [item.fetchOneItemByIdComplete]);

	useEffect(() => {
		if (item.updateItemComplete === APIStatus.FULFILLED) {
			itemFormRef.current?.setLoading(false);
			setLoading(false);
			dispatch(clearUpdateItem());
			navigate(Locations.ITEM_LIST);
		}
		if (item.updateItemComplete === APIStatus.REJECTED) {
			itemFormRef.current?.setLoading(false);
			setLoading(false);
			dispatch(clearUpdateItem());
		}
	}, [item.updateItemComplete]);

	const itemUpdate = () => {
		if (itemFormRef.current?.validate() && params.itemId) {
			itemFormRef.current.setLoading(true);
			setLoading(true);
			const formData = itemFormRef.current.getFormData();
			dispatch(
				updateItem({
					itemId: params.itemId,
					item: {
						itemName: formData.itemName ? formData.itemName.trim() : "",
						itemConceptName: formData.itemConceptName ? formData.itemConceptName.trim() : "",
						title: formData.title ? formData.title : "",
						description: formData.description ? formData.description : "",
						itemCategory: { id: formData.category.categoryId },
						itemNumberOfActiveDays: formData.salesPeriod ? formData.salesPeriod : "",
					},
				})
			);
		}
	};

	return (
		<>
			<div className="main_content_container">
				<div className="main_content_section ">
					<div className="main_content_heading">
						<I18 tkey="ITEM_UPDATE" />
					</div>
					{item.itemLoading ? (
						<div className="farmer_create_form px-4 py-3 d-flex align-items-center justify-content-center">
							<div className="button_spinner">
								<div></div>
								<div></div>
								<div></div>
								<div></div>
							</div>
						</div>
					) : null}
					{!item.itemLoading ? <ItemForm ref={itemFormRef} /> : null}
				</div>
				<div className="main_footer_section d-flex align-items-center justify-content-end">
					<button className="primary_border_btn min_width_120 mr-3" onClick={() => navigate(Locations.ITEM_LIST)}>
						<I18 tkey="CANCEL_BTN" />
					</button>
					<button
						className="primary_btn min_width_120"
						hidden={loading}
						disabled={item.itemLoading}
						onClick={itemUpdate}
					>
						<I18 tkey="SAVE_BTN" />
					</button>
					<button className="primary_btn min_width_120" hidden={!loading} disabled>
						<div className="button_spinner">
							<div></div>
							<div></div>
							<div></div>
							<div></div>
						</div>
					</button>
				</div>
			</div>
		</>
	);
};
