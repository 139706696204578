import { Checkbox } from "antd";
import React, { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { APIStatus } from "../../../constants";
import { Locations } from "../../../constants/locations";
import { PaginationWithNumber } from "../../../modal/common";
import { useAppDispatch, useAppSelector } from "../../../modal/hooks";
import I18 from "../../../plugins/i18";
import {
	AdminProductQuery,
	fetchAllAdminProducts,
	publish,
} from "../../../services/admin-product/admin-product.service";
import {
	clearFetchAllAdminProducts,
	clearFetchAllAdminProductsList,
	clearPublish,
} from "../../../services/admin-product/admin-product.slice";
import { createLocation } from "../../../utils/util.fns";

interface AdminProductListTableProps {
	farmerId: string | null;
	onProductCheck(e: number[]): void;
	checkedProduct: number[];
	loading: boolean;
	setLoading(loading: boolean): void;
}

export const AdminProductListTable: React.FunctionComponent<AdminProductListTableProps> = (props) => {
	const [pagination, setPagination] = useState<PaginationWithNumber>({ pageNumber: 0, numResults: 100 });
	const adminProduct = useAppSelector((store) => store.adminProduct);
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();

	useEffect(() => {
		return () => {
			dispatch(clearFetchAllAdminProducts());
			dispatch(clearFetchAllAdminProductsList());
		};
	}, []);

	useEffect(() => {
		const farmer = searchParams.get("farmer");
		if (farmer) {
			navigate(Locations.ADMIN_PRODUCTS_LIST);
		}
		fetchAdminProductList(farmer ? JSON.parse(farmer).value : props.farmerId);
	}, [pagination.pageNumber, props.farmerId]);

	const fetchAdminProductList = (farmerId: string | null): void => {
		let query: AdminProductQuery = { pageNumber: pagination.pageNumber, numResults: pagination.numResults };
		if (farmerId) {
			query = { ...query, userId: farmerId };
		}
		dispatch(fetchAllAdminProducts({ ...query }));
	};
	const pageScrolled = (e: React.UIEvent<HTMLDivElement>) => {
		if (adminProduct.products.length < adminProduct.productCount) {
			const tableViewHeight = e.currentTarget.offsetHeight;
			const tableScrollHeight = e.currentTarget.scrollHeight;
			const scrollLocation = e.currentTarget.scrollTop;
			const buffer = 200;
			const limit = tableScrollHeight - tableViewHeight - buffer;
			if (scrollLocation > limit && !adminProduct.productListLoading) {
				setPagination((prevState) => ({ ...prevState, pageNumber: ++prevState.pageNumber }));
			}
		}
	};

	const productChecked = (id: number) => {
		const checkedItems = [...props.checkedProduct];
		const index = checkedItems.indexOf(id);
		if (index != -1) {
			checkedItems.splice(index, 1);
		} else {
			checkedItems.push(id);
		}
		props.onProductCheck([...checkedItems]);
	};

	const onPublish = (id: number) => {
		props.setLoading(true);
		dispatch(publish([id]));
	};

	useEffect(() => {
		if (adminProduct.publishCompleted === APIStatus.FULFILLED) {
			dispatch(clearFetchAllAdminProductsList());
			fetchAdminProductList(props.farmerId);
			props.setLoading(false);
			dispatch(clearPublish());
		}
		if (adminProduct.publishCompleted === APIStatus.REJECTED) {
			props.setLoading(false);
			dispatch(clearPublish());
		}
	}, [adminProduct.publishCompleted]);

	return (
		<div className="h-100 overflow-auto position-relative" onScroll={pageScrolled}>
			<table className="custom_primary_table">
				<thead>
					<tr>
						<th className="width_50">
							<div className="custom_primary_table_th font_weight_regular text-center">&nbsp;</div>
						</th>
						<th className="width_75">
							<div className="custom_primary_table_th font_weight_regular text-center">&nbsp;</div>
						</th>
						<th>
							<div className="custom_primary_table_th font_weight_regular">
								<I18 tkey="PRODUCT_TABLE_HEADER" />
							</div>
						</th>
						<th>
							<div className="custom_primary_table_th font_weight_regular text-center">
								<I18 tkey="TITLE_TABLE_HEADER" />
							</div>
						</th>
						<th>
							<div className="custom_primary_table_th font_weight_regular">
								<I18 tkey="DESCRIPTION_TABLE_HEADER" />
							</div>
						</th>
						<th>
							<div className="custom_primary_table_th font_weight_regular text-center">
								<I18 tkey="PRICE_TABLE_HEADER" />
							</div>
						</th>
						<th className="table_column_width_280">
							<div className="custom_primary_table_th font_weight_regular text-center ">
								<I18 tkey="ACTION_TABLE_HEADER" />
							</div>
						</th>
					</tr>
				</thead>
				<tbody>
					{adminProduct &&
						adminProduct.products.map((data) => (
							<tr key={data.id}>
								<td>
									<div className="custom_primary_table_td line_height_30 text-center">
										<Checkbox
											checked={props.checkedProduct.includes(data.id)}
											onChange={() => productChecked(data.id)}
										/>
									</div>
								</td>
								<td>
									<div className="custom_primary_table_td line_height_30 text-center">
										<img
											className="admin_product_list_img"
											src={data.images && data.images.length ? data.images[0] : "/images/broken-doble-img.png"}
										/>
									</div>
								</td>
								<td>
									<div className="custom_primary_table_td line_height_30">{data.item.itemName}</div>
								</td>
								<td>
									<div className="custom_primary_table_td line_height_30 text-center">{data.title}</div>
								</td>
								<td>
									<div className="custom_primary_table_td line_height_30">{data.description}</div>
								</td>
								<td>
									<div className="custom_primary_table_td line_height_30 text-center">{data.price}</div>
								</td>
								<td>
									<div className="custom_primary_table_td line_height_30 text-center">
										<button
											className="primary_btn mr-2"
											onClick={() => {
												navigate(createLocation(Locations.ADMIN_PRODUCT_EDIT, { batchId: `${data.batchId}` }));
											}}
										>
											<I18 tkey="VIEW_OR_EDIT_BTN" />
										</button>
										<button className="primary_btn" disabled={props.loading} onClick={() => onPublish(data.id)}>
											<I18 tkey="PUBLISH" />
										</button>
									</div>
								</td>
							</tr>
						))}
				</tbody>
			</table>
			{(!adminProduct.products || !adminProduct.products.length) && !adminProduct.productListLoading ? (
				<div className="shade_loading">
					<I18 tkey="NO_PRODUCT_DATA" />
				</div>
			) : null}
			{adminProduct.productListLoading || props.loading ? (
				<div className="shade_loading">
					<div className="button_spinner">
						<div></div>
						<div></div>
						<div></div>
						<div></div>
					</div>
				</div>
			) : null}
		</div>
	);
};
