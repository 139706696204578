export enum Language {
	ENGLISH = "en",
	JAPANESE = "ja",
}

export enum APIStatus {
	FULFILLED = "FULFILLED",
	REJECTED = "REJECTED",
}

export const priceRegex = /^[0-9]+$/;

export const quantityRegex = /^[0-9]+((,|\.)?[0-9])*$/;

export const emailRegex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;

export const phoneNumberRegex = /^\d{2}(?:-\d{4}-\d{4}|\d{8}|\d-\d{3,4}-\d{4})$/;

export const numberRegex = /^[0-9]+$/;

export enum LocalStorageKeys {
	TOKEN = "TOKEN",
}

export enum Roles {
	ROLE_SITE_OPERATOR = "ROLE_SITE_OPERATOR",
	FARMER = "ROLE_FARMER",
}

export enum ProductStatus {
	PENDING_CREATION = "PENDING_CREATION",
	READY_FOR_PUBLISH = "READY_FOR_PUBLISH",
	PUBLISHED = "PUBLISHED",
	MARKED_AS_SOLD = "MARKED_AS_SOLD",
	PUBLISH_PERIOD_END = "PUBLISH_PERIOD_END",
}
