// export enum Config {
// 	apiUrl = "http://3.113.179.133:8092/api/v1",
// 	apiSecureUrl = "http://3.113.179.133:8092/api/v1/secure",
// 	env = "dev",
// 	defaultLanguage = "ja",
// 	maxImagesPerProduct = 5,
// 	domain = "http://localhost:3000",
// 	proStoreLink = "https://test.pro.store.yahoo.co.jp",
// 	yahooAppManagementUrl = "https://e.developer.yahoo.co.jp/dashboard",
// }

export enum Config {
	apiUrl = "/api/v1",
	apiSecureUrl = "/api/v1/secure",
	env = "production",
	defaultLanguage = "ja",
	maxImagesPerProduct = 5,
	domain = "https://airbandc.com",
	proStoreLink = "https://pro.store.yahoo.co.jp",
	yahooAppManagementUrl = "https://e.developer.yahoo.co.jp/dashboard"
}

// export enum Config {
// 	apiUrl = "/api/v1",
// 	apiSecureUrl = "/api/v1/secure",
// 	env = "dev",
// 	defaultLanguage = "ja",
// 	maxImagesPerProduct = 5,
// 	domain = "https://ojen.simplogics.com",
// 	proStoreLink = "https://test.pro.store.yahoo.co.jp",
// 	yahooAppManagementUrl = "https://e.developer.yahoo.co.jp/dashboard"
// }

// export enum Config {
// 	apiUrl = "http://localhost:3000/api/v1",
// 	apiSecureUrl = "http://localhost:3000/api/v1/secure",
// 	env = "dev",
// 	defaultLanguage = "en",
// 	maxImagesPerProduct = 5,
// 	domain = "http://localhost:3000",
// 	proStoreLink = "https://test.pro.store.yahoo.co.jp",
// 	yahooAppManagementUrl = "https://e.developer.yahoo.co.jp/dashboard"
// }
