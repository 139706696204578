import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Footer } from "../../../components/mobile/footer";
import { Header } from "../../../components/mobile/header";
import FarmerSupportedItemForm, {
	FarmerSupportedItemHandles,
} from "../../../components/mobile/farmer-supported-item-form";
import { APIStatus } from "../../../constants";
import { Locations } from "../../../constants/locations";
import { useAppDispatch, useAppSelector } from "../../../modal/hooks";
import { createFarmerSupportedItem } from "../../../services/farmer-supported-items/farmer-supported-items.service";
import { clearCreateFarmerSupportedItem } from "../../../services/farmer-supported-items/farmer-supported-items.slice";

export const FarmerSupportedItemCreate: React.FunctionComponent = () => {
	const [loading, setLoading] = useState<boolean>(false);
	const formRef = useRef<FarmerSupportedItemHandles>(null);
	const farmerSupportedItem = useAppSelector((store) => store.farmerSupportedItem);
	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	useEffect(() => {
		if (farmerSupportedItem.createFarmerSupportedItemComplete === APIStatus.FULFILLED) {
			setLoading(false);
			formRef.current?.setLoading(false);
			dispatch(clearCreateFarmerSupportedItem());
			navigate(Locations.FARMER_SUPPORTED_ITEMS_LIST);
		}
		if (farmerSupportedItem.createFarmerSupportedItemComplete === APIStatus.REJECTED) {
			setLoading(false);
			formRef.current?.setLoading(false);
			dispatch(clearCreateFarmerSupportedItem());
		}
	}, [farmerSupportedItem.createFarmerSupportedItemComplete]);

	const create = () => {
		if (formRef.current?.validate()) {
			formRef.current.setLoading(true);
			setLoading(true);
			const formData = formRef.current.getFormData();
			dispatch(
				createFarmerSupportedItem({
					itemName: formData.itemName ? formData.itemName : "",
					id: formData.itemId ? formData.itemId : 0,
					title: formData.title ? formData.title : "",
					description: formData.description ? formData.description : "",
					itemCategory: { id: formData.categoryId },
					itemNumberOfActiveDays: formData.salesPeriod ? formData.salesPeriod : "",
				})
			);
		}
	};

	return (
		<>
			<Header headerText="FARMER_SUPPORTED_ITEMS_CREATE" />
			<div className="main_container">
				<div className="farmer_create_form p-3">
					<FarmerSupportedItemForm ref={formRef} />
				</div>
			</div>
			<Footer
				showPrimaryLoader={loading}
				primaryBtnDisable={loading}
				secondaryBtnDisable={loading}
				primaryText="CREATE_BTN"
				secondaryText="CANCEL_BTN"
				primaryBtnChange={create}
				secondaryBtnChange={() => {
					navigate(Locations.FARMER_SUPPORTED_ITEMS_LIST);
				}}
			/>
		</>
	);
};
