import React from "react";
import { createBrowserRouter, Navigate } from "react-router-dom";
import { UploadMode } from "../../containers/web/upload-mode";
import { ImagePreview } from "../../containers/web/image-preview";
import { ErrorBoundary } from "../../plugins/error-boundary";
import { ProductCreate } from "../../containers/web/product-create";
import { ImageCapture } from "../../containers/web/image-capture";
import { ProductList } from "../../containers/web/product-list";
import { Locations } from "../../constants/locations";
import { MainContainer } from "../../containers/web/main-container";
import { Login } from "../../containers/web/login";
import { FarmerList } from "../../containers/web/farmer-list";
import { ProductDetails } from "../../containers/web/product-details";
import "./web-main-container.scss";
import { FarmerCreate } from "../../containers/web/farmer-create";
import { ItemList } from "../../containers/web/item-list";
import { ItemCreate } from "../../containers/web/item-create";
import { FarmerSupportedItemList } from "../../containers/web/farmer-supported-item";
import { FarmerSupportedItemCreate } from "../../containers/web/farmer-supported-item-create";
import { FarmerDetails } from "../../containers/web/farmer-details";
import { ItemDetails } from "../../containers/web/item-details";
import { FarmerSupportedItemDetails } from "../../containers/web/farmer-supported-item-details";
import { ForgotPassword } from "../../containers/web/forgot-password";
import { ResetPassword } from "../../containers/web/reset-password";
import { AdminProductList } from "../../containers/web/admin-product-list";
import { GenerateToken } from "../../containers/web/generate-token";
import { RoleGuard } from "../../plugins/role-guard";
import { Roles } from "../../constants";

export const webRouter = createBrowserRouter([
	{
		path: Locations.BASE,
		element: <MainContainer />,
		errorElement: <ErrorBoundary />,
		children: [
			{
				path: Locations.UPLOAD_MODE,
				element: <RoleGuard role={[Roles.FARMER]}><UploadMode /></RoleGuard>,
				errorElement: <ErrorBoundary />,
			},
			{
				path: Locations.IMAGE_CAPTURE,
				element: <RoleGuard role={[Roles.FARMER]}><ImageCapture /></RoleGuard>,
				errorElement: <ErrorBoundary />,
			},
			{
				path: Locations.IMAGE_PREVIEW,
				element: <RoleGuard role={[Roles.FARMER]}><ImagePreview /></RoleGuard>,
				errorElement: <ErrorBoundary />,
			},
			{
				path: Locations.PRODUCT_CREATE,
				element: <RoleGuard role={[Roles.FARMER]}><ProductCreate /></RoleGuard>,
				errorElement: <ErrorBoundary />,
			},
			{
				path: Locations.PRODUCT_LIST,
				element: <RoleGuard role={[Roles.FARMER]}><ProductList /></RoleGuard>,
				errorElement: <ErrorBoundary />,
			},
			{
				path: Locations.PRODUCT_DETAILS,
				element: <RoleGuard role={[Roles.FARMER]}><ProductDetails /></RoleGuard>,
				errorElement: <ErrorBoundary />,
			},
			{
				path: Locations.FARMER_LIST,
				element: <RoleGuard role={[Roles.ROLE_SITE_OPERATOR]}><FarmerList /></RoleGuard>,
				errorElement: <ErrorBoundary />,
			},
			{
				path: Locations.FARMER_CREATE,
				element: <RoleGuard role={[Roles.ROLE_SITE_OPERATOR]}><FarmerCreate /></RoleGuard>,
				errorElement: <ErrorBoundary />,
			},
			{
				path: Locations.ITEM_LIST,
				element: <RoleGuard role={[Roles.ROLE_SITE_OPERATOR]}><ItemList /></RoleGuard>,
				errorElement: <ErrorBoundary />,
			},
			{
				path: Locations.ITEM_CREATE,
				element: <RoleGuard role={[Roles.ROLE_SITE_OPERATOR]}><ItemCreate /></RoleGuard>,
				errorElement: <ErrorBoundary />,
			},
			{
				path: Locations.FARMER_SUPPORTED_ITEMS_LIST,
				element: <RoleGuard role={[Roles.FARMER]}><FarmerSupportedItemList /></RoleGuard>,
				errorElement: <ErrorBoundary />,
			},
			{
				path: Locations.FARMER_SUPPORTED_ITEMS_CREATE,
				element: <RoleGuard role={[Roles.FARMER]}><FarmerSupportedItemCreate /></RoleGuard>,
				errorElement: <ErrorBoundary />,
			},
			{
				path: Locations.FARMER_SUPPORTED_ITEMS_DETAILS,
				element: <RoleGuard role={[Roles.FARMER]}><FarmerSupportedItemDetails /></RoleGuard>,
				errorElement: <ErrorBoundary />,
			},
			{
				path: Locations.FARMER_DETAILS,
				element: <RoleGuard role={[Roles.ROLE_SITE_OPERATOR]}><FarmerDetails /></RoleGuard>,
				errorElement: <ErrorBoundary />,
			},
			{
				path: Locations.ITEM_DETAILS,
				element: <RoleGuard role={[Roles.ROLE_SITE_OPERATOR]}><ItemDetails /></RoleGuard>,
				errorElement: <ErrorBoundary />,
			},
			{
				path: Locations.ADMIN_PRODUCTS_LIST,
				element: <RoleGuard role={[Roles.ROLE_SITE_OPERATOR]}><AdminProductList /></RoleGuard>,
				errorElement: <ErrorBoundary />,
			},
			{
				path: Locations.ADMIN_PRODUCT_EDIT,
				element: <RoleGuard role={[Roles.ROLE_SITE_OPERATOR]}><ProductDetails /></RoleGuard>,
				errorElement: <ErrorBoundary />,
			},
			{
				path: Locations.GENERATE_TOKEN,
				element: <RoleGuard role={[Roles.ROLE_SITE_OPERATOR]}><GenerateToken /></RoleGuard>,
				errorElement: <ErrorBoundary />,
			}
		],
	},
	{
		path: Locations.LOGIN,
		element: <Login />,
		errorElement: <ErrorBoundary />,
	},
	{
		path: Locations.FORGOT_PASSWORD,
		element: <ForgotPassword />,
		errorElement: <ErrorBoundary />,
	},
	{
		path: Locations.RESET_PASSWORD,
		element: <ResetPassword />,
		errorElement: <ErrorBoundary />,
	},
	{
		path: "*",
		element: <Navigate to={Locations.BASE} />,
		errorElement: <ErrorBoundary />,
	},
]);