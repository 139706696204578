import React from "react";
import { useNavigate } from "react-router-dom";
import { HeaderProps } from "../modal";
import I18 from "../../../plugins/i18";
import "./header.scss";
import { useAppDispatch, useAppSelector } from "../../../modal/hooks";
import { clearImage } from "../../../services/product/product.slice";
import { Locations } from "../../../constants/locations";

export const Header: React.FunctionComponent<HeaderProps> = (props) => {
	const navigate = useNavigate();
	const disable = useAppSelector((store) => store.product.disableSideBar);
	const user = useAppSelector((store) => store.user.user);
	const dispatch = useAppDispatch();

	const backClicked = () => {
		if (!disable) {
			dispatch(clearImage());
			navigate(-1);
		}
	};

	return (
		<div className={`custom_header ${props.class}`}>
			<div className="header_user_container d-none" onClick={() => navigate(Locations.SETTINGS)}>
				<div className="profile_pic_container">
					<img
						src={
							user && user.base64UserImg
								? user.base64UserImg
								: user.role === "ROLE_SITE_OPERATOR"
									? "/images/admin_user.png"
									: "/images/user.png"
						}
					/>
				</div>
			</div>
			<div className="header_back_nav_box font_weight_bold">
				<a onClick={backClicked}>
					<i className="fas fa-arrow-left"></i>
				</a>
			</div>
			<div className="header_text text-center font_weight_black">
				<I18 tkey={props.headerText} />
			</div>
			<div className="header_back_nav_box_filler"></div>
		</div>
	);
};
