import React, { useEffect, useState } from "react";
import Select, { SingleValue, components, DropdownIndicatorProps } from "react-select";
import { useAppDispatch, useAppSelector } from "../../../modal/hooks";
import I18 from "../../../plugins/i18";
import { fetchItem } from "../../../services/item/item.service";
import { clearItems } from "../../../services/item/item.slice";

interface ItemsDropdownProps {
	itemId: number | undefined;
	itemName: string | undefined;
	disabled?: boolean;
	itemChanged(itemId: number | undefined, itemName: string | undefined): void;
	invalid: boolean;
}

type Option = {
	label: string | undefined;
	value: number | undefined;
};

export const ItemsDropdown: React.FunctionComponent<ItemsDropdownProps> = (props) => {
	const [pagination, setPagination] = useState<{ pageNumber: number; numResults: number }>({
		pageNumber: 0,
		numResults: 100,
	});
	const dispatch = useAppDispatch();
	const item = useAppSelector((store) => store.item);

	useEffect(() => {
		return () => {
			dispatch(clearItems());
		};
	}, []);

	useEffect(() => {
		dispatch(fetchItem({ pageNumber: pagination.pageNumber, numResults: pagination.numResults }));
	}, [pagination.pageNumber]);

	const onScroll = () => {
		if ((item.items.length < item.itemCount) && !item.itemListLoading) {
			setPagination((prevState) => ({ ...prevState, pageNumber: ++prevState.pageNumber }));
		}
	};

	const DropdownIndicator = (props: DropdownIndicatorProps<Option, false>) => {
		return (
			<components.DropdownIndicator {...props}>
				<i className="fas fa-caret-down"></i>
			</components.DropdownIndicator>
		);
	};

	return (
		<Select
			className={`react_select ${props.invalid ? "invalid_react_select" : ""}`}
			isDisabled={props.disabled}
			isLoading={item.itemListLoading}
			components={{ DropdownIndicator }}
			value={{ 
				label: props.itemName, 
				value: props.itemId 
			}}
			options={item.items.map((el) => ({ label: el.itemName, value: el.id }))}
			menuPlacement="auto"
			loadingMessage={() => <><I18 tkey="LOADING"/>...</>}
			noOptionsMessage={() => <I18 tkey="NO_OPTIONS"/>}
			placeholder={<I18 tkey="SELECT"/>}
			onMenuScrollToBottom={onScroll}
			onChange={(e: SingleValue<Option>) => {
				props.itemChanged(e?.value, e?.value ? item.items.find(el => el.id === e?.value)?.itemName : undefined);
			}}
		/>
	);
};
