import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Footer } from "../../../components/mobile/footer";
import { Header } from "../../../components/mobile/header";
import FarmerSupportedItemForm, {
	FarmerSupportedItemHandles,
} from "../../../components/mobile/farmer-supported-item-form";
import { APIStatus } from "../../../constants";
import { Locations } from "../../../constants/locations";
import { useAppDispatch, useAppSelector } from "../../../modal/hooks";
import {
	deleteFarmerSupportedItem,
	fetchOneFarmerSupportedItem,
	updateFarmerSupportedItem,
} from "../../../services/farmer-supported-items/farmer-supported-items.service";
import {
	clearDeleteFarmerSupportedItem,
	clearFarmerSupportedItem,
	clearFetchOneFarmerSupportedItem,
	clearUpdateFarmerSupportedItem,
} from "../../../services/farmer-supported-items/farmer-supported-items.slice";
import { deleteModal } from "../../../plugins/delete-modal";

export const FarmerSupportedItemDetails: React.FunctionComponent = () => {
	const [loading, setLoading] = useState<string>("");
	const formRef = useRef<FarmerSupportedItemHandles>(null);
	const farmerSupportedItem = useAppSelector((store) => store.farmerSupportedItem);
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const params = useParams();

	useEffect(() => {
		if (params.id) {
			dispatch(fetchOneFarmerSupportedItem(params.id));
		} else {
			navigate(-1);
		}
		return () => {
			dispatch(clearFarmerSupportedItem());
		};
	}, [params.id]);

	useEffect(() => {
		if (farmerSupportedItem.fetchOneFarmerSupportedItemComplete === APIStatus.FULFILLED && formRef.current) {
			if (!farmerSupportedItem.farmerSupportedItem.id) {
				navigate(Locations.FARMER_SUPPORTED_ITEMS_LIST);
			}
			formRef.current.setLoading(false);
			formRef.current.setFormData({
				itemId: farmerSupportedItem.farmerSupportedItem.item.id,
				itemName: farmerSupportedItem.farmerSupportedItem.item.itemName,
				title: farmerSupportedItem.farmerSupportedItem.title,
				description: farmerSupportedItem.farmerSupportedItem.description,
				categoryId: farmerSupportedItem.farmerSupportedItem.itemCategory.id,
				categoryName: farmerSupportedItem.farmerSupportedItem.itemCategory.categoryName,
				salesPeriod: farmerSupportedItem.farmerSupportedItem.itemNumberOfActiveDays,
			});
			dispatch(clearFetchOneFarmerSupportedItem());
		}
		if (farmerSupportedItem.fetchOneFarmerSupportedItemComplete === APIStatus.REJECTED) {
			navigate(Locations.FARMER_SUPPORTED_ITEMS_LIST);
			dispatch(clearFetchOneFarmerSupportedItem());
		}
	}, [farmerSupportedItem.fetchOneFarmerSupportedItemComplete]);

	useEffect(() => {
		if (farmerSupportedItem.updateFarmerSupportedItemComplete === APIStatus.FULFILLED) {
			formRef.current?.setLoading(false);
			setLoading("");
			dispatch(clearUpdateFarmerSupportedItem());
			navigate(Locations.FARMER_SUPPORTED_ITEMS_LIST);
		}
		if (farmerSupportedItem.updateFarmerSupportedItemComplete === APIStatus.REJECTED) {
			formRef.current?.setLoading(false);
			setLoading("");
			dispatch(clearUpdateFarmerSupportedItem());
		}
	}, [farmerSupportedItem.updateFarmerSupportedItemComplete]);

	useEffect(() => {
		if (farmerSupportedItem.deleteFarmerSupportedItemComplete === APIStatus.FULFILLED) {
			formRef.current?.setLoading(false);
			setLoading("");
			navigate(Locations.FARMER_SUPPORTED_ITEMS_LIST);
			dispatch(clearDeleteFarmerSupportedItem());
		}
		if (farmerSupportedItem.deleteFarmerSupportedItemComplete === APIStatus.REJECTED) {
			formRef.current?.setLoading(false);
			setLoading("");
			dispatch(clearDeleteFarmerSupportedItem());
		}
	}, [farmerSupportedItem.deleteFarmerSupportedItemComplete]);

	const settingsUpdate = () => {
		if (formRef.current?.validate() && params.id) {
			formRef.current.setLoading(true);
			setLoading("UPDATE");
			const formData = formRef.current.getFormData();
			dispatch(
				updateFarmerSupportedItem({
					settingsId: params.id,
					settings: {
						itemName: formData.itemName ? formData.itemName : "",
						id: formData.itemId ? formData.itemId : 0,
						title: formData.title ? formData.title : "",
						description: formData.description ? formData.description : "",
						itemCategory: { id: formData.categoryId },
						itemNumberOfActiveDays: formData.salesPeriod ? formData.salesPeriod : "",
					},
				})
			);
		}
	};

	const deleteClicked = () => {
		if (formRef.current) {
			formRef.current.setLoading(true);
			setLoading("DELETE");
			dispatch(deleteFarmerSupportedItem(farmerSupportedItem.farmerSupportedItem.id));
		}
	};

	return (
		<>
			<Header
				headerText={
					farmerSupportedItem.farmerSupportedItem &&
					farmerSupportedItem.farmerSupportedItem.item &&
					farmerSupportedItem.farmerSupportedItem.item.itemName
						? farmerSupportedItem.farmerSupportedItem.item.itemName
						: ""
				}
			/>
			<div className="main_container">
				{farmerSupportedItem.farmerSupportedItemLoading ? (
					<div className="h-100 d-flex align-items-center justify-content-center">
						<div className="button_spinner">
							<div></div>
							<div></div>
							<div></div>
							<div></div>
						</div>
					</div>
				) : null}
				{!farmerSupportedItem.farmerSupportedItemLoading ? (
					<div className="farmer_create_form p-3">
						<FarmerSupportedItemForm ref={formRef} />
					</div>
				) : null}
			</div>
			<Footer
				showPrimaryLoader={loading === "UPDATE"}
				primaryBtnDisable={loading !== "" || farmerSupportedItem.farmerSupportedItemLoading}
				showSecondaryLoader={loading === "DELETE"}
				secondaryBtnDisable={loading !== "" || farmerSupportedItem.farmerSupportedItemLoading}
				primaryText="SAVE_BTN"
				secondaryText="DELETE_BTN"
				primaryBtnChange={settingsUpdate}
				secondaryBtnChange={() => {
					deleteModal({
						id: farmerSupportedItem.farmerSupportedItem.id,
						content: "DELETE_MODAL_CONTENT",
						delete: deleteClicked,
					});
				}}
			/>
		</>
	);
};
