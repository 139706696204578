import React from "react";
import { ProductListTable } from "../../../components/web/product-list-table";
import I18 from "../../../plugins/i18";
import "./product-list.scss";

export const ProductList: React.FunctionComponent = () => {
	return (
		<div className="main_content_container main_content_container_no_footer">
			<div className="px-3">
				<div className="main_content_heading mb-0"><I18 tkey="PRODUCTS_HEADER_WEB"/></div>
			</div>
			<div className="product_list_container">
				<ProductListTable />
			</div>
		</div>
	);
};
