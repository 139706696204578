import React, { forwardRef, useState, ForwardRefRenderFunction, useImperativeHandle, useEffect } from "react";
import { useParams } from "react-router-dom";
import { APIStatus, numberRegex } from "../../../constants";
import { useAppDispatch, useAppSelector } from "../../../modal/hooks";
import { CategorySelect } from "../../common/category-select";
import I18 from "../../../plugins/i18";
import { fetchOneItem } from "../../../services/item/item.service";
import { clearFetchOneItem } from "../../../services/item/item.slice";
import { ItemsDropdown } from "../items-dropdown";

interface FarmerSupportedItem {
	itemId: number | undefined;
	itemName: string | undefined;
	title: string;
	description: string;
	categoryId: number | undefined;
	categoryName: string | undefined;
	salesPeriod: string;
}

interface FarmerSupportedItemInvalid {
	itemId: boolean;
	title: boolean;
	description: boolean;
	categoryId: boolean;
	salesPeriod: boolean;
}

export interface FarmerSupportedItemHandles {
	getFormData(): FarmerSupportedItem;
	validate(): boolean;
	setLoading(status: boolean): void;
	setFormData(data: FarmerSupportedItem): void;
}

const FarmerSupportedItemForm: ForwardRefRenderFunction<FarmerSupportedItemHandles> = (props, ref) => {
	const [formData, setFormData] = useState<FarmerSupportedItem>({
		itemName: undefined,
		itemId: undefined,
		title: "",
		description: "",
		categoryId: undefined,
		categoryName: undefined,
		salesPeriod: "",
	});
	const [invalid, setInvalid] = useState<FarmerSupportedItemInvalid>({
		itemId: false,
		title: false,
		description: false,
		categoryId: false,
		salesPeriod: false,
	});
	const [loading, setLoading] = useState<boolean>(false);
	const params = useParams();
	const dispatch = useAppDispatch();
	const item = useAppSelector((store) => store.item);

	useEffect(() => {
		if (item.fetchOneItemComplete === APIStatus.FULFILLED) {
			setLoading(false);
			setFormData((prevState) => ({ ...prevState, title: item.item.title, description: item.item.description }));
			setInvalid((prevState) => ({ ...prevState, title: false, description: false }));
			dispatch(clearFetchOneItem());
		}
		if (item.fetchOneItemComplete === APIStatus.REJECTED) {
			setLoading(false);
			dispatch(clearFetchOneItem());
		}
	}, [item.fetchOneItemComplete]);

	useImperativeHandle(ref, () => ({
		getFormData: (): FarmerSupportedItem => formData,
		setFormData: (data: FarmerSupportedItem) => {
			setFormData(data);
		},
		setLoading: (status: boolean) => {
			setLoading(status);
		},
		validate: () => {
			const prevState: FarmerSupportedItemInvalid = JSON.parse(JSON.stringify(invalid));
			if (!formData.itemId) {
				prevState.itemId = true;
			}
			if (!formData.title || !formData.title.trim()) {
				prevState.title = true;
			}
			if (!formData.description || !formData.description.trim()) {
				prevState.description = true;
			}
			if (!formData.categoryId) {
				prevState.categoryId = true;
			}
			if (
				formData.salesPeriod &&
				(!formData.salesPeriod.toString().trim() ||
					!numberRegex.test(formData.salesPeriod) ||
					parseInt(formData.salesPeriod) < 1 ||
					parseInt(formData.salesPeriod) > 2147483647)
			) {
				prevState.salesPeriod = true;
			}
			setInvalid(prevState);
			return !(
				prevState.itemId ||
				prevState.title ||
				prevState.description ||
				prevState.categoryId ||
				prevState.salesPeriod
			);
		},
	}));

	const itemChanged = (itemId: number | undefined, itemName: string | undefined) => {
		setFormData({ ...formData, itemName: itemName, itemId: itemId });
		setInvalid((prevState) => ({ ...prevState, itemId: false }));
		if (itemName) {
			setLoading(true);
			dispatch(fetchOneItem(itemName));
		}
	};

	const categoryChanged = (categoryName: string | undefined, categoryId: number | undefined) => {
		setFormData((prevState) => ({
			...prevState,
			categoryId: categoryId,
			categoryName: categoryName,
		}));
		setInvalid((prevState) => ({ ...prevState, categoryId: false }));
	};

	return (
		<div className="product_form_container">
			{!params.id ? (
				<div className="mb-2">
					<div className="product_form_label font_weight_medium">
						<I18 tkey="FARMER_SUPPORTED_ITEM_SELECT" />
					</div>
					<div className="product_form_input_container position-relative">
						<ItemsDropdown
							invalid={invalid.itemId}
							disabled={loading}
							itemName={formData.itemName}
							itemId={formData.itemId}
							itemChanged={itemChanged}
						/>
					</div>
				</div>
			) : (
				""
			)}
			<div className="mb-2">
				<div className="product_form_label font_weight_medium">
					<I18 tkey="TITLE_INPUT" />
				</div>
				<div className="product_form_input_container">
					<input
						className={`${invalid.title ? "invalid_input" : ""}`}
						type="text"
						maxLength={75}
						disabled={loading}
						value={formData.title ? formData.title : undefined}
						onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
							setFormData((prevState) => ({ ...prevState, title: e.target.value }));
							setInvalid((prevState) => ({ ...prevState, title: false }));
						}}
					/>
				</div>
			</div>
			<div className="mb-2">
				<div className="product_form_label font_weight_medium">
					<I18 tkey="DESCRIPTION_INPUT" />
				</div>
				<div className="product_form_input_container position-relative">
					<div className="remaining_characters">
						{formData.description && formData.description.length ? formData.description.length : 0}/500
					</div>
					<textarea
						maxLength={500}
						className={`${invalid.description ? "invalid_input" : ""}`}
						disabled={loading}
						value={formData.description}
						onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
							setFormData((prevState) => ({ ...prevState, description: e.target.value }));
							setInvalid((prevState) => ({ ...prevState, description: false }));
						}}
					></textarea>
				</div>
			</div>
			<div className="mb-2">
				<div className="product_form_label font_weight_medium">
					<I18 tkey="CATEGORY" />
				</div>
				<div className="product_form_input_container position-relative">
					<CategorySelect
						categoryId={formData.categoryId}
						categoryName={formData.categoryName}
						disabled={loading}
						categoryChanged={categoryChanged}
						invalid={invalid.categoryId}
					/>
				</div>
			</div>
			<div className="mb-2">
				<div className="product_form_label font_weight_medium">
					<I18 tkey="SALES_PERIOD" />
				</div>
				<div className="product_form_input_container position-relative">
					<input
						type="number"
						inputMode="numeric"
						min={1}
						max={2147483647}
						disabled={loading}
						value={formData.salesPeriod ? formData.salesPeriod : undefined}
						onKeyDown={(e) => {
							if (e.code === "Minus" || e.code === "NumpadSubtract") {
								e.preventDefault();
							}
						}}
						onPaste={(e) => {
							const clipboardData = e.clipboardData || window.Clipboard;
							const pastedData = parseFloat(clipboardData.getData("text"));
							if (pastedData < 0) {
								e.preventDefault();
							}
						}}
						onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
							if (!e.target.value || (e.target.value && numberRegex.test(e.target.value))) {
								setFormData((prevState) => ({ ...prevState, salesPeriod: e.target.value }));
								setInvalid((prevState) => ({ ...prevState, salesPeriod: false }));
							}
						}}
					/>
				</div>
			</div>
		</div>
	);
};

export default forwardRef(FarmerSupportedItemForm);
