import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import FarmerPemUpload, { FarmerPemUploadHandles } from "../../../components/common/farmer-pem-upload";
import { APIStatus, emailRegex, phoneNumberRegex } from "../../../constants";
import { Locations } from "../../../constants/locations";
import { useAppDispatch, useAppSelector } from "../../../modal/hooks";
import I18 from "../../../plugins/i18";
import { ProfileUpload } from "../../../plugins/profile-upload";
import { createFarmer } from "../../../services/farmer/farmer.service";
import { clearCreateFarmer } from "../../../services/farmer/farmer.slice";
import { showError } from "../../../utils/util.fns";
import "./farmer-create.scss";

type InvalidProps = {
	name: boolean;
	email: boolean;
	contact: boolean;
	availableTime: boolean;
	sellerId: boolean;
	yahooPassword: boolean;
	yahooShoppingId: boolean;
	clientId: boolean;
	clientSecret: boolean;
	password: boolean;
	confirmPassword: boolean;
	pemFile: boolean;
};

export const FarmerCreate: React.FunctionComponent = () => {
	const [profileImage, setProfileImage] = useState<string>("");
	const nameRef = useRef<HTMLInputElement>(null);
	const emailRef = useRef<HTMLInputElement>(null);
	const contactRef = useRef<HTMLInputElement>(null);
	const availableTimeRef = useRef<HTMLInputElement>(null);
	const yahooShoppingIdRef = useRef<HTMLInputElement>(null);
	const sellerIdRef = useRef<HTMLInputElement>(null);
	const yahooPasswordRef = useRef<HTMLInputElement>(null);
	const clientIdRef = useRef<HTMLInputElement>(null);
	const clientSecretRef = useRef<HTMLInputElement>(null);
	const passwordRef = useRef<HTMLInputElement>(null);
	const confirmPasswordRef = useRef<HTMLInputElement>(null);
	const pemUploadRef = useRef<FarmerPemUploadHandles>(null);
	const [invalid, setInvalid] = useState<InvalidProps>({
		name: false,
		email: false,
		contact: false,
		availableTime: false,
		sellerId: false,
		yahooPassword: false,
		yahooShoppingId: false,
		clientId: false,
		clientSecret: false,
		password: false,
		confirmPassword: false,
		pemFile: false
	});
	const [loading, setLoading] = useState<boolean>(false);
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const farmer = useAppSelector((store) => store.farmer);

	useEffect(() => {
		if (farmer.createFarmerComplete === APIStatus.FULFILLED) {
			if(farmer.createdFarmerId) {
				pemUploadRef.current?.uploadPem(farmer.createdFarmerId);
			}
			dispatch(clearCreateFarmer());
		}
		if (farmer.createFarmerComplete === APIStatus.REJECTED) {
			setLoading(false);
			dispatch(clearCreateFarmer());
		}
	}, [farmer.createFarmerComplete]);

	const validate = (): boolean => {
		const prevState: InvalidProps = JSON.parse(JSON.stringify(invalid));
		if (!nameRef.current?.value || !nameRef.current.value.trim()) {
			prevState.name = true;
		}
		if (!emailRef.current?.value || !emailRef.current.value.trim() || !emailRegex.test(emailRef.current.value)) {
			prevState.email = true;
		}
		if (
			!contactRef.current?.value ||
			!contactRef.current.value.trim() ||
			!phoneNumberRegex.test(contactRef.current.value)
		) {
			prevState.contact = true;
		}
		if (!availableTimeRef.current?.value || !availableTimeRef.current.value.trim()) {
			prevState.availableTime = true;
		}
		if (!yahooShoppingIdRef.current?.value || !yahooShoppingIdRef.current.value.trim()) {
			prevState.yahooShoppingId = true;
		}
		if (!yahooPasswordRef.current?.value || !yahooPasswordRef.current.value.trim()) {
			prevState.yahooPassword = true;
		}
		if (!sellerIdRef.current?.value || !sellerIdRef.current.value.trim()) {
			prevState.sellerId = true;
		}
		if (!clientIdRef.current?.value || !clientIdRef.current.value.trim()) {
			prevState.clientId = true;
		}
		if (!clientSecretRef.current?.value || !clientSecretRef.current.value.trim()) {
			prevState.clientSecret = true;
		}
		if (!passwordRef.current?.value || !passwordRef.current.value.trim()) {
			prevState.password = true;
		}
		if (passwordRef.current && passwordRef.current.value.length < 8) {
			showError("PASSWORD_MIN_LENGTH");
			prevState.password = true;
		}
		if (
			!confirmPasswordRef.current?.value ||
			!confirmPasswordRef.current.value.trim() ||
			passwordRef.current?.value !== confirmPasswordRef.current.value
		) {
			prevState.confirmPassword = true;
		}
		prevState.pemFile = pemUploadRef.current && pemUploadRef.current?.validate() ? pemUploadRef.current?.validate() : false;
		setInvalid(prevState);
		return !(
			prevState.name ||
			prevState.email ||
			prevState.contact ||
			prevState.availableTime ||
			prevState.yahooPassword ||
			prevState.sellerId ||
			prevState.yahooShoppingId ||
			prevState.clientId ||
			prevState.clientSecret ||
			prevState.password ||
			prevState.confirmPassword ||
			prevState.pemFile
		);
	};

	const create = () => {
		if (validate()) {
			setLoading(true);
			dispatch(
				createFarmer({
					contact: contactRef.current?.value ?? "",
					yahooShoppingId: yahooShoppingIdRef.current?.value.trim() ?? "",
					availableTimeForCall: availableTimeRef.current?.value ?? "",
					sellerId: sellerIdRef.current?.value.trim() ?? "",
					yahooShoppingPassword: yahooPasswordRef.current?.value.trim() ?? "",
					clientId: clientIdRef.current?.value.trim() ?? "",
					clientSecret: clientSecretRef.current?.value.trim() ?? "",
					user: {
						firstName: nameRef.current?.value ?? "",
						lastName: "",
						email: emailRef.current?.value.trim() ?? "",
						password: passwordRef.current?.value.trim() ?? "",
					},
					base64UserImg: profileImage ? profileImage : "",
				})
			);
		}
	};

	return (
		<>
			<div className="main_content_container">
				<div className="main_content_section ">
					<div className="main_content_heading">
						<I18 tkey="FARMER_CREATE_HEADER" />
					</div>
					<div className="row ml-0 mr-0">
						<div className="farmer_create_form px-4 py-3 col-md-8 farmer_profile_container">
							<div className="d-flex align-items-center mb-4">
								<div className="farmer_form_label">
									<I18 tkey="NAME_INPUT" />
								</div>
								<div className="farmer_form_input_container position-relative">
									<input
										ref={nameRef}
										type="text"
										maxLength={100}
										onChange={() => setInvalid((prevState) => ({ ...prevState, name: false }))}
									/>
									{invalid.name ? (
										<span className="invalid_message">
											<I18 tkey="ENTER_NAME" />
										</span>
									) : (
										""
									)}
								</div>
							</div>
							<div className="d-flex align-items-center mb-4 position-relative">
								<div className="farmer_form_label">
									<I18 tkey="EMAIL_INPUT" />
								</div>
								<div className="farmer_form_input_container position-relative">
									<input
										ref={emailRef}
										type="email"
										inputMode="email"
										maxLength={100}
										onChange={() => setInvalid((prevState) => ({ ...prevState, email: false }))}
									/>
									{invalid.email ? (
										<span className="invalid_message">
											<I18 tkey="ENTER_EMAIL" />
										</span>
									) : (
										""
									)}
								</div>
							</div>
							<div className="d-flex align-items-center mb-4 position-relative">
								<div className="farmer_form_label">
									<I18 tkey="CONTACT_INPUT" />
								</div>
								<div className="farmer_form_input_container position-relative">
									<input
										ref={contactRef}
										type="tel"
										inputMode="tel"
										maxLength={100}
										onChange={() => setInvalid((prevState) => ({ ...prevState, contact: false }))}
									/>
									{invalid.contact ? (
										<span className="invalid_message">
											<I18 tkey="ENTER_CONTACT" />
										</span>
									) : (
										""
									)}
								</div>
							</div>
							<div className="d-flex align-items-center mb-4 position-relative">
								<div className="farmer_form_label">
									<I18 tkey="AVAILABLE_TIME_INPUT" />
								</div>
								<div className="farmer_form_input_container position-relative">
									<input
										ref={availableTimeRef}
										type="text"
										maxLength={100}
										onChange={() => setInvalid((prevState) => ({ ...prevState, availableTime: false }))}
									/>
									{invalid.availableTime ? (
										<span className="invalid_message">
											<I18 tkey="ENTER_AVAILABLE_TIME" />
										</span>
									) : (
										""
									)}
								</div>
							</div>
							<div className="d-flex align-items-center mb-4 position-relative">
								<div className="farmer_form_label">
									<I18 tkey="YAHOO_ID_INPUT" />
								</div>
								<div className="farmer_form_input_container position-relative">
									<input
										ref={yahooShoppingIdRef}
										type="text"
										maxLength={100}
										onChange={() => setInvalid((prevState) => ({ ...prevState, yahooShoppingId: false }))}
									/>
									{invalid.yahooShoppingId ? (
										<span className="invalid_message">
											<I18 tkey="ENTER_YAHOO_SHOPPING_ID" />
										</span>
									) : (
										""
									)}
								</div>
							</div>
							<div className="d-flex align-items-center mb-4 position-relative">
								<div className="farmer_form_label">
									<I18 tkey="YAHOO_PASSWORD_INPUT" />
								</div>
								<div className="farmer_form_input_container position-relative">
									<input
										ref={yahooPasswordRef}
										type="text"
										maxLength={100}
										onChange={() => setInvalid((prevState) => ({ ...prevState, yahooPassword: false }))}
									/>
									{invalid.yahooPassword ? (
										<span className="invalid_message">
											<I18 tkey="ENTER_YAHOO_SHOPPING_PASSWORD" />
										</span>
									) : (
										""
									)}
								</div>
							</div>
							<div className="d-flex align-items-center mb-4 position-relative">
								<div className="farmer_form_label">
									<I18 tkey="SELLER_ID_INPUT" />
								</div>
								<div className="farmer_form_input_container position-relative">
									<input
										ref={sellerIdRef}
										type="text"
										maxLength={100}
										onChange={() => setInvalid((prevState) => ({ ...prevState, sellerId: false }))}
									/>
									{invalid.sellerId ? (
										<span className="invalid_message">
											<I18 tkey="ENTER_SELLER_ID" />
										</span>
									) : (
										""
									)}
								</div>
							</div>
							<div className="d-flex align-items-center mb-4 position-relative">
								<div className="farmer_form_label">
									<I18 tkey="CLIENT_ID_INPUT" />
								</div>
								<div className="farmer_form_input_container position-relative">
									<input
										ref={clientIdRef}
										type="text"
										maxLength={100}
										onChange={() => setInvalid((prevState) => ({ ...prevState, clientId: false }))}
									/>
									{invalid.clientId ? (
										<span className="invalid_message">
											<I18 tkey="ENTER_CLIENT_ID" />
										</span>
									) : (
										""
									)}
								</div>
							</div>
							<div className="d-flex align-items-center mb-4 position-relative">
								<div className="farmer_form_label">
									<I18 tkey="CLIENT_SECRET_INPUT" />
								</div>
								<div className="farmer_form_input_container position-relative">
									<input
										ref={clientSecretRef}
										type="text"
										maxLength={100}
										onChange={() => setInvalid((prevState) => ({ ...prevState, clientSecret: false }))}
									/>
									{invalid.clientSecret ? (
										<span className="invalid_message">
											<I18 tkey="ENTER_CLIENT_SECRET" />
										</span>
									) : (
										""
									)}
								</div>
							</div>
							<div className="d-flex align-items-center mb-4 position-relative">
								<div className="farmer_form_label">
									<I18 tkey="PASSWORD_INPUT" />
								</div>
								<div className="farmer_form_input_container position-relative">
									<input
										ref={passwordRef}
										type="text"
										maxLength={100}
										onChange={() => setInvalid((prevState) => ({ ...prevState, password: false }))}
									/>
									{invalid.password ? (
										<span className="invalid_message">
											<I18 tkey="ENTER_PASSWORD" />
										</span>
									) : (
										""
									)}
								</div>
							</div>
							<div className="d-flex align-items-center mb-4 position-relative">
								<div className="farmer_form_label">
									<I18 tkey="CONFIRM_PASSWORD_INPUT" />
								</div>
								<div className="farmer_form_input_container position-relative">
									<input
										ref={confirmPasswordRef}
										type="text"
										maxLength={100}
										onChange={() => setInvalid((prevState) => ({ ...prevState, confirmPassword: false }))}
									/>
									{invalid.confirmPassword ? (
										<span className="invalid_message">
											<I18 tkey="ENTER_CONFIRM_PASSWORD" />
										</span>
									) : (
										""
									)}
								</div>
							</div>
							<div className="d-flex align-items-center mb-4 position-relative">
								<div className="farmer_form_label">
									<I18 tkey="PEM_FILE" />
								</div>
								<div className="farmer_form_input_container position-relative d-flex align-items-center">
									<FarmerPemUpload 
										ref={pemUploadRef}
										showInvalidMsg
										keyUpdateTime=""
										sellerId={sellerIdRef.current ? sellerIdRef.current.value : ""}
										loading={loading}
										setLoading={setLoading}/>
								</div>
							</div>
						</div>
						<div className="col-md-4 web_profile_container">
							<ProfileUpload image={profileImage} onImageChange={(image: string) => setProfileImage(image)} />
						</div>
					</div>
				</div>
				<div className="main_footer_section d-flex align-items-center justify-content-end">
					<button className="primary_border_btn min_width_120 mr-3" onClick={() => navigate(Locations.FARMER_LIST)}>
						<I18 tkey="CANCEL_BTN" />
					</button>
					<button className="primary_btn min_width_120" hidden={loading} disabled={loading} onClick={create}>
						<I18 tkey="CREATE_BTN" />
					</button>
					<button className="primary_btn min_width_120" hidden={!loading} disabled>
						<div className="button_spinner">
							<div></div>
							<div></div>
							<div></div>
							<div></div>
						</div>
					</button>
				</div>
			</div>
		</>
	);
};
