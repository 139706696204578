import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Footer } from "../../../components/mobile/footer";
import { Header } from "../../../components/mobile/header";
import { LocalStorageKeys } from "../../../constants";
import { Locations } from "../../../constants/locations";
import { useAppSelector } from "../../../modal/hooks";
import I18 from "../../../plugins/i18";
import { logoutModal } from "../../../plugins/logout-modal";
import { ProfileUpload } from "../../../plugins/profile-upload";
import { clearCurrentUser } from "../../../services/user/user.slice";
import "./profile.scss";

export const Profile: React.FunctionComponent = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const user = useAppSelector((store) => store.user);

	const logoutClicked = () => {
		localStorage.removeItem(LocalStorageKeys.TOKEN);
		dispatch(clearCurrentUser());
		navigate(Locations.LOGIN);
	};

	return (
		<div className="h-100 w-100">
			<Header class="user_img_header" headerText="SETTINGS" />
			<div className="main_container main_container_navigate_footer profile_page_container">
				<ProfileUpload image={user.user.base64UserImg} disabled />
				<div className="mt-2 user_details_container">
					<div className="mb-3">
						<I18 tkey="NAME" />
						<div className="user_details_input">{user.user.firstName}</div>
					</div>
					<div className="">
						<I18 tkey="EMAIL_INPUT" />
						<div className="user_details_input">{user.user.email}</div>
					</div>
				</div>
				<div className="text-center mt-5">
					<button className="profile_logout_btn" onClick={() => logoutModal({ logout: logoutClicked })}>
						<svg
							className="mr-3"
							width="18"
							height="18"
							viewBox="0 0 18 18"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M15.5629 8.70463C15.5118 6.4706 14.7415 4.71802 13.1833 3.32741C12.8847 3.06085 12.7344 2.75378 12.8416 2.3604C13.0112 1.73775 13.744 1.5305 14.2498 1.96439C15.3216 2.88383 16.1257 4.00026 16.6514 5.31132C18.5963 10.1631 15.8764 15.6025 10.8321 16.9561C7.15785 17.9419 3.10666 16.2977 1.25222 12.9757C-0.591369 9.67339 -0.388809 6.4298 1.79061 3.33563C2.13203 2.85095 2.58118 2.43761 3.00715 2.0184C3.3788 1.65262 3.91896 1.68374 4.24922 2.04776C4.57595 2.40767 4.54836 2.95047 4.16262 3.29247C3.34827 4.01464 2.71358 4.86363 2.29848 5.87085C0.59111 10.0122 3.09727 14.6487 7.49927 15.4026C11.2783 16.0499 14.8216 13.5156 15.4493 9.73504C15.5139 9.34489 15.5368 8.94741 15.5629 8.70463Z"
								fill="#2C5638"
							/>
							<path
								d="M9.5157 4.35432C9.5157 5.49218 9.5204 6.62974 9.51394 7.7676C9.51042 8.40346 8.94853 8.8168 8.3755 8.61894C8.04612 8.50533 7.81684 8.21118 7.80128 7.86272C7.79071 7.62757 7.796 7.39154 7.796 7.15611C7.79571 5.10291 7.79512 3.04943 7.79717 0.99624C7.79717 0.86942 7.79923 0.737316 7.83269 0.616955C7.94865 0.198919 8.33733 -0.0450331 8.77944 0.00692784C9.18749 0.0550724 9.50865 0.403827 9.5113 0.840358C9.51893 2.01168 9.51394 3.183 9.51394 4.35462C9.51453 4.35433 9.51511 4.35432 9.5157 4.35432Z"
								fill="#2C5638"
							/>
						</svg>
						<I18 tkey="LOGOUT_BTN" />
						<div className="filler_25"></div>
					</button>
				</div>
			</div>
			<Footer navigateFooter />
		</div>
	);
};
