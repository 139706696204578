import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Footer } from "../../../components/mobile/footer";
import { Header } from "../../../components/mobile/header";
import { ItemCard } from "../../../components/mobile/item-card";
import { Locations } from "../../../constants/locations";
import { PaginationWithNumber } from "../../../modal/common";
import { useAppDispatch, useAppSelector } from "../../../modal/hooks";
import I18 from "../../../plugins/i18";
import { fetchItem } from "../../../services/item/item.service";
import { clearItems, Item } from "../../../services/item/item.slice";
import "./item-list.scss";

export const ItemList: React.FunctionComponent = () => {
	const [pagination, setPagination] = useState<PaginationWithNumber>({ pageNumber: 0, numResults: 100 });
	const item = useAppSelector((store) => store.item);
	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	useEffect(() => {
		return () => {
			dispatch(clearItems());
		};
	}, []);

	useEffect(() => {
		dispatch(
			fetchItem({
				pageNumber: pagination.pageNumber,
				numResults: pagination.numResults,
			})
		);
	}, [pagination.pageNumber]);

	const pageScrolled = (e: React.UIEvent<HTMLElement>) => {
		if (item.items.length < item.itemCount) {
			const tableViewHeight = e.currentTarget.offsetHeight;
			const tableScrollHeight = e.currentTarget.scrollHeight;
			const scrollLocation = e.currentTarget.scrollTop;
			const buffer = 200;
			const limit = tableScrollHeight - tableViewHeight - buffer;
			if (scrollLocation > limit) {
				setPagination((prevState) => ({ ...prevState, pageNumber: ++prevState.pageNumber }));
			}
		}
	};

	return (
		<div className="h-100 w-100">
			<Header class="user_img_header" headerText="ITEM_LIST_HEADER_MOBILE" />
			<div className="main_container main_container_navigate_footer">
				<div className="text-right px-3 pt-3">
					<button className="primary_btn min_width_120" onClick={() => navigate(Locations.ITEM_CREATE)}>
						<I18 tkey="CREATE_BTN"></I18>
					</button>
				</div>
				<div className="product_list_container item_list_container position-relative" onScroll={pageScrolled}>
					{item && item.items.map((data: Item) => <ItemCard key={data.id} item={data} />)}
					{(!item.items || !item.items.length) && !item.itemListLoading ? (
						<div className="shade_loading">
							<I18 tkey="NO_DATA" />
						</div>
					) : null}
					{item.itemListLoading ? (
						<div className="shade_loading">
							<div className="button_spinner">
								<div></div>
								<div></div>
								<div></div>
								<div></div>
							</div>
						</div>
					) : null}
				</div>
			</div>
			<Footer navigateFooter />
		</div>
	);
};
