import { createSlice } from "@reduxjs/toolkit";
import { APIStatus } from "../../constants";
import { fetchItem, fetchOneItem, createItem, updateItem, fetchOneItemById, fetchCategory } from "./item.service";
import _ from "lodash";

interface ItemCategory {
	categoryId: string;
	categoryName: string;
	id: number;
	parentId: number | null;
}

export interface Item {
	id: number;
	title: string;
	description: string;
	itemName: string;
	itemConceptName: string;
	itemCategory: ItemCategory;
	itemNumberOfActiveDays: number;
	salesPeriod: string;
}

export interface Category {
	id: number;
	categoryId: string;
	categoryName: string;
	parentId: number;
}

export interface ItemReducer {
	items: Item[];
	item: Item;
	itemById: Item;
	itemCount: number;
	disableSideBar: boolean;
	fetchItemComplete: APIStatus.FULFILLED | APIStatus.REJECTED | null;
	fetchOneItemComplete: APIStatus.FULFILLED | APIStatus.REJECTED | null;
	createItemComplete: APIStatus.FULFILLED | APIStatus.REJECTED | null;
	updateItemComplete: APIStatus.FULFILLED | APIStatus.REJECTED | null;
	fetchOneItemByIdComplete: APIStatus.FULFILLED | APIStatus.REJECTED | null;
	itemListLoading: boolean;
	itemLoading: boolean;
	categoryListLoading: boolean;
	fetchCategoryComplete: APIStatus.FULFILLED | APIStatus.REJECTED | null;
	categoryList: Category[];
	categoryCount: number;
}

const initialState: ItemReducer = {
	items: [],
	item: {} as Item,
	itemById: {} as Item,
	itemCount: 0,
	disableSideBar: false,
	fetchItemComplete: null,
	fetchOneItemComplete: null,
	createItemComplete: null,
	updateItemComplete: null,
	fetchOneItemByIdComplete: null,
	itemListLoading: false,
	itemLoading: false,
	categoryListLoading: false,
	fetchCategoryComplete: null,
	categoryList: [],
	categoryCount: 0,
};

const itemSlice = createSlice({
	name: "item",
	initialState,
	reducers: {
		clearFetchOneItem(state) {
			state.fetchOneItemComplete = null;
		},
		clearItems(state) {
			state.fetchItemComplete = null;
			state.items = [];
		},
		clearCategory(state) {
			state.fetchCategoryComplete = null;
			state.categoryList = [];
		},
		clearCreateItem(state) {
			state.createItemComplete = null;
		},
		clearUpdateItem(state) {
			state.updateItemComplete = null;
		},
		clearFetchOneItemById(state) {
			state.fetchOneItemByIdComplete = null;
		},
	},
	extraReducers(builder) {
		builder.addCase(fetchItem.pending, (state) => {
			state.itemListLoading = true;
		});
		builder.addCase(fetchItem.fulfilled, (state, action) => {
			state.itemListLoading = false;
			state.fetchItemComplete = APIStatus.FULFILLED;
			state.items = _.unionBy(state.items, action.payload.data.data, "id");
			state.itemCount = action.payload.data.count;
		});
		builder.addCase(fetchItem.rejected, (state) => {
			state.itemListLoading = false;
			state.fetchItemComplete = APIStatus.REJECTED;
		});
		builder.addCase(fetchCategory.pending, (state) => {
			state.categoryListLoading = true;
		});
		builder.addCase(fetchCategory.fulfilled, (state, action) => {
			state.categoryListLoading = false;
			state.fetchCategoryComplete = APIStatus.FULFILLED;
			state.categoryList = _.unionBy(state.categoryList, action.payload.data.data, "id");
			state.categoryCount = action.payload.data.count;
		});
		builder.addCase(fetchCategory.rejected, (state) => {
			state.categoryListLoading = false;
			state.fetchCategoryComplete = APIStatus.REJECTED;
		});
		builder.addCase(fetchOneItem.fulfilled, (state, action) => {
			state.fetchOneItemComplete = APIStatus.FULFILLED;
			state.item = action.payload.data;
		});
		builder.addCase(fetchOneItem.rejected, (state) => {
			state.fetchOneItemComplete = APIStatus.REJECTED;
		});
		builder.addCase(createItem.pending, (state) => {
			state.disableSideBar = true;
		});
		builder.addCase(createItem.fulfilled, (state) => {
			state.disableSideBar = false;
			state.createItemComplete = APIStatus.FULFILLED;
		});
		builder.addCase(createItem.rejected, (state) => {
			state.disableSideBar = false;
			state.createItemComplete = APIStatus.REJECTED;
		});
		builder.addCase(fetchOneItemById.pending, (state) => {
			state.itemLoading = true;
		});
		builder.addCase(fetchOneItemById.fulfilled, (state, action) => {
			state.itemLoading = false;
			state.itemById = action.payload.data;
			state.fetchOneItemByIdComplete = APIStatus.FULFILLED;
		});
		builder.addCase(fetchOneItemById.rejected, (state) => {
			state.itemLoading = false;
			state.fetchOneItemByIdComplete = APIStatus.REJECTED;
		});
		builder.addCase(updateItem.pending, (state) => {
			state.disableSideBar = true;
		});
		builder.addCase(updateItem.fulfilled, (state) => {
			state.disableSideBar = false;
			state.updateItemComplete = APIStatus.FULFILLED;
		});
		builder.addCase(updateItem.rejected, (state) => {
			state.disableSideBar = false;
			state.updateItemComplete = APIStatus.REJECTED;
		});
	},
});

export const { clearFetchOneItem, clearItems, clearCreateItem, clearUpdateItem, clearFetchOneItemById, clearCategory } =
	itemSlice.actions;

export default itemSlice.reducer;
