import { createSlice } from "@reduxjs/toolkit";
import { APIStatus } from "../../constants";
import {
	fetchProduct,
	uploadFile,
	fetchAllProducts,
	createProduct,
	uploadAdditionalFile,
	saveProduct,
	deleteProduct,
	removeImage,
	markProductAsSold,
	getYahooProducts,
	fetchPublishedProducts,
} from "./product.service";
import _ from "lodash";

type ItemCategory = {
	categoryId: string;
	categoryName: string;
	id: number;
	parentId: number | null;
};

type Item = {
	id: number;
	description: string;
	itemConceptName: string;
	itemName: string;
	title: string;
	itemCategory: ItemCategory;
	itemNumberOfActiveDays: number;
};

type RelevantProduct = {
	id: number;
	title: string;
	yahooJapanItemCode: string;
};

type Product = {
	id: number;
	description: string;
	price: string;
	quantity: string;
	title: string;
	status: string;
	cartRelatedItems: null;
	delivery: number | null;
	display: boolean;
	explanation: string;
	headline: string;
	keepStock: number | null;
	path: string;
	pickAndDeliveryCode: string;
	pickAndDeliveryTransportRuleType: number | null;
	postageSet: number;
	productCategory: number;
	publishPeriodEnd: string;
	publishPeriodStart: string;
	relevantLinks: string;
	reserveMemberPrice: number;
	reservePrice: number;
	reserveSalePrice: number;
	reserveSellingPeriodEnd: string;
	reserveSellingPeriodStart: string;
	shipWeight: string;
	updatedAt: string;
	yahooJapanItemCode: string;
	yamatoffFlag: number;
	relevantProducts: RelevantProduct[];
	priceUnit: string | null;
	quantityUnit: string | null;
};

export type Image = {
	id: number;
	path: string;
	isUsedForPrediction: boolean;
};

export type ProductResponse = {
	batchId: number;
	item: Item;
	price: string;
	priceUnit: string | null;
	quantity: string;
	quantityUnit: string | null;
	images: Image[];
	product: Product;
};

export type YahooProductList = {
	itemCode: string;
	hasSubCode: number;
	name: string;
	stCatName: string;
	display: number;
	editingFlag: number;
	originalPrice: number;
	price: number;
	salePrice: number;
	salePeriodStart: string;
	salePeriodEnd: string;
	sortOrder: number;
	sortPriority: string;
	condition: number;
	isDrug: number;
	quantity: number;
	stockClose: number;
	batchId: number;
	images: Image[];
	productStatus: string;
	description: string;
	updatedAt: string;
};

export type ProductList = {
	id: number;
	batchId: number;
	item: Item;
	price: string;
	quantity: string;
	images: string[];
	title: string;
	description: string;
};

export type PublishedProductList = {
	batchId: number;
	cartRelatedItems: string;
	delivery: string;
	description: string;
	display: boolean;
	explanation: string;
	headline: string;
	id: number;
	images: string[];
	item: Item;
	keepStock: string;
	path: string;
	pickAndDeliveryCode: string;
	pickAndDeliveryTransportRuleType: string;
	postageSet: string;
	price: string;
	productCategory: string;
	publishPeriodEnd: string;
	publishPeriodStart: string;
	quantity: string;
	relevantLinks: string;
	reserveMemberPrice: string;
	reservePrice: string;
	reserveSalePrice: string;
	reserveSellingPeriodEnd: string;
	reserveSellingPeriodStart: string;
	shipWeight: string;
	title: string;
	yahooJapanItemCode: string;
	yamatoffFlag: string;
};

export type ProductReducer = {
	products: ProductList[];
	productCount: number;
	productListLoading: boolean;
	fetchAllProductComplete: APIStatus.FULFILLED | APIStatus.REJECTED | null;
	product: ProductResponse;
	productLoading: boolean;
	fetchProductComplete: APIStatus.FULFILLED | APIStatus.REJECTED | null;
	fileUploadComplete: APIStatus.FULFILLED | APIStatus.REJECTED | null;
	batchId: number | null;
	uploadImage: File | null;
	disableSideBar: boolean;
	createProductComplete: APIStatus.FULFILLED | APIStatus.REJECTED | null;
	createMessage: string;
	imageUploadingCount: number;
	imageUploadingSuccessCount: number;
	imageUploadingFailedCount: number;
	uploadAdditionalFileComplete: APIStatus.FULFILLED | APIStatus.REJECTED | null;
	uploadAdditionalFileLoading: boolean;
	updateProductComplete: APIStatus.FULFILLED | APIStatus.REJECTED | null;
	deleteProductComplete: APIStatus.FULFILLED | APIStatus.REJECTED | null;
	deleteImageComplete: APIStatus.FULFILLED | APIStatus.REJECTED | null;
	markAsSoldComplete: APIStatus.FULFILLED | APIStatus.REJECTED | null;
	getAllYahooProductComplete: APIStatus.FULFILLED | APIStatus.REJECTED | null;
	yahooProductList: YahooProductList[];
	yahooProductCount: number;
	yahooProductLoading: boolean;
	fetchPublishedProductsComplete: APIStatus.FULFILLED | APIStatus.REJECTED | null;
	productPublishedList: PublishedProductList[];
	productPublishedListLoading: boolean;
	productPublishedListCount: number;
	productDetailsDataChanged: boolean;
};

const initialState: ProductReducer = {
	products: [],
	productCount: 0,
	fetchAllProductComplete: null,
	productListLoading: false,
	productLoading: false,
	product: {} as ProductResponse,
	fetchProductComplete: null,
	fileUploadComplete: null,
	batchId: null,
	uploadImage: null,
	disableSideBar: false,
	createProductComplete: null,
	createMessage: "",
	imageUploadingCount: 0,
	imageUploadingSuccessCount: 0,
	imageUploadingFailedCount: 0,
	uploadAdditionalFileComplete: null,
	uploadAdditionalFileLoading: false,
	updateProductComplete: null,
	deleteProductComplete: null,
	deleteImageComplete: null,
	markAsSoldComplete: null,
	getAllYahooProductComplete: null,
	yahooProductList: [],
	yahooProductCount: 0,
	yahooProductLoading: false,
	fetchPublishedProductsComplete: null,
	productPublishedList: [],
	productPublishedListCount: 0,
	productPublishedListLoading: false,
	productDetailsDataChanged: false
};

const productSlice = createSlice({
	name: "product",
	initialState,
	reducers: {
		clearFetchProduct(state) {
			state.fetchProductComplete = null;
		},
		clearUploadFile(state) {
			state.fileUploadComplete = null;
		},
		saveImage(state, action) {
			state.uploadImage = action.payload;
		},
		clearImage(state) {
			state.uploadImage = null;
		},
		clearProduct(state) {
			state.product = {} as ProductResponse;
		},
		clearFetchAllProducts(state) {
			state.fetchAllProductComplete = null;
		},
		clearProducts(state) {
			state.products = [];
			state.productCount = 0;
		},
		clearCreateProduct(state) {
			state.createProductComplete = null;
			state.createMessage = "";
		},
		clearUploadAdditionalFiles(state) {
			state.uploadAdditionalFileComplete = null;
			if (state.imageUploadingCount === state.imageUploadingSuccessCount + state.imageUploadingFailedCount) {
				state.imageUploadingCount = 0;
				state.imageUploadingSuccessCount = 0;
				state.imageUploadingFailedCount = 0;
			}
		},
		clearUpdateProduct(state) {
			state.updateProductComplete = null;
		},
		clearDeleteProduct(state) {
			state.deleteProductComplete = null;
		},
		clearDeleteImage(state) {
			state.deleteImageComplete = null;
		},
		clearMarkAsSold(state) {
			state.markAsSoldComplete = null;
		},
		clearGetAllYahooProducts(state) {
			state.getAllYahooProductComplete = null;
		},
		clearYahooProducts(state) {
			state.yahooProductList = [];
			state.yahooProductCount = 0;
		},
		clearFetchPublishedProducts(state) {
			state.fetchPublishedProductsComplete = null;
		},
		clearPublishedProducts(state) {
			state.productPublishedList = [];
		},
		saveProductDetailsChanged(state, action) {
			state.productDetailsDataChanged = action.payload;
		}
	},
	extraReducers(builder) {
		builder.addCase(fetchProduct.pending, (state) => {
			state.productLoading = true;
		});
		builder.addCase(fetchProduct.fulfilled, (state, action) => {
			state.productLoading = false;
			state.fetchProductComplete = APIStatus.FULFILLED;
			state.product = action.payload.data;
		});
		builder.addCase(fetchProduct.rejected, (state) => {
			state.productLoading = false;
			state.fetchProductComplete = APIStatus.REJECTED;
		});
		builder.addCase(uploadFile.pending, (state) => {
			state.disableSideBar = true;
			state.batchId = null;
		});
		builder.addCase(uploadFile.fulfilled, (state, action) => {
			state.fileUploadComplete = APIStatus.FULFILLED;
			state.batchId = action.payload.data.batchId;
			state.disableSideBar = false;
		});
		builder.addCase(uploadFile.rejected, (state) => {
			state.fileUploadComplete = APIStatus.REJECTED;
			state.batchId = null;
			state.disableSideBar = false;
		});
		builder.addCase(fetchAllProducts.pending, (state) => {
			state.productListLoading = true;
		});
		builder.addCase(fetchAllProducts.fulfilled, (state, action) => {
			state.fetchAllProductComplete = APIStatus.FULFILLED;
			state.products = _.unionBy(state.products, action.payload.data.data, "id");
			state.productCount = action.payload.data.count;
			state.productListLoading = false;
		});
		builder.addCase(fetchAllProducts.rejected, (state) => {
			state.fetchAllProductComplete = APIStatus.REJECTED;
			state.productListLoading = false;
		});
		builder.addCase(createProduct.pending, (state) => {
			state.disableSideBar = true;
		});
		builder.addCase(createProduct.fulfilled, (state, action) => {
			state.disableSideBar = false;
			state.createMessage = action.payload.messageCode === 400 ? action.payload.message : "";
			state.createProductComplete = APIStatus.FULFILLED;
		});
		builder.addCase(createProduct.rejected, (state) => {
			state.disableSideBar = false;
			state.createProductComplete = APIStatus.REJECTED;
		});
		builder.addCase(uploadAdditionalFile.pending, (state) => {
			state.uploadAdditionalFileLoading = true;
			++state.imageUploadingCount;
		});
		builder.addCase(uploadAdditionalFile.fulfilled, (state, action) => {
			state.uploadAdditionalFileLoading = false;
			++state.imageUploadingSuccessCount;
			state.uploadAdditionalFileComplete = APIStatus.FULFILLED;
			state.product = { ...state.product, images: action.payload.data.images };
		});
		builder.addCase(uploadAdditionalFile.rejected, (state) => {
			state.uploadAdditionalFileLoading = false;
			++state.imageUploadingFailedCount;
			state.uploadAdditionalFileComplete = APIStatus.REJECTED;
		});
		builder.addCase(saveProduct.pending, (state) => {
			state.disableSideBar = true;
		});
		builder.addCase(saveProduct.fulfilled, (state) => {
			state.disableSideBar = false;
			state.updateProductComplete = APIStatus.FULFILLED;
		});
		builder.addCase(saveProduct.rejected, (state) => {
			state.disableSideBar = false;
			state.updateProductComplete = APIStatus.REJECTED;
		});
		builder.addCase(deleteProduct.pending, (state) => {
			state.disableSideBar = true;
		});
		builder.addCase(deleteProduct.fulfilled, (state) => {
			state.disableSideBar = false;
			state.deleteProductComplete = APIStatus.FULFILLED;
		});
		builder.addCase(deleteProduct.rejected, (state) => {
			state.disableSideBar = false;
			state.deleteProductComplete = APIStatus.REJECTED;
		});
		builder.addCase(removeImage.fulfilled, (state, action) => {
			state.deleteImageComplete = APIStatus.FULFILLED;
			state.product = { ...state.product, images: action.payload.data };
		});
		builder.addCase(removeImage.rejected, (state) => {
			state.deleteImageComplete = APIStatus.REJECTED;
		});
		builder.addCase(markProductAsSold.fulfilled, (state) => {
			state.markAsSoldComplete = APIStatus.FULFILLED;
		});
		builder.addCase(markProductAsSold.rejected, (state) => {
			state.markAsSoldComplete = APIStatus.REJECTED;
		});
		builder.addCase(getYahooProducts.pending, (state) => {
			state.yahooProductLoading = true;
		});
		builder.addCase(getYahooProducts.fulfilled, (state, action) => {
			state.yahooProductLoading = false;
			state.getAllYahooProductComplete = APIStatus.FULFILLED;
			state.yahooProductList = _.unionBy(state.yahooProductList, action.payload.data.data, "batchId");
			state.yahooProductCount = action.payload.data.count;
		});
		builder.addCase(getYahooProducts.rejected, (state) => {
			state.yahooProductLoading = false;
			state.getAllYahooProductComplete = APIStatus.REJECTED;
		});
		builder.addCase(fetchPublishedProducts.pending, (state) => {
			state.productPublishedListLoading = true;
		});
		builder.addCase(fetchPublishedProducts.fulfilled, (state, action) => {
			state.productPublishedListLoading = false;
			state.fetchPublishedProductsComplete = APIStatus.FULFILLED;
			state.productPublishedList = _.unionBy(state.productPublishedList, action.payload.data.data, "id");
			state.productPublishedListCount = action.payload.data.count;
		});
		builder.addCase(fetchPublishedProducts.rejected, (state) => {
			state.productPublishedListLoading = false;
			state.fetchPublishedProductsComplete = APIStatus.REJECTED;
		});
	},
});

export const {
	clearFetchProduct,
	clearUploadFile,
	saveImage,
	clearImage,
	clearProduct,
	clearFetchAllProducts,
	clearProducts,
	clearCreateProduct,
	clearUploadAdditionalFiles,
	clearDeleteProduct,
	clearUpdateProduct,
	clearDeleteImage,
	clearMarkAsSold,
	clearGetAllYahooProducts,
	clearYahooProducts,
	clearFetchPublishedProducts,
	clearPublishedProducts,
	saveProductDetailsChanged
} = productSlice.actions;

export default productSlice.reducer;
