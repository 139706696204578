import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { Config } from "../../config";
import { PaginationWithNumber } from "../../modal/common";
import { axiosConfig } from "../../utils/util.fns";

type FarmerSupportedItemCreatePayload = {
	itemName: string;
	id: number;
	title: string;
	description: string;
	itemCategory: { id: number | undefined };
	itemNumberOfActiveDays: string;
};

type FarmerSupportedItemPayload = {
	settingsId: string;
	settings: FarmerSupportedItemCreatePayload;
};

export const fetchAllFarmerSupportedItems = createAsyncThunk(
	"farmer/supported-items/fetchAll",
	async (pagination: PaginationWithNumber) => {
		const response = await axios.get(
			Config.apiSecureUrl + `/farmerDetails?offset=${pagination.pageNumber}&numResults=${pagination.numResults}`,
			axiosConfig()
		);
		return response.data;
	}
);

export const createFarmerSupportedItem = createAsyncThunk(
	"farmer/supported-items/create",
	async (product: FarmerSupportedItemCreatePayload) => {
		const response = await axios.post(Config.apiSecureUrl + "/farmerDetails", product, axiosConfig());
		return response.data;
	}
);

export const fetchOneFarmerSupportedItem = createAsyncThunk(
	"farmer/supported-items/fetchOneById",
	async (settingsId: string) => {
		const response = await axios.get(Config.apiSecureUrl + `/farmerDetails/${settingsId}`, axiosConfig());
		return response.data;
	}
);

export const updateFarmerSupportedItem = createAsyncThunk(
	"farmer/supported-items/update",
	async (settings: FarmerSupportedItemPayload) => {
		const response = await axios.put(
			Config.apiSecureUrl + `/farmerDetails/${settings.settingsId}`,
			settings.settings,
			axiosConfig()
		);
		return response.data;
	}
);

export const deleteFarmerSupportedItem = createAsyncThunk(
	"farmer/supported-items/delete",
	async (productId: number) => {
		const response = await axios.delete(Config.apiSecureUrl + `/farmerDetails/${productId}`, axiosConfig());
		return response.data;
	}
);
