import React from "react";
import { useNavigate } from "react-router-dom";
import { FarmerListTable } from "../../../components/web/farmer-list-table";
import { Locations } from "../../../constants/locations";
import I18 from "../../../plugins/i18";
import "./farmer-list.scss";

export const FarmerList: React.FunctionComponent = () => {
	const navigate = useNavigate();

	return (
		<div className="main_content_container main_content_container_no_footer">
			<div className="px-3">
				<div className="main_content_heading mb-0 d-flex align-items-center justify-content-between">
					<I18 tkey="FARMER_LIST_HEADER"/>
					<div className="font_14">
						<button className="primary_btn min_width_120" onClick={() => navigate(Locations.FARMER_CREATE)}>
							<I18 tkey="CREATE_BTN" />
						</button>
					</div>
				</div>
			</div>
			<div className="product_list_container">
				<FarmerListTable />
			</div>
		</div>
	);
};
