import React, { useState, useEffect } from "react";
import Select, { components, DropdownIndicatorProps, SingleValue } from "react-select";
import { Config } from "../../../config";
import { PaginationWithNumber } from "../../../modal/common";
import { useAppDispatch, useAppSelector } from "../../../modal/hooks";
import I18, { i18Get } from "../../../plugins/i18";
import { clearFetchAllAdminProductsList } from "../../../services/admin-product/admin-product.slice";
import { fetchAllFarmers } from "../../../services/farmer/farmer.service";
import { clearFarmers, clearFetchAllFarmers } from "../../../services/farmer/farmer.slice";

type Option = {
	label: string | undefined;
	value: string | undefined;
};

interface FarmerFilterProps {
	onFarmerChange(e: SingleValue<Option>): void;
	farmer: SingleValue<Option> | undefined;
}

export const FarmerFilter: React.FunctionComponent<FarmerFilterProps> = (props) => {
	const [pagination, setPagination] = useState<PaginationWithNumber>({ pageNumber: 0, numResults: 100 });
	const dispatch = useAppDispatch();
	const farmer = useAppSelector((store) => store.farmer);

	useEffect(() => {
		return () => {
			dispatch(clearFetchAllFarmers());
			dispatch(clearFarmers());
		};
	}, []);

	useEffect(() => {
		dispatch(
			fetchAllFarmers({
				pageNumber: pagination.pageNumber,
				numResults: pagination.numResults,
			})
		);
	}, [pagination.pageNumber]);

	const DropdownIndicator = (props: DropdownIndicatorProps<Option, false>) => {
		return (
			<components.DropdownIndicator {...props}>
				<i className="fas fa-caret-down font-16"></i>
			</components.DropdownIndicator>
		);
	};

	const onScroll = () => {
		if ((farmer.farmers.length < farmer.farmerCount) && !farmer.farmerListLoading) {
			setPagination((prevState) => ({ ...prevState, pageNumber: ++prevState.pageNumber }));
		}
	};

	return (
		<Select
			options={farmer.farmers.map((el) => ({ label: el.user.firstName, value: el.id.toString() }))}
			className="react_select admin_product_list_select w-100"
			menuPlacement="auto"
			components={{ DropdownIndicator }}
			loadingMessage={() => <><I18 tkey="LOADING"/>...</>}
			noOptionsMessage={() => <I18 tkey="NO_OPTIONS"/>}
			value={props.farmer}
			isClearable={true}
			isLoading={farmer.farmerListLoading}
			placeholder={i18Get("SELECT_FARMER", Config.defaultLanguage)}
			onMenuScrollToBottom={onScroll}
			onChange={(e: SingleValue<Option>) => {
				props.onFarmerChange(e);
				dispatch(clearFetchAllAdminProductsList());
			}}
		/>
	);
};
