import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Footer } from "../../../components/mobile/footer";
import { Header } from "../../../components/mobile/header";
import { APIStatus } from "../../../constants";
import { Locations } from "../../../constants/locations";
import { createLocation } from "../../../utils/util.fns";
import { useAppDispatch, useAppSelector } from "../../../modal/hooks";
import I18 from "../../../plugins/i18";
import { uploadFile } from "../../../services/product/product.service";
import { clearImage, clearUploadFile } from "../../../services/product/product.slice";
import "./image-upload.scss";

export const ImagePreview: React.FunctionComponent = () => {
	const [file, setFile] = useState<File | null>(null);
	const [uploading, setUploading] = useState<boolean>(false);
	const navigate = useNavigate();
	const product = useAppSelector((store) => store.product);
	const dispatch = useAppDispatch();

	useEffect(() => {
		if (product.uploadImage) {
			setFile(product.uploadImage);
		} else {
			navigate(Locations.UPLOAD_MODE);
		}
	}, []);

	useEffect(() => {
		if (product.fileUploadComplete === APIStatus.FULFILLED) {
			navigate(createLocation(Locations.PRODUCT_CREATE, { batchId: product.batchId ? `${product.batchId}` : "" }));
			dispatch(clearUploadFile());
			setUploading(false);
		}
		if (product.fileUploadComplete === APIStatus.REJECTED) {
			setUploading(false);
			dispatch(clearUploadFile());
		}
	}, [product.fileUploadComplete]);

	const imageSelected = (e: React.ChangeEvent<HTMLInputElement>) => {
		setFile(e.target.files ? e.target.files[0] : null);
	};

	const fileUpload = () => {
		const payload: FormData = new FormData();
		if (file) {
			payload.append("file", file);
			dispatch(uploadFile(payload));
			setUploading(true);
		}
	};

	const cancelClicked = () => {
		dispatch(clearImage());
		navigate(Locations.UPLOAD_MODE);
	};

	return (
		<>
			<Header headerText="ADD_PHOTO_HEADER" />
			<div className="main_container p-3">
				{file ? (
					<div className="h-100 d-flex align-items-center justify-content-center">
						<img className="image_capture_container_img" src={URL.createObjectURL(file)} />
					</div>
				) : (
					<div className="image_upload_choose_file_container position-relative">
						<input
							className="choose_file_input"
							type="file"
							accept="image/jpeg, image/jpg, image/png"
							onChange={imageSelected}
						/>
						<div className="choose_file_box">
							<I18 tkey="UPLOAD_FILE_DIALOG" />
						</div>
					</div>
				)}
			</div>
			<Footer
				showPrimaryLoader={uploading}
				primaryText="UPLOAD_BTN"
				secondaryText="CANCEL_BTN"
				primaryBtnDisable={uploading}
				secondaryBtnDisable={uploading}
				primaryBtnChange={fileUpload}
				secondaryBtnChange={cancelClicked}
			/>
		</>
	);
};
