import React from "react";
import { useNavigate } from "react-router-dom";
import { ProductStatus } from "../../../constants";
import { Locations } from "../../../constants/locations";
import I18 from "../../../plugins/i18";
import { YahooProductList } from "../../../services/product/product.slice";
import { createLocation } from "../../../utils/util.fns";
import dayjs from "dayjs";

type ProductCardProps = {
	product: YahooProductList;
};

export const ProductCard: React.FunctionComponent<ProductCardProps> = (props) => {
	const navigate = useNavigate();

	return (
		<div
			className="product_list_item_container align-items-start"
			onClick={() => {
				navigate(createLocation(Locations.PRODUCT_DETAILS, { batchId: `${props.product.batchId}` }));
			}}
		>
			<div className="product_list_item_img_container">
				<img
					src={
						props.product && props.product.images && props.product.images.length && props.product.images[0]
							? props.product.images[0].path
							: "/images/broken-doble-img.png"
					}
				/>
			</div>
			<div className="product_list_item_label_container">
				<div className="product_list_item_label_main font_weight_black">
					{props.product && props.product.name ? props.product.name : ""}
				</div>
				<div className="product_list_item_label_sub d-flex my-1">
					{![ProductStatus.MARKED_AS_SOLD, ProductStatus.PENDING_CREATION].includes(
						props.product.productStatus as ProductStatus
					) ? (
							<div className={`product_status_box mr-1 ${props.product.productStatus}`}>
								<svg width="15" height="12" viewBox="0 0 20 12" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path
										d="M19.8749 4.88457C19.7134 5.44681 19.5486 6.00797 19.3913 6.57128C19.1216 7.53822 18.857 8.50651 18.5897 9.47425C18.5717 9.5392 18.5481 9.60253 18.5242 9.67526C18.0935 9.63957 17.6716 9.60441 17.235 9.56845C17.235 7.9228 17.235 6.2844 17.235 4.63928C18.1144 4.69618 18.9944 4.75307 19.8747 4.80997C19.875 4.83466 19.8749 4.85961 19.8749 4.88457Z"
										fill="white"
									/>
									<path
										d="M6.5834 1.92013C7.78784 3.42649 8.95928 4.89126 10.1428 6.37133C11.2842 5.40492 12.4146 4.44764 13.5863 3.45548C13.4701 3.44904 13.4054 3.44233 13.3407 3.44233C12.7849 3.44152 12.2291 3.43803 11.6733 3.44474C11.5472 3.44635 11.483 3.41629 11.4546 3.28399C11.3905 2.98636 11.3105 2.6925 11.2337 2.38441C13.7306 2.38441 16.2077 2.38441 18.7103 2.38441C18.6775 2.44962 18.6595 2.50866 18.6236 2.55321C18.4054 2.82534 18.1867 3.09747 17.9586 3.36128C17.9178 3.40851 17.8319 3.43615 17.7659 3.4383C17.4913 3.44662 17.2162 3.44904 16.9417 3.44018C16.4562 3.42461 16.0209 3.56336 15.6227 3.83576C14.1158 4.86657 12.6647 5.96662 11.3448 7.23333C11.1334 7.43649 11.0217 7.65387 11.0311 7.95471C11.0598 8.87147 11.0703 9.78876 11.0885 10.7439C12.0152 10.7587 12.9153 10.7731 13.8179 10.7876C13.8179 11.0901 13.8179 11.3614 13.8179 11.6526C11.0021 11.6526 8.20731 11.6526 5.38807 11.6526C5.43208 11.4041 5.4769 11.1502 5.52575 10.8743C5.90952 10.8743 6.29033 10.892 6.66901 10.8692C7.02191 10.8478 7.37375 10.7927 7.7229 10.734C7.94108 10.6972 8.07285 10.5654 8.07822 10.3225C8.09593 9.53622 8.09298 8.74802 8.15363 7.96491C8.18959 7.49902 8.03313 7.1606 7.73981 6.82273C6.82681 5.77125 5.95246 4.68623 5.02765 3.64548C4.51909 3.07305 3.94289 2.56046 3.38844 2.02989C3.32162 1.96602 3.20058 1.93167 3.10424 1.93059C2.37373 1.92254 1.64296 1.9263 0.912182 1.9263C0.844553 1.9263 0.776923 1.9263 0.697754 1.9263C0.697754 1.57286 0.697754 1.23283 0.697754 0.865967C0.764578 0.865967 0.830329 0.865967 0.89608 0.865967C3.58784 0.865967 6.2796 0.865967 8.97136 0.865967C9.20269 0.865967 9.19786 0.866235 9.19276 1.10643C9.18713 1.36809 9.19142 1.63029 9.19142 1.91905C8.33237 1.92013 7.48324 1.92013 6.5834 1.92013Z"
										fill="white"
									/>
									<path
										d="M17.23 11.6091C17.23 11.1727 17.23 10.7755 17.23 10.3724C17.6752 10.4041 18.1092 10.4347 18.5767 10.4677C18.5136 10.8668 18.4511 11.2618 18.385 11.6805C17.9922 11.6563 17.6151 11.633 17.23 11.6091Z"
										fill="white"
									/>
								</svg>
							</div>
						) : null}
					<I18 tkey={props.product && props.product.productStatus ? props.product.productStatus : ""} />
				</div>
				{props.product.productStatus === ProductStatus.PUBLISHED && (
					<div className="product_list_item_label_sub">
						<I18 tkey="DATE" />{" "}
						{props.product && props.product.salePeriodStart && dayjs(props.product.salePeriodStart).isValid()
							? dayjs(props.product.salePeriodStart).format("DD-MM-YYYY HH:mm").toString()
							: ""}
					</div>
				)}
				{props.product.productStatus === ProductStatus.PUBLISH_PERIOD_END && (
					<div className="product_list_item_label_sub">
						<I18 tkey="DATE" />{" "}
						{props.product && props.product.salePeriodEnd && dayjs(props.product.salePeriodEnd).isValid()
							? dayjs(props.product.salePeriodEnd).format("DD-MM-YYYY HH:mm").toString()
							: ""}
					</div>
				)}
				{props.product.productStatus === ProductStatus.READY_FOR_PUBLISH && (
					<div className="product_list_item_label_sub">
						<I18 tkey="DATE" />{" "}
						{props.product && props.product.updatedAt && dayjs(props.product.updatedAt).isValid()
							? dayjs(props.product.updatedAt).format("DD-MM-YYYY HH:mm").toString()
							: ""}
					</div>
				)}
				{props.product.productStatus === ProductStatus.MARKED_AS_SOLD && (
					<div className="product_list_item_label_sub">
						<I18 tkey="DATE" />{" "}
						{props.product && props.product.updatedAt && dayjs(props.product.updatedAt).isValid()
							? dayjs(props.product.updatedAt).format("DD-MM-YYYY HH:mm").toString()
							: ""}
					</div>
				)}
			</div>
			<div className="product_list_item_more ml-2">
				<svg width="23" height="22" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path
						d="M22.3287 3.01194C22.2192 3.47528 21.9164 3.8045 21.5902 4.12991C18.5157 7.19603 15.446 10.2672 12.3789 13.341C12.1848 13.5353 11.9755 13.657 11.7024 13.708C10.7732 13.8818 9.84801 14.0764 8.92005 14.257C8.40539 14.3571 8.03502 13.9849 8.13536 13.4731C8.32512 12.5034 8.51259 11.5333 8.71759 10.5667C8.75087 10.4095 8.84054 10.2411 8.95358 10.1275C12.1447 6.926 15.3429 3.73185 18.5365 0.53288C18.7766 0.292316 19.0687 0.16454 19.3814 0.0644531C19.5382 0.0644531 19.6946 0.0644531 19.8514 0.0644531C20.301 0.160984 20.6404 0.426951 20.9442 0.757187C21.1601 0.991908 21.3867 1.21825 21.6212 1.43442C21.956 1.74307 22.2303 2.08499 22.3287 2.54199C22.3287 2.69872 22.3287 2.85546 22.3287 3.01194ZM9.57697 12.8027C9.6486 12.7981 9.68975 12.8001 9.72913 12.7923C10.2725 12.684 10.8166 12.5791 11.3577 12.4602C11.4456 12.4409 11.5317 12.3701 11.5985 12.3033C14.121 9.78483 16.641 7.26411 19.1612 4.74339C19.2138 4.69081 19.2628 4.63467 19.2923 4.60317C18.7817 4.09232 18.2906 3.60077 17.7897 3.09983C17.7478 3.13971 17.6909 3.19154 17.6363 3.24615C15.2164 5.66577 12.8006 8.0897 10.3716 10.5002C10.0769 10.7926 9.87418 11.0918 9.82286 11.512C9.77104 11.9385 9.6641 12.3579 9.57697 12.8027ZM20.256 3.66199C20.4923 3.42118 20.726 3.1829 20.9594 2.94462C21.0654 2.8364 21.0847 2.72031 20.9729 2.60753C20.5774 2.2087 20.1842 1.80759 19.7764 1.42172C19.7221 1.37041 19.5491 1.35796 19.4993 1.40293C19.2326 1.644 18.9877 1.90869 18.7461 2.15434C19.2486 2.6563 19.7406 3.14759 20.256 3.66199Z"
						fill="#2C5638"
					/>
					<path
						d="M0.458808 12.7427C0.458808 10.4791 0.45576 8.21541 0.460078 5.95202C0.462111 4.8188 1.18482 3.89516 2.26901 3.63529C2.46055 3.58931 2.66301 3.57026 2.86039 3.57C5.94962 3.56594 9.03911 3.56695 12.1283 3.56772C12.6427 3.56797 12.9682 3.98026 12.8041 4.42277C12.7078 4.68188 12.5122 4.81779 12.2411 4.84598C12.1636 4.85411 12.0846 4.84878 12.0064 4.84878C8.98119 4.84878 5.95597 4.84878 2.93075 4.84903C2.16867 4.84903 1.74038 5.27656 1.74038 6.03839C1.74012 10.5159 1.74012 14.9931 1.74038 19.4706C1.74038 20.2177 2.17324 20.6534 2.91653 20.6534C7.40088 20.6536 11.8855 20.6539 16.3698 20.6534C17.1129 20.6534 17.5445 20.2177 17.5445 19.4693C17.5447 16.4228 17.5445 13.3762 17.5452 10.3294C17.5452 10.2372 17.5427 10.1425 17.5622 10.0536C17.6324 9.73729 17.9235 9.52365 18.2357 9.54931C18.5593 9.57573 18.8105 9.8389 18.8245 10.1732C18.8311 10.3294 18.826 10.4864 18.826 10.6429C18.826 13.6041 18.8276 16.5653 18.8253 19.5265C18.8243 20.678 18.1125 21.6001 17.0174 21.8648C16.8192 21.9128 16.6094 21.9319 16.4051 21.9321C11.8992 21.936 7.39351 21.9362 2.88757 21.9342C1.48102 21.9337 0.461095 20.9158 0.45957 19.5123C0.456776 17.2558 0.458808 14.9992 0.458808 12.7427Z"
						fill="#2C5638"
					/>
				</svg>
			</div>
		</div>
	);
};
