import React, { forwardRef, useState, ForwardRefRenderFunction, useImperativeHandle, useEffect } from "react";
import { useParams } from "react-router-dom";
import { APIStatus, priceRegex, quantityRegex } from "../../../constants";
import { useAppDispatch, useAppSelector } from "../../../modal/hooks";
import I18, { i18Get } from "../../../plugins/i18";
import { fetchOneItem } from "../../../services/item/item.service";
import { clearFetchOneItem } from "../../../services/item/item.slice";
import { InventoryCancellation, inventoryCancellationOptions } from "../../common/inventory-cancel-dropdown";
import { ProductDelivery, productDeliveryOptions } from "../../common/product-delivery-dropdown";
import { ItemsDropdown } from "../items-dropdown";
import dayjs, { Dayjs } from "dayjs";
import { DatePicker } from "antd";
import { showError } from "../../../utils/util.fns";
import { Config } from "../../../config";
import { UnitSelect } from "../../common/unit-select";

interface Product {
	itemId: number | undefined;
	itemName: string | undefined;
	title: string;
	description: string;
	price: string;
	quantity: string;
	explanation: string;
	delivery: number | null;
	keepStock: number | null;
	headline: string;
	salePeriodStart: Dayjs | null;
	salePeriodEnd: Dayjs | null;
	relevantLinks: { value: string | undefined; label: string | undefined }[];
	quantityUnit: string | null;
}

interface ProductInvalid {
	itemId: boolean;
	title: boolean;
	description: boolean;
	price: boolean;
	quantity: boolean;
	salePeriodStart: boolean;
	salePeriodEnd: boolean;
	priceNotValid: boolean;
	quantityUnit: boolean;
}

export interface ProductFormHandles {
	getFormData(): Product;
	validate(): boolean;
	setLoading(status: boolean): void;
	setFormData(data: Product): void;
}

interface ProductFormProps {
	disable: boolean;
	productActiveDays: number;
	productChanged?(changed: boolean): void;
}

const ProductForm: ForwardRefRenderFunction<ProductFormHandles, ProductFormProps> = (props, ref) => {
	const [formData, setFormData] = useState<Product>({
		itemId: undefined,
		itemName: undefined,
		title: "",
		description: "",
		price: "",
		quantity: "",
		explanation: "",
		delivery: productDeliveryOptions[0].value,
		keepStock: inventoryCancellationOptions[0].value,
		headline: "",
		salePeriodStart: dayjs(),
		salePeriodEnd: dayjs(),
		relevantLinks: [],
		quantityUnit: null,
	});
	const [invalid, setInvalid] = useState<ProductInvalid>({
		itemId: false,
		title: false,
		description: false,
		price: false,
		quantity: false,
		salePeriodStart: false,
		salePeriodEnd: false,
		priceNotValid: false,
		quantityUnit: false,
	});
	const [loading, setLoading] = useState<boolean>(false);
	const dispatch = useAppDispatch();
	const item = useAppSelector((store) => store.item);
	const params = useParams();

	useEffect(() => {
		if (item.fetchOneItemComplete === APIStatus.FULFILLED) {
			setLoading(false);
			setFormData((prevState) => ({ ...prevState, title: item.item.title, description: item.item.description }));
			setInvalid((prevState) => ({ ...prevState, title: false, description: false }));
			dispatch(clearFetchOneItem());
		}
		if (item.fetchOneItemComplete === APIStatus.REJECTED) {
			setLoading(false);
			dispatch(clearFetchOneItem());
		}
	}, [item.fetchOneItemComplete]);

	useImperativeHandle(ref, () => ({
		getFormData: (): Product => ({
			...formData,
			price: formData.price ?? 0,
		}),
		setFormData: (data: Product) => {
			setFormData(data);
		},
		setLoading: (status: boolean) => {
			setLoading(status);
		},
		validate: () => {
			const prevState: ProductInvalid = JSON.parse(JSON.stringify(invalid));
			if (!formData.itemId) {
				prevState.itemId = true;
			}
			if (!formData.title || !formData.title.trim()) {
				prevState.title = true;
			}
			if (!formData.description || !formData.description.trim()) {
				prevState.description = true;
			}
			if (!formData.price || !priceRegex.test(`${formData.price}`)) {
				prevState.price = true;
			}
			if (
				!formData.quantity ||
				!formData.quantity.toString().trim() ||
				!quantityRegex.test(formData.quantity) ||
				parseInt(formData.quantity) <= 0
			) {
				prevState.quantity = true;
			}
			if (!formData.salePeriodStart || !dayjs(formData.salePeriodStart).isValid()) {
				prevState.salePeriodStart = true;
			}
			if (!formData.salePeriodEnd || !dayjs(formData.salePeriodEnd).isValid()) {
				prevState.salePeriodEnd = true;
			}
			if (!formData.quantityUnit) {
				prevState.quantityUnit = true;
			}
			if (formData.price) {
				if (Number(formData.price) <= 1 || Number(formData.price) > 99999999) {
					prevState.priceNotValid = true;
					showError("PRICE_MUST_BE_BETWEEN_1_AND_99999999");
				}
			}
			setInvalid(prevState);
			return !(
				prevState.itemId ||
				prevState.title ||
				prevState.description ||
				prevState.price ||
				prevState.quantity ||
				prevState.salePeriodStart ||
				prevState.salePeriodEnd ||
				prevState.priceNotValid ||
				prevState.quantityUnit
			);
		},
	}));

	const itemChanged = (itemId: number | undefined, itemName: string | undefined) => {
		props.productChanged && props.productChanged(true);
		setFormData({ ...formData, itemId: itemId, itemName: itemName });
		setInvalid((prevState) => ({ ...prevState, itemId: false }));
		if (itemName) {
			setLoading(true);
			dispatch(fetchOneItem(itemName));
		}
	};

	return (
		<div className="product_form_container px-2">
			<div className="mb-2">
				<div className="product_form_label font_weight_black position-relative">
					{params.batchId ? <I18 tkey="PRODUCT_INPUT_EDIT" /> : <I18 tkey="PRODUCT_INPUT" />}
					<span className="required">*</span>
				</div>
				<div className="product_form_input_container">
					<ItemsDropdown
						invalid={invalid.itemId}
						disabled={loading || props.disable}
						itemId={formData.itemId}
						itemName={formData.itemName}
						itemChanged={itemChanged}
					/>
				</div>
			</div>
			<div className="mb-2">
				<div className="product_form_label font_weight_medium position-relative">
					<I18 tkey="TITLE_INPUT" />
					<span className="required">*</span>
				</div>
				<div className="product_form_input_container">
					<input
						className={`${invalid.title ? "invalid_input" : ""}`}
						type="text"
						maxLength={75}
						disabled={loading || props.disable}
						value={formData.title ? formData.title : undefined}
						onFocus={(e) => {
							e.target.scrollIntoView({ behavior: "smooth", block: "nearest", inline: "nearest" });
						}}
						onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
							props.productChanged && props.productChanged(true);
							setFormData((prevState) => ({ ...prevState, title: e.target.value }));
							setInvalid((prevState) => ({ ...prevState, title: false }));
						}}
					/>
				</div>
			</div>
			<div className="mb-2">
				<div className="product_form_label font_weight_medium">
					<I18 tkey="HEADLINE_INPUT" />
				</div>
				<div className="product_form_input_container">
					<input
						type="text"
						maxLength={30}
						disabled={loading || props.disable}
						value={formData.headline ?? ""}
						onFocus={(e) => {
							e.target.scrollIntoView({ behavior: "smooth", block: "nearest", inline: "nearest" });
						}}
						onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
							props.productChanged && props.productChanged(true);
							setFormData((prevState) => ({ ...prevState, headline: e.target.value }));
						}}
					/>
				</div>
			</div>
			<div className="mb-2">
				<div className="product_form_label font_weight_medium position-relative">
					<I18 tkey="DESCRIPTION_INPUT" />
					<span className="required">*</span>
				</div>
				<div className="product_form_input_container position-relative">
					<div className="remaining_characters">
						{formData.description && formData.description.length ? formData.description.length : 0}/500
					</div>
					<textarea
						className={`${invalid.description ? "invalid_input" : ""}`}
						disabled={loading || props.disable}
						maxLength={500}
						value={formData.description}
						onFocus={(e) => {
							e.target.scrollIntoView({ behavior: "smooth", block: "nearest", inline: "nearest" });
						}}
						onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
							props.productChanged && props.productChanged(true);
							setFormData((prevState) => ({ ...prevState, description: e.target.value }));
							setInvalid((prevState) => ({ ...prevState, description: false }));
						}}
					></textarea>
				</div>
			</div>
			<div className="mb-2">
				<div className="product_form_label font_weight_medium">
					<I18 tkey="EXPLANATION_INPUT" />
				</div>
				<div className="product_form_input_container position-relative">
					<div className="remaining_characters">
						{formData.explanation && formData.explanation.length ? formData.explanation.length : 0}/30
					</div>
					<textarea
						disabled={loading || props.disable}
						maxLength={30}
						value={formData.explanation}
						onFocus={(e) => {
							e.target.scrollIntoView({ behavior: "smooth", block: "nearest", inline: "nearest" });
						}}
						onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
							props.productChanged && props.productChanged(true);
							setFormData((prevState) => ({ ...prevState, explanation: e.target.value }));
						}}
					></textarea>
				</div>
			</div>
			<div className="mb-2">
				<div className="product_form_label font_weight_medium position-relative">
					<I18 tkey="QUANTITY_INPUT" />
					<span className="required">*</span>
				</div>
				<div className="product_form_input_container d-flex">
					<input
						className={`quantity_input ${invalid.quantity ? "invalid_input" : ""}`}
						type="number"
						inputMode="numeric"
						min={1}
						disabled={loading || props.disable}
						value={formData.quantity}
						onFocus={(e) => {
							e.target.scrollIntoView({ behavior: "smooth", block: "nearest", inline: "nearest" });
						}}
						onKeyDown={(e) => {
							if (e.code === "Minus" || e.code === "NumpadSubtract") {
								e.preventDefault();
							}
						}}
						onPaste={(e) => {
							const clipboardData = e.clipboardData || window.Clipboard;
							const pastedData = parseFloat(clipboardData.getData("text"));
							if (pastedData < 0) {
								e.preventDefault();
							}
						}}
						onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
							props.productChanged && props.productChanged(true);
							setFormData((prevState) => ({ ...prevState, quantity: `${parseFloat(e.target.value)}` }));
							setInvalid((prevState) => ({ ...prevState, quantity: false }));
						}}
					/>
					<div className="quantity_container">
						<UnitSelect
							className={invalid.quantityUnit ? "invalid_antd_select" : ""}
							disabled={loading || props.disable}
							value={formData.quantityUnit}
							valueChanged={(e) => {
								props.productChanged && props.productChanged(true);
								setFormData((prevState) => ({ ...prevState, quantityUnit: e }));
								setInvalid((prevState) => ({ ...prevState, quantityUnit: false }));
							}}
						/>
					</div>
				</div>
			</div>
			<div className="mb-2">
				<div className="product_form_label font_weight_medium position-relative">
					<I18 tkey="PRICE_INPUT" />
					<span className="required">*</span>
				</div>
				<div className="product_form_input_container d-flex">
					<input
						className={`price_input ${invalid.price || invalid.priceNotValid ? "invalid_input" : ""}`}
						type="number"
						inputMode="numeric"
						min={2}
						max={99999998}
						maxLength={10}
						disabled={loading || props.disable}
						value={formData.price}
						onFocus={(e) => {
							e.target.scrollIntoView({ behavior: "smooth", block: "nearest", inline: "nearest" });
						}}
						onKeyDown={(e) => {
							if (e.code === "Minus" || e.code === "NumpadSubtract") {
								e.preventDefault();
							}
						}}
						onPaste={(e) => {
							const clipboardData = e.clipboardData || window.Clipboard;
							const pastedData = parseFloat(clipboardData.getData("text"));
							if (pastedData < 0) {
								e.preventDefault();
							}
						}}
						onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
							props.productChanged && props.productChanged(true);
							setFormData((prevState) => ({ ...prevState, price: `${parseInt(e.target.value)}` }));
							setInvalid((prevState) => ({ ...prevState, price: false, priceNotValid: false }));
						}}
					/>
					<div className="yen">
						<I18 tkey="YEN" />
					</div>
				</div>
			</div>
			<div className="mb-2">
				<div className="product_form_label font_weight_medium position-relative">
					<I18 tkey="SALE_PERIOD_START" />
					<span className="required">*</span>
				</div>
				<div className="product_form_input_container">
					<DatePicker
						className={`w-100 ${invalid.salePeriodStart ? "invalid_input" : ""}`}
						disabled={loading || props.disable}
						value={formData.salePeriodStart}
						placeholder={i18Get("SELECT_DATE", Config.defaultLanguage)}
						onChange={(date: Dayjs | null) => {
							props.productChanged && props.productChanged(true);
							setFormData((prevState) => ({
								...prevState,
								salePeriodStart: date,
								salePeriodEnd: date ? dayjs(date).add(props.productActiveDays, "days") : null,
							}));
							setInvalid((prevState) => ({ ...prevState, salePeriodStart: false, salePeriodEnd: false }));
						}}
					/>
				</div>
			</div>
			<div className="mb-2">
				<div className="product_form_label font_weight_medium position-relative">
					<I18 tkey="SALE_PERIOD_END" />
					<span className="required">*</span>
				</div>
				<div className="product_form_input_container">
					<DatePicker
						className="w-100"
						disabled
						placeholder={i18Get("SELECT_DATE", Config.defaultLanguage)}
						value={formData.salePeriodEnd}
					/>
				</div>
			</div>
			<div className="mb-2">
				<div className="product_form_label font_weight_medium">
					<I18 tkey="PRODUCT_DELIVERY_INPUT" />
				</div>
				<div className="product_form_input_container">
					<ProductDelivery
						delivery={formData.delivery}
						disabled={loading || props.disable}
						deliveryChanged={(e: number | null) => {
							props.productChanged && props.productChanged(true);
							setFormData((prevState) => ({ ...prevState, delivery: e }));
						}}
					/>
				</div>
			</div>
			<div className="mb-2">
				<div className="product_form_label font_weight_medium">
					<I18 tkey="INVENTORY_CANCELLATION_INPUT" />
				</div>
				<div className="product_form_input_container">
					<InventoryCancellation
						value={formData.keepStock}
						disabled={loading || props.disable}
						valueChanged={(e: number | null) => {
							props.productChanged && props.productChanged(true);
							setFormData((prevState) => ({ ...prevState, keepStock: e }));
						}}
					/>
				</div>
			</div>
			{/* <div className="mb-2">
				<div className="product_form_label font_weight_medium">
					<I18 tkey="RELEVANT_LINKS" />
				</div>
				<div className="product_form_input_container">
					<RelevantLinks
						relevantLinksChanged={(e: { value: string | undefined, label: string | undefined }[]) => { 
							props.productChanged && props.productChanged(true);
							setFormData(prevState => ({ ...prevState, relevantLinks: e }));
						}}
						value={formData.relevantLinks}
						disabled={loading || props.disable}/>
				</div>
			</div> */}
		</div>
	);
};

export default forwardRef(ProductForm);
