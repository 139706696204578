import React, { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { SingleValue } from "react-select";
import { AdminProductCard } from "../../../components/mobile/admin-product-card";
import { FarmerFilter } from "../../../components/mobile/farmer-filter";
import { Footer } from "../../../components/mobile/footer";
import { Header } from "../../../components/mobile/header";
import { APIStatus } from "../../../constants";
import { Locations } from "../../../constants/locations";
import { PaginationWithNumber } from "../../../modal/common";
import { useAppDispatch, useAppSelector } from "../../../modal/hooks";
import I18 from "../../../plugins/i18";
import {
	AdminProductQuery,
	fetchAllAdminProducts,
	publish,
} from "../../../services/admin-product/admin-product.service";
import {
	clearFetchAllAdminProducts,
	clearFetchAllAdminProductsList,
	clearPublish,
} from "../../../services/admin-product/admin-product.slice";
import "./admin-product-list.scss";

type Option = {
	label: string | undefined;
	value: string | undefined;
};

export const AdminProductList: React.FunctionComponent = () => {
	const [pagination, setPagination] = useState<PaginationWithNumber>({ pageNumber: 0, numResults: 100 });
	const [farmer, setFarmer] = useState<SingleValue<Option>>();
	const [loading, setLoading] = useState<boolean>(false);
	const [checkedProduct, setCheckedProducts] = useState<number[]>([]);
	const adminProduct = useAppSelector((store) => store.adminProduct);
	const dispatch = useAppDispatch();
	const [searchParams] = useSearchParams();
	const navigate = useNavigate();

	useEffect(() => {
		return () => {
			dispatch(clearFetchAllAdminProducts());
			dispatch(clearFetchAllAdminProductsList());
		};
	}, []);

	useEffect(() => {
		const farmerParams: string | null = searchParams.get("farmer");
		if (farmerParams) {
			setFarmer(JSON.parse(farmerParams));
			navigate(Locations.ADMIN_PRODUCTS_LIST);
		}
		fetchAdminProductList(farmerParams ? JSON.parse(farmerParams) : farmer);
	}, [pagination.pageNumber, farmer]);

	const fetchAdminProductList = (farmerData: SingleValue<Option> | undefined): void => {
		let query: AdminProductQuery = { pageNumber: pagination.pageNumber, numResults: pagination.numResults };
		if (farmerData?.value) {
			query = { ...query, userId: farmerData.value };
		}
		dispatch(fetchAllAdminProducts({ ...query }));
	};

	const pageScrolled = (e: React.UIEvent<HTMLElement>) => {
		if (adminProduct.products.length < adminProduct.productCount) {
			const tableViewHeight = e.currentTarget.offsetHeight;
			const tableScrollHeight = e.currentTarget.scrollHeight;
			const scrollLocation = e.currentTarget.scrollTop;
			const buffer = 200;
			const limit = tableScrollHeight - tableViewHeight - buffer;
			if (scrollLocation > limit && !adminProduct.productListLoading) {
				setPagination((prevState) => ({ ...prevState, pageNumber: ++prevState.pageNumber }));
			}
		}
	};

	useEffect(() => {
		if (adminProduct.publishCompleted === APIStatus.FULFILLED) {
			dispatch(clearFetchAllAdminProductsList());
			setLoading(false);
			fetchAdminProductList(farmer);
			dispatch(clearPublish());
		}
		if (adminProduct.publishCompleted === APIStatus.REJECTED) {
			setLoading(false);
			dispatch(clearPublish());
		}
	}, [adminProduct.publishCompleted]);

	const onPublish = () => {
		setLoading(true);
		dispatch(publish(checkedProduct));
	};

	return (
		<div className="h-100 w-100">
			<Header class="user_img_header" headerText="PRODUCTS_HEADER_MOBILE" />
			<div className="main_container main_container_navigate_footer">
				<div className="d-flex px-3 pt-3">
					<div className="flex_1_1_10 d-flex align-items-center width_10 pr-2">
						<div className="flex_1_1_10 width_10">
							<FarmerFilter farmer={farmer} onFarmerChange={(e: SingleValue<Option>) => setFarmer(e)} />
						</div>
					</div>
					<button
						className={`primary_btn min_width_120 ${!checkedProduct.length ? "disabled_btn" : ""}`}
						disabled={!checkedProduct.length || loading}
						onClick={onPublish}
					>
						<I18 tkey="PUBLISH_ALL" />
					</button>
				</div>
				<div className="product_list_container admin_product_list_container position-relative" onScroll={pageScrolled}>
					{adminProduct &&
						adminProduct.products.map((data) => (
							<AdminProductCard
								checkedProduct={checkedProduct}
								onProductCheck={(e: number[]) => setCheckedProducts(e)}
								key={data.id}
								adminProduct={data}
								loading={loading}
								setLoading={setLoading}
							/>
						))}
					{(!adminProduct.products || !adminProduct.products.length) && !adminProduct.productListLoading ? (
						<div className="shade_loading">
							<I18 tkey="NO_PRODUCT_DATA" />
						</div>
					) : null}
					{adminProduct.productListLoading || loading ? (
						<div className="shade_loading">
							<div className="button_spinner">
								<div></div>
								<div></div>
								<div></div>
								<div></div>
							</div>
						</div>
					) : null}
				</div>
			</div>
			<Footer navigateFooter />
		</div>
	);
};
