import React from "react";
import { useNavigate } from "react-router-dom";
import { Locations } from "../../../constants/locations";
import { Item } from "../../../services/item/item.slice";
import { createLocation } from "../../../utils/util.fns";

type ItemCardProps = {
	item: Item;
};

export const ItemCard: React.FunctionComponent<ItemCardProps> = (props) => {
	const navigate = useNavigate();

	return (
		<div
			className="product_list_item_container"
			onClick={() => {
				navigate(createLocation(Locations.ITEM_DETAILS, { itemId: `${props.item.id}` }));
			}}
		>
			<div className="product_list_item_label_container">
				<div className="product_list_item_label_main font_weight_black">
					{props.item && props.item.itemName ? props.item.itemName : ""}
				</div>
				{/* <div className="product_list_item_label_sub">
					{props.item && props.item.description ? props.item.description : ""}
				</div> */}
			</div>
			<div className="product_list_item_more ml-2">
				<svg width="23" height="22" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path
						d="M22.3287 3.01194C22.2192 3.47528 21.9164 3.8045 21.5902 4.12991C18.5157 7.19603 15.446 10.2672 12.3789 13.341C12.1848 13.5353 11.9755 13.657 11.7024 13.708C10.7732 13.8818 9.84801 14.0764 8.92005 14.257C8.40539 14.3571 8.03502 13.9849 8.13536 13.4731C8.32512 12.5034 8.51259 11.5333 8.71759 10.5667C8.75087 10.4095 8.84054 10.2411 8.95358 10.1275C12.1447 6.926 15.3429 3.73185 18.5365 0.53288C18.7766 0.292316 19.0687 0.16454 19.3814 0.0644531C19.5382 0.0644531 19.6946 0.0644531 19.8514 0.0644531C20.301 0.160984 20.6404 0.426951 20.9442 0.757187C21.1601 0.991908 21.3867 1.21825 21.6212 1.43442C21.956 1.74307 22.2303 2.08499 22.3287 2.54199C22.3287 2.69872 22.3287 2.85546 22.3287 3.01194ZM9.57697 12.8027C9.6486 12.7981 9.68975 12.8001 9.72913 12.7923C10.2725 12.684 10.8166 12.5791 11.3577 12.4602C11.4456 12.4409 11.5317 12.3701 11.5985 12.3033C14.121 9.78483 16.641 7.26411 19.1612 4.74339C19.2138 4.69081 19.2628 4.63467 19.2923 4.60317C18.7817 4.09232 18.2906 3.60077 17.7897 3.09983C17.7478 3.13971 17.6909 3.19154 17.6363 3.24615C15.2164 5.66577 12.8006 8.0897 10.3716 10.5002C10.0769 10.7926 9.87418 11.0918 9.82286 11.512C9.77104 11.9385 9.6641 12.3579 9.57697 12.8027ZM20.256 3.66199C20.4923 3.42118 20.726 3.1829 20.9594 2.94462C21.0654 2.8364 21.0847 2.72031 20.9729 2.60753C20.5774 2.2087 20.1842 1.80759 19.7764 1.42172C19.7221 1.37041 19.5491 1.35796 19.4993 1.40293C19.2326 1.644 18.9877 1.90869 18.7461 2.15434C19.2486 2.6563 19.7406 3.14759 20.256 3.66199Z"
						fill="#2C5638"
					/>
					<path
						d="M0.458808 12.7427C0.458808 10.4791 0.45576 8.21541 0.460078 5.95202C0.462111 4.8188 1.18482 3.89516 2.26901 3.63529C2.46055 3.58931 2.66301 3.57026 2.86039 3.57C5.94962 3.56594 9.03911 3.56695 12.1283 3.56772C12.6427 3.56797 12.9682 3.98026 12.8041 4.42277C12.7078 4.68188 12.5122 4.81779 12.2411 4.84598C12.1636 4.85411 12.0846 4.84878 12.0064 4.84878C8.98119 4.84878 5.95597 4.84878 2.93075 4.84903C2.16867 4.84903 1.74038 5.27656 1.74038 6.03839C1.74012 10.5159 1.74012 14.9931 1.74038 19.4706C1.74038 20.2177 2.17324 20.6534 2.91653 20.6534C7.40088 20.6536 11.8855 20.6539 16.3698 20.6534C17.1129 20.6534 17.5445 20.2177 17.5445 19.4693C17.5447 16.4228 17.5445 13.3762 17.5452 10.3294C17.5452 10.2372 17.5427 10.1425 17.5622 10.0536C17.6324 9.73729 17.9235 9.52365 18.2357 9.54931C18.5593 9.57573 18.8105 9.8389 18.8245 10.1732C18.8311 10.3294 18.826 10.4864 18.826 10.6429C18.826 13.6041 18.8276 16.5653 18.8253 19.5265C18.8243 20.678 18.1125 21.6001 17.0174 21.8648C16.8192 21.9128 16.6094 21.9319 16.4051 21.9321C11.8992 21.936 7.39351 21.9362 2.88757 21.9342C1.48102 21.9337 0.461095 20.9158 0.45957 19.5123C0.456776 17.2558 0.458808 14.9992 0.458808 12.7427Z"
						fill="#2C5638"
					/>
				</svg>
			</div>
		</div>
	);
};
