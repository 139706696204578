import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { APIStatus } from "../../../constants";
import { Locations } from "../../../constants/locations";
import { createLocation, showError } from "../../../utils/util.fns";
import { useAppDispatch, useAppSelector } from "../../../modal/hooks";
import I18 from "../../../plugins/i18";
import { uploadFile } from "../../../services/product/product.service";
import { clearImage, clearUploadFile } from "../../../services/product/product.slice";
import "./image-upload.scss";

export const ImagePreview: React.FunctionComponent = () => {
	const [file, setFile] = useState<File | null>(null);
	const [uploading, setUploading] = useState<boolean>(false);
	const navigate = useNavigate();
	const product = useAppSelector((store) => store.product);
	const dispatch = useAppDispatch();

	useEffect(() => {
		if (product.uploadImage) {
			setFile(product.uploadImage);
		} else {
			navigate(Locations.UPLOAD_MODE);
		}
	}, []);

	useEffect(() => {
		if (product.fileUploadComplete === APIStatus.FULFILLED) {
			if (!product.batchId) {
				showError("BATCHID_NOT_GENERATED");
			} else {
				navigate(createLocation(Locations.PRODUCT_CREATE, { batchId: product.batchId ? `${product.batchId}` : "" }));
			}
			dispatch(clearUploadFile());
			setUploading(false);
		}
		if (product.fileUploadComplete === APIStatus.REJECTED) {
			setUploading(false);
			dispatch(clearUploadFile());
		}
	}, [product.fileUploadComplete]);

	const fileUpload = () => {
		const payload: FormData = new FormData();
		if (file) {
			payload.append("file", file);
			dispatch(uploadFile(payload));
			setUploading(true);
		}
	};

	const discardFile = () => {
		dispatch(clearImage());
		navigate(Locations.UPLOAD_MODE);
	};

	return (
		<div className="main_content_container">
			<div className="main_content_section pt-0">
				<div className="main_content_heading">
					<I18 tkey="PRODUCT_REGISTRATION_HEADER_WEB" />
				</div>
				<div className="upload_mode_container py-1 d-flex justify-content-center align-items-start ">
					{file ? (
						<div className="upload_mode_image_capture_box">
							<div className="image_capture_container d-flex align-items-center justify-content-center">
								<img className="image_capture_container_img" src={URL.createObjectURL(file)} />
							</div>
						</div>
					) : null}
				</div>
			</div>
			<div className="main_footer_section d-flex align-items-center justify-content-center">
				<div className="upload_capture_box_footer d-flex align-items-center justify-content-end">
					<button className="primary_border_btn min_width_120 mr-3" disabled={uploading} onClick={discardFile}>
						<I18 tkey="CANCEL_BTN" />
					</button>
					<button className="primary_btn min_width_120" hidden={uploading} disabled={uploading} onClick={fileUpload}>
						<I18 tkey="UPLOAD_BTN" />
					</button>
					<button className="primary_btn min_width_120" hidden={!uploading} disabled>
						<div className="button_spinner">
							<div></div>
							<div></div>
							<div></div>
							<div></div>
						</div>
					</button>
				</div>
			</div>
		</div>
	);
};
