import { createSlice } from "@reduxjs/toolkit";
import { APIStatus } from "../../constants";
import { fetchAllAdminProducts, fetchAdminProductById, publish } from "./admin-product.service";
import _ from "lodash";

type ItemCategory = {
	categoryId: string;
	categoryName: string;
	id: number;
	parentId: number | null;
};

type Item = {
	id: number;
	description: string;
	itemConceptName: string;
	itemName: string;
	title: string;
	itemCategory: ItemCategory;
	itemNumberOfActiveDays: number;
};

type Product = {
	id: number;
	description: string;
	price: string;
	quantity: string;
	title: string;
};

export type Image = {
	id: number;
	path: string;
	isUsedForPrediction: boolean;
};

export type ProductResponse = {
	batchId: number;
	item: Item;
	price: string;
	quantity: string;
	images: Image[];
	product: Product;
};

export type ProductList = {
	id: number;
	batchId: number;
	item: Item;
	price: string;
	quantity: string;
	images: string[];
	title: string;
	description: string;
};

export type AdminProductReducer = {
	products: ProductList[];
	productListLoading: boolean;
	fetchAllAdminProductsComplete: APIStatus.FULFILLED | APIStatus.REJECTED | null;
	product: ProductResponse;
	productLoading: boolean;
	fetchAdminProductByIdComplete: APIStatus.FULFILLED | APIStatus.REJECTED | null;
	productCount: number;
	publishCompleted: APIStatus.FULFILLED | APIStatus.REJECTED | null;
};

const initialState: AdminProductReducer = {
	products: [],
	fetchAllAdminProductsComplete: null,
	productListLoading: false,
	productLoading: false,
	product: {} as ProductResponse,
	fetchAdminProductByIdComplete: null,
	productCount: 0,
	publishCompleted: null,
};

const adminProductSlice = createSlice({
	name: "adminProduct",
	initialState,
	reducers: {
		clearFetchAllAdminProducts(state) {
			state.fetchAllAdminProductsComplete = null;
		},
		clearFetchAdminProductById(state) {
			state.fetchAdminProductByIdComplete = null;
		},
		clearFetchAllAdminProductsList(state) {
			state.products = [];
		},
		clearPublish(state) {
			state.publishCompleted = null;
		},
	},
	extraReducers(builder) {
		builder.addCase(fetchAdminProductById.pending, (state) => {
			state.productLoading = true;
		});
		builder.addCase(fetchAdminProductById.fulfilled, (state, action) => {
			state.productLoading = false;
			state.fetchAdminProductByIdComplete = APIStatus.FULFILLED;
			state.product = action.payload.data;
		});
		builder.addCase(fetchAdminProductById.rejected, (state) => {
			state.productLoading = false;
			state.fetchAdminProductByIdComplete = APIStatus.REJECTED;
		});
		builder.addCase(fetchAllAdminProducts.pending, (state) => {
			state.productListLoading = true;
		});
		builder.addCase(fetchAllAdminProducts.fulfilled, (state, action) => {
			state.fetchAllAdminProductsComplete = APIStatus.FULFILLED;
			state.products = _.unionBy(state.products, action.payload.data.data, "id");
			state.productCount = action.payload.data.count;
			state.productListLoading = false;
		});
		builder.addCase(fetchAllAdminProducts.rejected, (state) => {
			state.fetchAllAdminProductsComplete = APIStatus.REJECTED;
			state.productListLoading = false;
		});
		builder.addCase(publish.fulfilled, (state) => {
			state.publishCompleted = APIStatus.FULFILLED;
		});
		builder.addCase(publish.rejected, (state) => {
			state.publishCompleted = APIStatus.REJECTED;
		});
	},
});

export const { clearFetchAdminProductById, clearFetchAllAdminProducts, clearFetchAllAdminProductsList, clearPublish } =
	adminProductSlice.actions;

export default adminProductSlice.reducer;
