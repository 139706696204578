import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Footer } from "../../../components/mobile/footer";
import { Header } from "../../../components/mobile/header";
import ItemForm, { ItemFormHandles } from "../../../components/mobile/item-form";
import { APIStatus } from "../../../constants";
import { Locations } from "../../../constants/locations";
import { useAppDispatch, useAppSelector } from "../../../modal/hooks";
import { fetchOneItemById, updateItem } from "../../../services/item/item.service";
import { clearFetchOneItemById, clearUpdateItem } from "../../../services/item/item.slice";

export const ItemDetails: React.FunctionComponent = () => {
	const [loading, setLoading] = useState<boolean>(false);
	const itemFormRef = useRef<ItemFormHandles>(null);
	const item = useAppSelector((store) => store.item);
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const params = useParams();

	useEffect(() => {
		if (params.itemId) {
			dispatch(fetchOneItemById(params.itemId));
		} else {
			navigate(-1);
		}
		return () => {
			dispatch(clearFetchOneItemById());
		};
	}, [params.batchId]);

	useEffect(() => {
		if (item.fetchOneItemByIdComplete === APIStatus.FULFILLED && itemFormRef.current) {
			if (!item.itemById.itemName) {
				navigate(Locations.ITEM_LIST);
			}
			itemFormRef.current.setLoading(false);
			itemFormRef.current.setFormData({
				itemName: item.itemById.itemName,
				title: item.itemById.title,
				description: item.itemById.description,
				itemConceptName: item.itemById.itemConceptName,
				category: { categoryId: item.itemById.itemCategory.id, categoryName: item.itemById.itemCategory.categoryName },
				salesPeriod: `${item.itemById.itemNumberOfActiveDays}`,
			});
			dispatch(clearFetchOneItemById());
		}
		if (item.fetchOneItemByIdComplete === APIStatus.REJECTED) {
			navigate(Locations.ITEM_LIST);
			dispatch(clearFetchOneItemById());
		}
	}, [item.fetchOneItemByIdComplete]);

	useEffect(() => {
		if (item.updateItemComplete === APIStatus.FULFILLED) {
			itemFormRef.current?.setLoading(false);
			setLoading(false);
			dispatch(clearUpdateItem());
			navigate(Locations.ITEM_LIST);
		}
		if (item.updateItemComplete === APIStatus.REJECTED) {
			itemFormRef.current?.setLoading(false);
			setLoading(false);
			dispatch(clearUpdateItem());
		}
	}, [item.updateItemComplete]);

	const itemUpdate = () => {
		if (itemFormRef.current?.validate() && params.itemId) {
			itemFormRef.current.setLoading(true);
			setLoading(true);
			const formData = itemFormRef.current.getFormData();
			dispatch(
				updateItem({
					itemId: params.itemId,
					item: {
						itemName: formData.itemName ? formData.itemName.trim() : "",
						itemConceptName: formData.itemConceptName ? formData.itemConceptName.trim() : "",
						title: formData.title ? formData.title : "",
						description: formData.description ? formData.description : "",
						itemCategory: { id: formData.category.categoryId },
						itemNumberOfActiveDays: formData.salesPeriod ? formData.salesPeriod : "",
					},
				})
			);
		}
	};

	return (
		<>
			<Header headerText="ITEM_UPDATE" />
			<div className="main_container">
				{item.itemLoading ? (
					<div className="farmer_create_form p-3 d-flex align-items-center justify-content-center h-100">
						<div className="button_spinner">
							<div></div>
							<div></div>
							<div></div>
							<div></div>
						</div>
					</div>
				) : null}
				{!item.itemLoading ? (
					<div className="farmer_create_form p-3">
						<ItemForm ref={itemFormRef} />
					</div>
				) : null}
			</div>
			<Footer
				showPrimaryLoader={loading}
				primaryBtnDisable={loading || item.itemLoading}
				secondaryBtnDisable={loading || item.itemLoading}
				primaryText="SAVE_BTN"
				secondaryText="CANCEL_BTN"
				primaryBtnChange={itemUpdate}
				secondaryBtnChange={() => {
					navigate(Locations.ITEM_LIST);
				}}
			/>
		</>
	);
};
