import React, { useRef, useEffect, useState } from "react";
import { ProductCarousel } from "../../../components/mobile/product-carousel/product-carousel";
import ProductForm, { ProductFormHandles } from "../../../components/web/product-form";
import I18 from "../../../plugins/i18";
import { useAppDispatch, useAppSelector } from "../../../modal/hooks";
import { useNavigate, useParams } from "react-router-dom";
import {
	createProduct,
	fetchProduct,
	FileUploadPayload,
	uploadAdditionalFile,
} from "../../../services/product/product.service";
import { APIStatus } from "../../../constants";
import {
	clearCreateProduct,
	clearFetchProduct,
	clearImage,
	clearProduct,
	clearUploadAdditionalFiles,
} from "../../../services/product/product.slice";
import "./product-create.scss";
import { Locations } from "../../../constants/locations";
import ProductCreateSuccessModal, {
	ProductCreateSuccessModalHandles,
} from "../../../components/mobile/product-create-success-modal";
import { checkIsMobile, createLocation, showInfo, showWarning, validateUploadFile } from "../../../utils/util.fns";
import { Config } from "../../../config";
import dayjs from "dayjs";
import { Modal } from "antd";
import { UploadAdditionalImages } from "../../../components/web/upload-additional-images";

export const ProductCreate: React.FunctionComponent = () => {
	const [loading, setLoading] = useState<boolean>(false);
	const [uploadedImg, setUploadedImg] = useState<string | null>(null);
	const [imageModalOpen, setImageModalOpen] = useState<boolean>(false);
	const productFormRef = useRef<ProductFormHandles>(null);
	const productSuccessModalRef = useRef<ProductCreateSuccessModalHandles>(null);
	const product = useAppSelector((store) => store.product);
	const dispatch = useAppDispatch();
	const params = useParams();
	const navigate = useNavigate();

	useEffect(() => {
		if (params.batchId) {
			dispatch(fetchProduct(params.batchId));
			dispatch(clearImage());
		} else {
			navigate(-1);
		}
		return () => {
			dispatch(clearProduct());
		};
	}, [params.batchId]);

	useEffect(() => {
		if (product.fetchProductComplete === APIStatus.FULFILLED && productFormRef.current) {
			if (product.product && product.product.product && product.product.product.id) {
				showInfo("PRODUCT_ALREADY_CREATED");
				navigate(createLocation(Locations.PRODUCT_DETAILS, { batchId: `${product.product.batchId}` }));
			}
			productFormRef.current.setFormData({
				itemName: product.product.item.itemName,
				itemId: product.product.item.id,
				title: product.product.item.title,
				description: product.product.item.description,
				price: product.product.price ?? "",
				quantity: product.product.quantity,
				explanation: "",
				delivery: null,
				headline: "",
				keepStock: null,
				salePeriodStart: dayjs(),
				salePeriodEnd: product.product.item.itemNumberOfActiveDays
					? dayjs().add(product.product.item.itemNumberOfActiveDays, "days")
					: dayjs(),
				relevantLinks: [],
				quantityUnit: product.product.quantityUnit,
			});
			dispatch(clearFetchProduct());
		}
	}, [product.fetchProductComplete]);

	useEffect(() => {
		if (product.createProductComplete === APIStatus.FULFILLED) {
			setLoading(false);
			product.createMessage && showWarning(product.createMessage);
			productFormRef.current?.setLoading(false);
			productSuccessModalRef.current?.openModal();
			dispatch(clearCreateProduct());
		}
		if (product.createProductComplete === APIStatus.REJECTED) {
			setLoading(false);
			productFormRef.current?.setLoading(false);
			dispatch(clearCreateProduct());
		}
	}, [product.createProductComplete]);

	useEffect(() => {
		if (product.uploadAdditionalFileComplete === APIStatus.FULFILLED) {
			setUploadedImg(null);
			dispatch(clearUploadAdditionalFiles());
		}
		if (product.uploadAdditionalFileComplete === APIStatus.REJECTED) {
			setUploadedImg(null);
			dispatch(clearUploadAdditionalFiles());
		}
	}, [product.uploadAdditionalFileComplete]);

	const fileSelected = (file: File) => {
		if (file && validateUploadFile(file)) {
			const payload: FileUploadPayload = {
				file: new FormData(),
				batchId: params.batchId ? params.batchId : "",
			};
			payload.file.append("file", file);
			setUploadedImg(URL.createObjectURL(file));
			setImageModalOpen(false);
			dispatch(uploadAdditionalFile(payload));
		}
	};

	const productCreate = () => {
		if (productFormRef.current?.validate()) {
			productFormRef.current.setLoading(true);
			setLoading(true);
			const formData = productFormRef.current.getFormData();
			dispatch(
				createProduct({
					itemName: formData.itemName ? formData.itemName : "",
					itemId: formData.itemId ? formData.itemId : 0,
					batchId: product.product.batchId,
					title: formData.title ? formData.title : "",
					description: formData.description ? formData.description : "",
					price: formData.price ? parseInt(formData.price) : 0,
					quantity: formData.quantity ? formData.quantity : "",
					delivery: formData.delivery,
					explanation: formData.explanation,
					headline: formData.explanation,
					keepStock: formData.keepStock,
					publishPeriodStart: dayjs(formData.salePeriodStart).toISOString(),
					publishPeriodEnd: dayjs(formData.salePeriodEnd).toISOString(),
					relevantLinks:
						formData.relevantLinks && formData.relevantLinks.length
							? formData.relevantLinks.map((el) => el.value).join()
							: "",
					quantityUnit: formData.quantityUnit ?? "",
					priceUnit: "円",
				})
			);
		}
	};

	return (
		<div className="main_content_container">
			<div className="main_content_section ">
				<div className="main_content_heading">
					<I18 tkey="PRODUCT_CREATE_HEADER" />
				</div>
				<div className="product_create_container d-flex">
					<div className="product_form">
						<ProductForm
							ref={productFormRef}
							disable={loading}
							productActiveDays={
								product.product && product.product.item && product.product.item.itemNumberOfActiveDays
									? product.product.item.itemNumberOfActiveDays
									: 0
							}
						/>
					</div>
					<div
						className={`product_carousel ${
							product.product.images && product.product.images.length >= Config.maxImagesPerProduct
								? "product_carousel_full_height"
								: ""
						}`}
					>
						<ProductCarousel images={product.product.images} disable={loading} uploadedImg={uploadedImg} />
						{product.product.images && product.product.images.length < Config.maxImagesPerProduct ? (
							<div className="text-center mt-2">
								<button
									className="primary_border_btn px-4"
									disabled={loading || product.uploadAdditionalFileLoading}
									onClick={() => setImageModalOpen(true)}
								>
									<I18 tkey="ADD_MORE_PHOTOS" />
								</button>
							</div>
						) : (
							""
						)}
					</div>
				</div>
			</div>
			<ProductCreateSuccessModal ref={productSuccessModalRef} />
			<Modal
				className={`product_created_modal product_details_modal ${
					checkIsMobile() ? "is_mobile_modal" : "is_web_modal"
				}`}
				maskClosable
				destroyOnClose
				closable
				title={null}
				open={imageModalOpen}
				footer={null}
				onCancel={() => setImageModalOpen(false)}
			>
				<UploadAdditionalImages cancelClicked={() => setImageModalOpen(false)} fileSelected={fileSelected} />
			</Modal>
			<div className="main_footer_section d-flex align-items-center justify-content-end">
				<button className="primary_border_btn min_width_120 mr-3" onClick={() => navigate(Locations.UPLOAD_MODE)}>
					<I18 tkey="CANCEL_BTN" />
				</button>
				<button className="primary_btn min_width_120" hidden={loading} onClick={productCreate}>
					<I18 tkey="SAVE_BTN" />
				</button>
				<button className="primary_btn min_width_120" hidden={!loading} disabled>
					<div className="button_spinner">
						<div></div>
						<div></div>
						<div></div>
						<div></div>
					</div>
				</button>
			</div>
		</div>
	);
};
