import { createSlice } from "@reduxjs/toolkit";
import { APIStatus } from "../../constants";
import {
	createFarmerSupportedItem,
	deleteFarmerSupportedItem,
	fetchAllFarmerSupportedItems,
	fetchOneFarmerSupportedItem,
	updateFarmerSupportedItem,
} from "./farmer-supported-items.service";
import _ from "lodash";

interface Item {
	id: number;
	description: string;
	itemCategory: string;
	itemConceptName: string;
	itemName: string;
	itemNumberOfActiveDays: number;
	title: string;
}

interface ItemCategory {
	categoryId: string;
	categoryName: string;
	id: number;
	parentId: number | null;
}

export interface FarmerSupportedItem {
	id: number;
	title: string;
	description: string;
	itemCategory: ItemCategory;
	itemNumberOfActiveDays: string;
	item: Item;
}

export interface FarmerSupportedItemReducer {
	fetchAllFarmerSupportedItemComplete: APIStatus.FULFILLED | APIStatus.REJECTED | null;
	farmerSupportedItemList: FarmerSupportedItem[];
	farmerSupportedItem: FarmerSupportedItem;
	farmerSupportedItemListLoading: boolean;
	farmerSupportedItemLoading: boolean;
	farmerSupportedItemCount: number;
	disableSideBar: boolean;
	createFarmerSupportedItemComplete: APIStatus.FULFILLED | APIStatus.REJECTED | null;
	fetchOneFarmerSupportedItemComplete: APIStatus.FULFILLED | APIStatus.REJECTED | null;
	updateFarmerSupportedItemComplete: APIStatus.FULFILLED | APIStatus.REJECTED | null;
	deleteFarmerSupportedItemComplete: APIStatus.FULFILLED | APIStatus.REJECTED | null;
}

const initialState: FarmerSupportedItemReducer = {
	fetchAllFarmerSupportedItemComplete: null,
	farmerSupportedItemList: [],
	farmerSupportedItem: {} as FarmerSupportedItem,
	farmerSupportedItemCount: 0,
	disableSideBar: false,
	createFarmerSupportedItemComplete: null,
	fetchOneFarmerSupportedItemComplete: null,
	updateFarmerSupportedItemComplete: null,
	farmerSupportedItemListLoading: false,
	farmerSupportedItemLoading: false,
	deleteFarmerSupportedItemComplete: null,
};

const farmerSupportedItemSlice = createSlice({
	name: "farmerSupportedItem",
	initialState,
	reducers: {
		clearFarmerSupportedItemList(state) {
			state.farmerSupportedItemList = [];
			state.fetchAllFarmerSupportedItemComplete = null;
		},
		clearCreateFarmerSupportedItem(state) {
			state.createFarmerSupportedItemComplete = null;
		},
		clearUpdateFarmerSupportedItem(state) {
			state.updateFarmerSupportedItemComplete = null;
		},
		clearFetchOneFarmerSupportedItem(state) {
			state.fetchOneFarmerSupportedItemComplete = null;
		},
		clearFarmerSupportedItem(state) {
			state.farmerSupportedItem = {} as FarmerSupportedItem;
		},
		clearDeleteFarmerSupportedItem(state) {
			state.deleteFarmerSupportedItemComplete = null;
		},
	},
	extraReducers(builder) {
		builder.addCase(fetchAllFarmerSupportedItems.pending, (state) => {
			state.farmerSupportedItemListLoading = true;
		});
		builder.addCase(fetchAllFarmerSupportedItems.fulfilled, (state, action) => {
			state.farmerSupportedItemListLoading = false;
			state.fetchAllFarmerSupportedItemComplete = APIStatus.FULFILLED;
			state.farmerSupportedItemList = _.unionBy(state.farmerSupportedItemList, action.payload.data.data, "id");
			state.farmerSupportedItemCount = action.payload.data.count;
		});
		builder.addCase(fetchAllFarmerSupportedItems.rejected, (state) => {
			state.farmerSupportedItemListLoading = false;
			state.fetchAllFarmerSupportedItemComplete = APIStatus.REJECTED;
		});
		builder.addCase(createFarmerSupportedItem.fulfilled, (state) => {
			state.disableSideBar = false;
			state.createFarmerSupportedItemComplete = APIStatus.FULFILLED;
		});
		builder.addCase(createFarmerSupportedItem.rejected, (state) => {
			state.disableSideBar = false;
			state.createFarmerSupportedItemComplete = APIStatus.REJECTED;
		});
		builder.addCase(fetchOneFarmerSupportedItem.pending, (state) => {
			state.farmerSupportedItemLoading = true;
		});
		builder.addCase(fetchOneFarmerSupportedItem.fulfilled, (state, action) => {
			state.farmerSupportedItemLoading = false;
			state.farmerSupportedItem = action.payload.data;
			state.fetchOneFarmerSupportedItemComplete = APIStatus.FULFILLED;
		});
		builder.addCase(fetchOneFarmerSupportedItem.rejected, (state) => {
			state.farmerSupportedItemLoading = false;
			state.fetchOneFarmerSupportedItemComplete = APIStatus.REJECTED;
		});
		builder.addCase(updateFarmerSupportedItem.fulfilled, (state) => {
			state.disableSideBar = false;
			state.updateFarmerSupportedItemComplete = APIStatus.FULFILLED;
		});
		builder.addCase(updateFarmerSupportedItem.rejected, (state) => {
			state.disableSideBar = false;
			state.updateFarmerSupportedItemComplete = APIStatus.REJECTED;
		});
		builder.addCase(deleteFarmerSupportedItem.fulfilled, (state) => {
			state.disableSideBar = false;
			state.deleteFarmerSupportedItemComplete = APIStatus.FULFILLED;
		});
		builder.addCase(deleteFarmerSupportedItem.rejected, (state) => {
			state.disableSideBar = false;
			state.deleteFarmerSupportedItemComplete = APIStatus.REJECTED;
		});
	},
});

export const {
	clearCreateFarmerSupportedItem,
	clearFarmerSupportedItemList,
	clearFetchOneFarmerSupportedItem,
	clearUpdateFarmerSupportedItem,
	clearFarmerSupportedItem,
	clearDeleteFarmerSupportedItem,
} = farmerSupportedItemSlice.actions;

export default farmerSupportedItemSlice.reducer;
