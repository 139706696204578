import React, { FunctionComponent } from "react";
import { useAppSelector } from "../../modal/hooks";
import { showError } from "../../utils/util.fns";
import "./profile-upload.scss";

export interface ProfileUploadProps {
	disabled?: boolean;
	image: string;
	onImageChange?(image: string): void;
}

export const ProfileUpload: FunctionComponent<ProfileUploadProps> = (props) => {
	const user = useAppSelector((store) => store.user.user);

	const toBase64 = (file: File): Promise<string | ArrayBuffer | null> =>
		new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () => resolve(reader.result);
			reader.onerror = () => reject(null);
		});

	const validate = (file: File): boolean => {
		if (file.size > 1048576) {
			showError("IMAGE_IS_TO_BIG");
			return false;
		}
		return true;
	};

	const onChangePicture = async (e: React.ChangeEvent<HTMLInputElement>) => {
		const file: File | null = e.target.files && e.target.files.length ? e.target.files[0] : null;
		if (file && validate(file)) {
			const image: any = await toBase64(file);
			if (image && props.onImageChange) {
				props.onImageChange(image);
			}
		}
	};

	return (
		<div className="d-flex align-items-center justify-content-center mb-2">
			<div className="profile_image_container">
				<img
					src={props.image ? props.image : user.role === "ROLE_SITE_OPERATOR" ? "/images/user.png" : "/images/user.png"}
				/>
				{!props.disabled ? (
					<div className="profile_img_edit">
						<input type="file" onChange={onChangePicture} />
						<svg width="23" height="21" viewBox="0 0 23 21" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path
								d="M14.7602 3.71988C15.8971 5.1323 17.0218 6.52978 18.2035 7.998C18.118 8.03541 18.0345 8.05274 17.9757 8.10079C15.3059 10.2754 12.6393 12.4539 9.96806 14.6267C9.81209 14.7538 9.6304 14.864 9.44306 14.9352C7.75361 15.5774 6.06092 16.2111 4.36736 16.8425C3.73939 17.0767 3.14432 16.7555 3.08684 16.1341C3.06937 15.9437 3.11654 15.7133 3.21624 15.5517C4.20652 13.948 5.21257 12.3538 6.2191 10.7602C6.28928 10.6491 6.38738 10.5493 6.48992 10.4654C9.21075 8.24141 11.9338 6.0201 14.6565 3.7985C14.6837 3.7763 14.7127 3.75592 14.7602 3.71988Z"
								fill="#454647"
							/>
							<path
								d="M19.5625 6.69393C18.4683 5.35273 17.33 3.95716 16.1743 2.54065C16.8791 1.96959 17.5647 1.353 18.3117 0.82268C19.4337 0.0259687 21.0023 0.276828 21.8876 1.33368C22.7914 2.41298 22.7703 4.00925 21.7507 4.96986C21.0755 5.60615 20.291 6.12692 19.5625 6.69393Z"
								fill="#454647"
							/>
							<path
								d="M22.0807 20.0397H1.35432C0.846679 20.0397 0.435059 19.6281 0.435059 19.1205C0.435059 18.6128 0.846679 18.2012 1.35432 18.2012H22.0807C22.5883 18.2012 23 18.6128 23 19.1205C23 19.6281 22.5883 20.0397 22.0807 20.0397Z"
								fill="#454647"
							/>
						</svg>
					</div>
				) : (
					""
				)}
			</div>
		</div>
	);
};
