import { Tooltip } from "antd";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Config } from "../../../config";
import { Locations } from "../../../constants/locations";
import { PaginationWithNumber } from "../../../modal/common";
import { useAppDispatch, useAppSelector } from "../../../modal/hooks";
import I18, { i18Get } from "../../../plugins/i18";
import { getYahooProducts } from "../../../services/product/product.service";
import { clearYahooProducts } from "../../../services/product/product.slice";
import { createLocation } from "../../../utils/util.fns";

export const ProductListTable: React.FunctionComponent = () => {
	const [pagination, setPagination] = useState<PaginationWithNumber>({ pageNumber: 1, numResults: 100 });
	const product = useAppSelector((store) => store.product);
	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	useEffect(() => {
		return () => {
			dispatch(clearYahooProducts());
		};
	}, []);

	useEffect(() => {
		dispatch(
			getYahooProducts({
				pageNumber: pagination.pageNumber,
				numResults: pagination.numResults,
			})
		);
	}, [pagination.pageNumber]);

	const pageScrolled = (e: React.UIEvent<HTMLDivElement>) => {
		if (product.yahooProductList.length < product.yahooProductCount) {
			const tableViewHeight = e.currentTarget.offsetHeight;
			const tableScrollHeight = e.currentTarget.scrollHeight;
			const scrollLocation = e.currentTarget.scrollTop;
			const buffer = 200;
			const limit = tableScrollHeight - tableViewHeight - buffer;
			if (scrollLocation > limit && !product.yahooProductLoading) {
				setPagination((prevState) => ({ ...prevState, pageNumber: ++prevState.pageNumber }));
			}
		}
	};

	return (
		<div className="h-100 overflow-auto position-relative" onScroll={pageScrolled}>
			<table className="custom_primary_table">
				<thead>
					<tr>
						<th className="table_column_width_100">
							<div className="custom_primary_table_th font_weight_regular text-center">&nbsp;</div>
						</th>
						<th>
							<div className="custom_primary_table_th font_weight_regular text-center">
								<I18 tkey="PRODUCT_TABLE_HEADER" />
							</div>
						</th>
						<th>
							<div className="custom_primary_table_th font_weight_regular text-center">
								<I18 tkey="TITLE_TABLE_HEADER" />
							</div>
						</th>
						<th className="table_column_width_100">
							<div className="custom_primary_table_th font_weight_regular text-center">&nbsp;</div>
						</th>
						<th>
							<div className="custom_primary_table_th font_weight_regular text-center">
								<I18 tkey="ACTION_TABLE_HEADER" />
							</div>
						</th>
					</tr>
				</thead>
				<tbody>
					{product &&
						product.yahooProductList.map((data) => (
							<tr key={data.itemCode}>
								<td>
									<div className="custom_primary_table_td line_height_30 text-center">
										<img
											className="custom_primary_table_td_max_100_img"
											src={data.images && data.images.length && data.images[0] ? data.images[0].path : "/images/broken-doble-img.png"}
										/>
									</div>
								</td>
								<td>
									<div className="custom_primary_table_td line_height_30 text-center">{data.itemCode}</div>
								</td>
								<td>
									<div className="custom_primary_table_td line_height_30 text-center">{data.name}</div>
								</td>
								<td>
									<div className="custom_primary_table_td line_height_30 d-flex align-items-center justify-content-center">
										<Tooltip title={i18Get(data.productStatus, Config.defaultLanguage)}>
											<div className={`product_status_box_web ${data.productStatus}`}>
												<svg width="20" height="12" viewBox="0 0 20 12" fill="none" xmlns="http://www.w3.org/2000/svg">
													<path
														d="M19.8749 4.88457C19.7134 5.44681 19.5486 6.00797 19.3913 6.57128C19.1216 7.53822 18.857 8.50651 18.5897 9.47425C18.5717 9.5392 18.5481 9.60253 18.5242 9.67526C18.0935 9.63957 17.6716 9.60441 17.235 9.56845C17.235 7.9228 17.235 6.2844 17.235 4.63928C18.1144 4.69618 18.9944 4.75307 19.8747 4.80997C19.875 4.83466 19.8749 4.85961 19.8749 4.88457Z"
														fill="white"
													/>
													<path
														d="M6.5834 1.92013C7.78784 3.42649 8.95928 4.89126 10.1428 6.37133C11.2842 5.40492 12.4146 4.44764 13.5863 3.45548C13.4701 3.44904 13.4054 3.44233 13.3407 3.44233C12.7849 3.44152 12.2291 3.43803 11.6733 3.44474C11.5472 3.44635 11.483 3.41629 11.4546 3.28399C11.3905 2.98636 11.3105 2.6925 11.2337 2.38441C13.7306 2.38441 16.2077 2.38441 18.7103 2.38441C18.6775 2.44962 18.6595 2.50866 18.6236 2.55321C18.4054 2.82534 18.1867 3.09747 17.9586 3.36128C17.9178 3.40851 17.8319 3.43615 17.7659 3.4383C17.4913 3.44662 17.2162 3.44904 16.9417 3.44018C16.4562 3.42461 16.0209 3.56336 15.6227 3.83576C14.1158 4.86657 12.6647 5.96662 11.3448 7.23333C11.1334 7.43649 11.0217 7.65387 11.0311 7.95471C11.0598 8.87147 11.0703 9.78876 11.0885 10.7439C12.0152 10.7587 12.9153 10.7731 13.8179 10.7876C13.8179 11.0901 13.8179 11.3614 13.8179 11.6526C11.0021 11.6526 8.20731 11.6526 5.38807 11.6526C5.43208 11.4041 5.4769 11.1502 5.52575 10.8743C5.90952 10.8743 6.29033 10.892 6.66901 10.8692C7.02191 10.8478 7.37375 10.7927 7.7229 10.734C7.94108 10.6972 8.07285 10.5654 8.07822 10.3225C8.09593 9.53622 8.09298 8.74802 8.15363 7.96491C8.18959 7.49902 8.03313 7.1606 7.73981 6.82273C6.82681 5.77125 5.95246 4.68623 5.02765 3.64548C4.51909 3.07305 3.94289 2.56046 3.38844 2.02989C3.32162 1.96602 3.20058 1.93167 3.10424 1.93059C2.37373 1.92254 1.64296 1.9263 0.912182 1.9263C0.844553 1.9263 0.776923 1.9263 0.697754 1.9263C0.697754 1.57286 0.697754 1.23283 0.697754 0.865967C0.764578 0.865967 0.830329 0.865967 0.89608 0.865967C3.58784 0.865967 6.2796 0.865967 8.97136 0.865967C9.20269 0.865967 9.19786 0.866235 9.19276 1.10643C9.18713 1.36809 9.19142 1.63029 9.19142 1.91905C8.33237 1.92013 7.48324 1.92013 6.5834 1.92013Z"
														fill="white"
													/>
													<path
														d="M17.23 11.6091C17.23 11.1727 17.23 10.7755 17.23 10.3724C17.6752 10.4041 18.1092 10.4347 18.5767 10.4677C18.5136 10.8668 18.4511 11.2618 18.385 11.6805C17.9922 11.6563 17.6151 11.633 17.23 11.6091Z"
														fill="white"
													/>
												</svg>
											</div>
										</Tooltip>
									</div>
								</td>
								<td className="table_column_width_150">
									<div className="custom_primary_table_td line_height_30 text-center">
										<button
											className="primary_btn"
											onClick={() => {
												navigate(createLocation(Locations.PRODUCT_DETAILS, { batchId: `${data.batchId}` }));
											}}
										>
											<I18 tkey="VIEW_OR_EDIT_BTN" />
										</button>
									</div>
								</td>
							</tr>
						))}
				</tbody>
			</table>
			{(!product.yahooProductList || !product.yahooProductList.length) && !product.yahooProductLoading ? (
				<div className="shade_loading">
					<I18 tkey="NO_PRODUCT_DATA" />
				</div>
			) : null}
			{product.yahooProductLoading ? (
				<div className="shade_loading">
					<div className="button_spinner">
						<div></div>
						<div></div>
						<div></div>
						<div></div>
					</div>
				</div>
			) : null}
		</div>
	);
};
