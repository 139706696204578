import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Footer } from "../../../components/mobile/footer";
import { Header } from "../../../components/mobile/header";
import { ProductCarousel } from "../../../components/mobile/product-carousel/product-carousel";
import ProductCreateSuccessModal, {
	ProductCreateSuccessModalHandles,
} from "../../../components/mobile/product-create-success-modal";
import ProductForm, { ProductFormHandles } from "../../../components/mobile/product-form";
import { Config } from "../../../config";
import { APIStatus, ProductStatus } from "../../../constants";
import { Locations } from "../../../constants/locations";
import { useAppDispatch, useAppSelector } from "../../../modal/hooks";
import { deleteModal } from "../../../plugins/delete-modal";
import I18 from "../../../plugins/i18";
import {
	deleteProduct,
	fetchProduct,
	FileUploadPayload,
	markProductAsSold,
	saveProduct,
	uploadAdditionalFile,
} from "../../../services/product/product.service";
import {
	clearDeleteProduct,
	clearFetchProduct,
	clearMarkAsSold,
	clearProduct,
	clearUpdateProduct,
	clearUploadAdditionalFiles,
} from "../../../services/product/product.slice";
import { validateUploadFile } from "../../../utils/util.fns";
import dayjs from "dayjs";

export const ProductDetails: React.FunctionComponent = () => {
	const [loading, setLoading] = useState<string>("");
	const [uploadedImg, setUploadedImg] = useState<string | null>(null);
	const [disableForm, setDisableForm] = useState<boolean>(false);
	const [enableSaveBtn, setEnableSaveBtn] = useState<boolean>(false);
	const productFormRef = useRef<ProductFormHandles>(null);
	const productSuccessModalRef = useRef<ProductCreateSuccessModalHandles>(null);
	const inputRef = useRef<HTMLInputElement>(null);
	const product = useAppSelector((store) => store.product);
	const dispatch = useAppDispatch();
	const params = useParams();
	const navigate = useNavigate();
	const location = useLocation();

	useEffect(() => {
		if (params.batchId) {
			dispatch(fetchProduct(params.batchId));
		} else {
			navigate(-1);
		}
		return () => {
			dispatch(clearProduct());
		};
	}, [params.batchId]);

	useEffect(() => {
		if (product.fetchProductComplete === APIStatus.FULFILLED && productFormRef.current) {
			if (!product.product.product) {
				navigate(
					location.pathname.startsWith(Locations.ADMIN_PRODUCTS_LIST)
						? Locations.ADMIN_PRODUCTS_LIST
						: Locations.PRODUCT_LIST
				);
			}
			setDisableForm(product.product.product.status !== ProductStatus.READY_FOR_PUBLISH);
			productFormRef.current.setLoading(false);
			productFormRef.current.setFormData({
				itemName: product.product.item.itemName,
				itemId: product.product.item.id,
				title: product.product.product.title,
				description: product.product.product.description,
				price: product.product.product.price ?? "",
				quantity: product.product.product.quantity,
				explanation: product.product.product.explanation ?? "",
				delivery: product.product.product.delivery,
				headline: product.product.product.headline,
				keepStock: product.product.product.keepStock,
				salePeriodStart: product.product.product.publishPeriodStart
					? dayjs(product.product.product.publishPeriodStart)
					: null,
				salePeriodEnd: product.product.product.publishPeriodEnd
					? dayjs(product.product.product.publishPeriodEnd)
					: null,
				relevantLinks:
					product.product.product.relevantProducts && product.product.product.relevantProducts.length
						? product.product.product.relevantProducts.map((el) => ({ value: el.yahooJapanItemCode, label: el.title }))
						: [],
				quantityUnit: product.product.product.quantityUnit,
			});
			dispatch(clearFetchProduct());
		}
		if (product.fetchProductComplete === APIStatus.REJECTED) {
			navigate(
				location.pathname.startsWith(Locations.ADMIN_PRODUCTS_LIST)
					? Locations.ADMIN_PRODUCTS_LIST
					: Locations.PRODUCT_LIST
			);
			dispatch(clearFetchProduct());
		}
	}, [product.fetchProductComplete]);

	useEffect(() => {
		if (product.uploadAdditionalFileComplete === APIStatus.FULFILLED) {
			setUploadedImg(null);
			dispatch(clearUploadAdditionalFiles());
		}
		if (product.uploadAdditionalFileComplete === APIStatus.REJECTED) {
			setUploadedImg(null);
			dispatch(clearUploadAdditionalFiles());
		}
	}, [product.uploadAdditionalFileComplete]);

	useEffect(() => {
		if (product.updateProductComplete === APIStatus.FULFILLED) {
			setEnableSaveBtn(false);
			productFormRef.current?.setLoading(false);
			setLoading("");
			navigate(
				location.pathname.startsWith(Locations.ADMIN_PRODUCTS_LIST)
					? Locations.ADMIN_PRODUCTS_LIST
					: Locations.PRODUCT_LIST
			);
			dispatch(clearUpdateProduct());
		}
		if (product.updateProductComplete === APIStatus.REJECTED) {
			productFormRef.current?.setLoading(false);
			setLoading("");
			dispatch(clearUpdateProduct());
		}
	}, [product.updateProductComplete]);

	useEffect(() => {
		if (product.deleteProductComplete === APIStatus.FULFILLED) {
			productFormRef.current?.setLoading(false);
			setLoading("");
			navigate(
				location.pathname.startsWith(Locations.ADMIN_PRODUCTS_LIST)
					? Locations.ADMIN_PRODUCTS_LIST
					: Locations.PRODUCT_LIST
			);
			dispatch(clearDeleteProduct());
		}
		if (product.deleteProductComplete === APIStatus.REJECTED) {
			productFormRef.current?.setLoading(false);
			setLoading("");
			dispatch(clearDeleteProduct());
		}
	}, [product.deleteProductComplete]);

	useEffect(() => {
		if (product.markAsSoldComplete === APIStatus.FULFILLED) {
			productFormRef.current?.setLoading(false);
			setLoading("");
			dispatch(clearMarkAsSold());
		}
		if (product.markAsSoldComplete === APIStatus.REJECTED) {
			productFormRef.current?.setLoading(false);
			setLoading("");
			dispatch(clearMarkAsSold());
		}
	}, [product.markAsSoldComplete]);

	const fileSelected = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (e.target.files && e.target.files.length && validateUploadFile(e.target.files[0])) {
			const payload: FileUploadPayload = {
				file: new FormData(),
				batchId: params.batchId ? params.batchId : "",
			};
			payload.file.append("file", e.target.files[0]);
			setUploadedImg(URL.createObjectURL(e.target.files[0]));
			dispatch(uploadAdditionalFile(payload));
		}
	};

	const productSave = () => {
		if (productFormRef.current?.validate()) {
			productFormRef.current.setLoading(true);
			setLoading("UPDATE");
			const formData = productFormRef.current.getFormData();
			dispatch(
				saveProduct({
					productId: product.product.product.id,
					product: {
						...formData,
						itemName: formData.itemName ? formData.itemName : "",
						itemId: formData.itemId ? formData.itemId : 0,
						batchId: params.batchId ? parseInt(params.batchId) : null,
						delivery: formData.delivery,
						explanation: formData.explanation,
						headline: formData.headline,
						keepStock: formData.keepStock,
						price: formData.price ? parseInt(formData.price) : 0,
						publishPeriodStart: dayjs(formData.salePeriodStart).toISOString(),
						publishPeriodEnd: dayjs(formData.salePeriodEnd).toISOString(),
						relevantLinks:
							formData.relevantLinks && formData.relevantLinks.length
								? formData.relevantLinks.map((el) => el.value).join()
								: "",
						quantityUnit: formData.quantityUnit ?? "",
						priceUnit: "円",
					},
				})
			);
		}
	};

	const deleteClicked = () => {
		if (productFormRef.current) {
			productFormRef.current.setLoading(true);
			setLoading("DELETE");
			dispatch(deleteProduct(product.product.product.id));
		}
	};

	const markAsSoldClicked = () => {
		setLoading("MARK_AS_SOLD");
		dispatch(markProductAsSold(product.product.product.id));
	};

	return (
		<div className="h-100 w-100">
			<Header headerText="PRODUCT_CREATE_HEADER" />
			<div className="main_container p-3 main_container_double_layer_footer">
				{product.productLoading ? (
					<div className="h-100 d-flex align-items-center justify-content-center">
						<div className="button_spinner">
							<div></div>
							<div></div>
							<div></div>
							<div></div>
						</div>
					</div>
				) : null}
				{!product.productLoading ? (
					<div className="h-100 overflow-auto">
						<ProductCarousel images={product.product.images} disable={disableForm} uploadedImg={uploadedImg} />
						<div className="py-3 text-center">
							<input
								type="file"
								accept="image/jpeg, image/jpg, image/png"
								hidden
								ref={inputRef}
								onChange={fileSelected}
							/>
							{product.product.images && product.product.images.length < Config.maxImagesPerProduct ? (
								<button
									className="primary_border_btn"
									disabled={loading !== "" || disableForm}
									onClick={() => {
										inputRef.current?.click();
									}}
								>
									<I18 tkey="ADD_MORE_PHOTOS" />
								</button>
							) : (
								""
							)}
						</div>
						<ProductForm
							ref={productFormRef}
							disable={loading !== "" || disableForm}
							productActiveDays={
								product.product && product.product.item && product.product.item.itemNumberOfActiveDays
									? product.product.item.itemNumberOfActiveDays
									: 0
							}
							productChanged={setEnableSaveBtn}
						/>
					</div>
				) : null}
			</div>
			<ProductCreateSuccessModal ref={productSuccessModalRef} />
			<Footer
				primaryText="SAVE_BTN"
				secondaryText="DELETE_BTN"
				markAsSoldText="MARK_AS_SOLD"
				showPrimaryLoader={loading === "UPDATE"}
				primaryBtnDisable={loading !== "" || product.productLoading || !enableSaveBtn || disableForm}
				showSecondaryLoader={loading === "DELETE"}
				secondaryBtnDisable={loading !== "" || product.productLoading}
				markAsSoldDisable={
					loading !== "" ||
					product.productLoading ||
					(product.product && product.product.product && product.product.product.status !== ProductStatus.PUBLISHED)
				}
				showMarkAsSoldLoader={loading === "MARK_AS_SOLD"}
				primaryBtnChange={productSave}
				secondaryBtnChange={() => {
					deleteModal({ id: product.product.product.id, content: "DELETE_MODAL_CONTENT", delete: deleteClicked });
				}}
				markAsSoldChange={markAsSoldClicked}
				markAsSold
			/>
		</div>
	);
};
