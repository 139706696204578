import { createSlice } from "@reduxjs/toolkit";
import { APIStatus } from "../../constants";
import {
	fetchAllFarmers,
	createFarmer,
	updateFarmer,
	deleteFarmer,
	fetchOneFarmer,
	generateToken,
	uploadPemFile,
} from "./farmer.service";
import _ from "lodash";

type User = {
	id: number;
	firstName: string;
	lastName: string;
	email: string;
	role: string;
};

export type Farmer = {
	id: number;
	user: User;
	contact: string;
	yahooShoppingId: string;
	yahooShoppingPassword: string;
	availableTimeForCall: string;
	clientId: string;
	clientSecret: string;
	sellerId: string;
	base64UserImg: string;
	isProductsCreationPending: boolean;
	isTokenAboutToExpire: boolean;
	keyUpdateTime: string;
};

type TokenDetails = {
	access_token: string;
	createTime: string;
};

export interface FarmerReducer {
	farmers: Farmer[];
	farmerCount: number;
	fetchAllFarmersComplete: APIStatus.FULFILLED | APIStatus.REJECTED | null;
	farmerListLoading: boolean;
	createFarmerComplete: APIStatus.FULFILLED | APIStatus.REJECTED | null;
	createdFarmerId: number | null;
	updateFarmerComplete: APIStatus.FULFILLED | APIStatus.REJECTED | null;
	deleteFarmerComplete: APIStatus.FULFILLED | APIStatus.REJECTED | null;
	farmer: Farmer;
	farmerLoading: boolean;
	fetchOneFarmerComplete: APIStatus.FULFILLED | APIStatus.REJECTED | null;
	disableSidebar: boolean;
	generateTokenComplete: APIStatus.FULFILLED | APIStatus.REJECTED | null;
	tokenDetails: TokenDetails;
	uploadPemFileComplete: APIStatus.FULFILLED | APIStatus.REJECTED | null;
}

const initialState: FarmerReducer = {
	farmers: [],
	farmerCount: 0,
	fetchAllFarmersComplete: null,
	farmerListLoading: false,
	createFarmerComplete: null,
	createdFarmerId: null,
	updateFarmerComplete: null,
	deleteFarmerComplete: null,
	farmer: {} as Farmer,
	farmerLoading: false,
	fetchOneFarmerComplete: null,
	disableSidebar: false,
	generateTokenComplete: null,
	tokenDetails: {} as TokenDetails,
	uploadPemFileComplete: null,
};

const farmerSlice = createSlice({
	name: "farmer",
	initialState,
	reducers: {
		clearFetchAllFarmers(state) {
			state.fetchAllFarmersComplete = null;
		},
		clearFarmers(state) {
			state.farmers = [];
		},
		clearCreateFarmer(state) {
			state.createFarmerComplete = null;
			state.createdFarmerId = null;
		},
		clearUpdateFarmer(state) {
			state.updateFarmerComplete = null;
		},
		clearDeleteFarmer(state) {
			state.deleteFarmerComplete = null;
		},
		clearFetchOneFarmer(state) {
			state.fetchOneFarmerComplete = null;
		},
		clearGenerateToken(state) {
			state.generateTokenComplete = null;
		},
		clearUploadPemFile(state) {
			state.uploadPemFileComplete = null;
		},
	},
	extraReducers(builder) {
		builder.addCase(fetchAllFarmers.pending, (state) => {
			state.farmerListLoading = true;
		});
		builder.addCase(fetchAllFarmers.fulfilled, (state, action) => {
			state.farmerListLoading = false;
			state.fetchAllFarmersComplete = APIStatus.FULFILLED;
			state.farmers = _.unionBy(state.farmers, action.payload.data.data, "id");
			state.farmerCount = action.payload.data.count;
		});
		builder.addCase(fetchAllFarmers.rejected, (state) => {
			state.farmerListLoading = false;
			state.fetchAllFarmersComplete = APIStatus.REJECTED;
		});
		builder.addCase(createFarmer.pending, (state) => {
			state.disableSidebar = true;
		});
		builder.addCase(createFarmer.fulfilled, (state, action) => {
			state.disableSidebar = false;
			state.createFarmerComplete = APIStatus.FULFILLED;
			state.createdFarmerId = action.payload.data.id;
		});
		builder.addCase(createFarmer.rejected, (state) => {
			state.disableSidebar = false;
			state.createFarmerComplete = APIStatus.REJECTED;
		});
		builder.addCase(updateFarmer.pending, (state) => {
			state.disableSidebar = true;
		});
		builder.addCase(updateFarmer.fulfilled, (state) => {
			state.disableSidebar = false;
			state.updateFarmerComplete = APIStatus.FULFILLED;
		});
		builder.addCase(updateFarmer.rejected, (state) => {
			state.disableSidebar = false;
			state.updateFarmerComplete = APIStatus.REJECTED;
		});
		builder.addCase(deleteFarmer.pending, (state) => {
			state.disableSidebar = true;
		});
		builder.addCase(deleteFarmer.fulfilled, (state) => {
			state.disableSidebar = false;
			state.deleteFarmerComplete = APIStatus.FULFILLED;
		});
		builder.addCase(deleteFarmer.rejected, (state) => {
			state.disableSidebar = false;
			state.deleteFarmerComplete = APIStatus.REJECTED;
		});
		builder.addCase(fetchOneFarmer.pending, (state) => {
			state.farmerLoading = true;
		});
		builder.addCase(fetchOneFarmer.fulfilled, (state, action) => {
			state.farmerLoading = false;
			state.farmer = action.payload.data;
			state.fetchOneFarmerComplete = APIStatus.FULFILLED;
		});
		builder.addCase(fetchOneFarmer.rejected, (state) => {
			state.farmerLoading = false;
			state.fetchOneFarmerComplete = APIStatus.REJECTED;
		});
		builder.addCase(generateToken.pending, (state) => {
			state.disableSidebar = true;
		});
		builder.addCase(generateToken.fulfilled, (state, action) => {
			state.disableSidebar = false;
			state.generateTokenComplete = APIStatus.FULFILLED;
			state.tokenDetails = action.payload.data;
		});
		builder.addCase(generateToken.rejected, (state) => {
			state.disableSidebar = false;
			state.generateTokenComplete = APIStatus.REJECTED;
		});
		builder.addCase(uploadPemFile.fulfilled, (state) => {
			state.uploadPemFileComplete = APIStatus.FULFILLED;
		});
		builder.addCase(uploadPemFile.rejected, (state) => {
			state.uploadPemFileComplete = APIStatus.REJECTED;
		});
	},
});

export const {
	clearFarmers,
	clearFetchAllFarmers,
	clearCreateFarmer,
	clearUpdateFarmer,
	clearDeleteFarmer,
	clearFetchOneFarmer,
	clearGenerateToken,
	clearUploadPemFile,
} = farmerSlice.actions;

export default farmerSlice.reducer;
