import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Config } from "../../../config";
import { APIStatus } from "../../../constants";
import { Locations } from "../../../constants/locations";
import { useAppDispatch, useAppSelector } from "../../../modal/hooks";
import I18, { i18Get } from "../../../plugins/i18";
import { resetPassword, verifyToken } from "../../../services/user/user.service";
import { clearResetPassword, clearVerifyToken } from "../../../services/user/user.slice";

type InvalidProps = {
	newPassword: boolean;
	confirmPassword: boolean;
};

export const ResetPassword: React.FunctionComponent = () => {
	const newPasswordRef = useRef<HTMLInputElement>(null);
	const confirmPasswordRef = useRef<HTMLInputElement>(null);
	const [invalid, setInvalid] = useState<InvalidProps>({ newPassword: false, confirmPassword: false });
	const [loading, setLoading] = useState<boolean>(false);
	const [showPassword, setShowPassword] = useState<boolean>(false);
	const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false);

	const user = useAppSelector((store) => store.user);
	const params = useParams();
	const navigate = useNavigate();
	const dispatch = useAppDispatch();

	useEffect(() => {
		if (params.token) {
			setLoading(true);
			dispatch(verifyToken(params.token));
		} else {
			navigate(Locations.LOGIN);
		}
	}, [params.token]);

	useEffect(() => {
		if (user.verifyTokenComplete === APIStatus.FULFILLED) {
			setLoading(false);
			dispatch(clearVerifyToken());
		}
		if (user.verifyTokenComplete === APIStatus.REJECTED) {
			setLoading(false);
			navigate(Locations.LOGIN);
			dispatch(clearVerifyToken());
		}
	}, [user.verifyTokenComplete]);

	useEffect(() => {
		if (user.resetPasswordComplete === APIStatus.FULFILLED) {
			setLoading(false);
			navigate(Locations.LOGIN);
			dispatch(clearResetPassword());
		}
		if (user.resetPasswordComplete === APIStatus.REJECTED) {
			setLoading(false);
			dispatch(clearResetPassword());
		}
	}, [user.resetPasswordComplete]);

	const validate = (): boolean => {
		const prevState: InvalidProps = JSON.parse(JSON.stringify(invalid));
		if (!newPasswordRef.current?.value || !newPasswordRef.current?.value.trim()) {
			prevState.newPassword = true;
		}
		if (!confirmPasswordRef.current?.value || !confirmPasswordRef.current?.value.trim()) {
			prevState.confirmPassword = true;
		}
		if (newPasswordRef.current?.value.trim() && confirmPasswordRef.current?.value.trim()) {
			if (newPasswordRef.current?.value !== confirmPasswordRef.current?.value) {
				prevState.confirmPassword = true;
			}
		}
		setInvalid(prevState);
		return !(prevState.newPassword || prevState.confirmPassword);
	};

	const resetClicked = () => {
		if (validate() && params.token) {
			setLoading(true);
			dispatch(
				resetPassword({
					password: newPasswordRef.current?.value ?? "",
					token: params.token,
				})
			);
		}
	};

	return (
		<div className="login_container">
			<div className="login_card">
				<div className="login_heading">
					<I18 tkey="LOGIN_HEADER" />
				</div>
				<div className="login_head_dec mb-3">
					<I18 tkey="RESET_SUBHEADER" />
				</div>
				<div className="login_input_container mb-2">
					<div className="login_input_label">
						<I18 tkey="NEW_PASSWORD_INPUT_PLACEHOLDER" />
					</div>
					<div className="position-relative password_eye_container">
						<input
							className={`${invalid.newPassword ? "invalid_input" : ""}`}
							ref={newPasswordRef}
							type={showPassword ? "text" : "password"}
							maxLength={100}
							placeholder={i18Get("NEW_PASSWORD_INPUT_PLACEHOLDER", Config.defaultLanguage)}
							onChange={() => setInvalid((prevState) => ({ ...prevState, newPassword: false }))}
						/>
						{showPassword ? (
							<a className="password_eye" key="password-fa-eye-slash" onClick={() => setShowPassword(false)}>
								<i className="fas fa-eye-slash"></i>
							</a>
						) : (
							<a className="password_eye" key="password-fa-eye" onClick={() => setShowPassword(true)}>
								<i className="fas fa-eye"></i>
							</a>
						)}
					</div>
				</div>
				<div className="login_input_container">
					<div className="login_input_label">
						<I18 tkey="CONFIRM_PASSWORD_INPUT" />
					</div>
					<div className="position-relative password_eye_container">
						<input
							className={`${invalid.confirmPassword ? "invalid_input" : ""}`}
							ref={confirmPasswordRef}
							placeholder={i18Get("CONFIRM_PASSWORD_INPUT", Config.defaultLanguage)}
							type={showConfirmPassword ? "text" : "password"}
							maxLength={100}
							onChange={() => setInvalid((prevState) => ({ ...prevState, confirmPassword: false }))}
						/>
						{showConfirmPassword ? (
							<a className="password_eye" key="fa-eye-slash" onClick={() => setShowConfirmPassword(false)}>
								<i className="fas fa-eye-slash"></i>
							</a>
						) : (
							<a className="password_eye" key="fa-eye" onClick={() => setShowConfirmPassword(true)}>
								<i className="fas fa-eye"></i>
							</a>
						)}
					</div>
				</div>
				<div className="text-center px-2 mt-3">
					<button className="primary_btn login_btn" disabled={loading} hidden={loading} onClick={resetClicked}>
						<I18 tkey="SUBMIT" />
					</button>
					<button className="primary_btn login_btn" hidden={!loading} disabled>
						<div className="button_spinner">
							<div></div>
							<div></div>
							<div></div>
							<div></div>
						</div>
					</button>
				</div>
			</div>
		</div>
	);
};
