import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Config } from "../../../config";
import { APIStatus } from "../../../constants";
import { Locations } from "../../../constants/locations";
import { useAppDispatch, useAppSelector } from "../../../modal/hooks";
import I18 from "../../../plugins/i18";
import { generateToken } from "../../../services/farmer/farmer.service";
import { clearGenerateToken } from "../../../services/farmer/farmer.slice";
import { createLocation } from "../../../utils/util.fns";
import "./generate-token.scss";

export const GenerateToken = () => {
	const [loading, setLoading] = useState<boolean>(true);
	const params = useParams();
	const [searchParams] = useSearchParams();
	const navigate = useNavigate();
	const farmer = useAppSelector((store) => store.farmer);
	const dispatch = useAppDispatch();

	useEffect(() => {
		if (params.id) {
			dispatch(
				generateToken({
					code: searchParams.get("code") ?? "",
					userId: params.id,
					callbackUrl: encodeURIComponent(
						createLocation(Config.domain + Locations.GENERATE_TOKEN, { id: `${params.id}` })
					),
				})
			);
		} else {
			navigate(Locations.FARMER_LIST);
		}
	}, [params.id]);

	useEffect(() => {
		if (farmer.generateTokenComplete === APIStatus.FULFILLED) {
			setLoading(false);
			dispatch(clearGenerateToken());
		}
		if (farmer.generateTokenComplete === APIStatus.REJECTED) {
			setLoading(false);
			dispatch(clearGenerateToken());
			navigate(Locations.FARMER_LIST);
		}
	}, [farmer.generateTokenComplete]);

	return (
		<div className="main_content_container">
			<div className="main_content_section ">
				<div className="main_content_heading">
					<I18 tkey="NEW_TOKEN_GENERATED" />
				</div>
				{loading && (
					<div className="generate_token_container px-4 py-3 d-flex align-items-center justify-content-center flex-column">
						<div className="button_spinner">
							<div></div>
							<div></div>
							<div></div>
							<div></div>
						</div>
						<div className="mt-2">
							<I18 tkey="GENERATING_TOKEN" />
						</div>
					</div>
				)}
				{!loading ? (
					<div className="generate_token_container">
						<div className="generate_token_content">
							<div className="mb-3">
								<div className="mb-1 font_weight_medium">
									<I18 tkey="CREATED_TIME" />:
								</div>
								<div className="flex_1_1_10">
									{farmer.tokenDetails?.createTime ? new Date(farmer.tokenDetails.createTime).toString() : ""}
								</div>
							</div>
							<div className="mb-3">
								<div className="mb-1 font_weight_medium">
									<I18 tkey="ACCESS_TOKEN" />:
								</div>
								<div className="word_break">{farmer.tokenDetails?.access_token}</div>
							</div>
						</div>
					</div>
				) : (
					""
				)}
			</div>
			<div className="main_footer_section d-flex align-items-center justify-content-end">
				<button className="primary_border_btn min_width_120 mr-3" onClick={() => navigate(-1)}>
					<I18 tkey="GO_BACK" />
				</button>
			</div>
		</div>
	);
};
