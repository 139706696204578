export enum Locations {
	BASE = "/",
	UPLOAD_MODE = "/image-mode",
	IMAGE_CAPTURE = "/image-mode/capture",
	IMAGE_UPLOAD = "/image-mode/upload",
	BATCH = "/batch",
	PRODUCT_CREATE = "/batch/:batchId/create",
	PRODUCT_LIST = "/product-list",
	SETTINGS = "/settings",
	IMAGE_PREVIEW = "/image-mode/preview",
	PRODUCT = "/product",
	PRODUCT_DETAILS = "/product/:batchId/details",
	LOGIN = "/login",
	FORGOT_PASSWORD = "/forgot-password",
	RESET_PASSWORD = "/reset-password/:token",
	RESET_PASSWORD_URL = "/reset-password",
	FARMER = "/farmer",
	FARMER_CREATE = "/farmer/create",
	FARMER_LIST = "/farmer/list",
	FARMER_DETAILS = "/farmer/:id/details",
	ITEM_LIST = "/item/list",
	ITEM = "/item",
	ITEM_CREATE = "/item/create",
	ITEM_DETAILS = "/item/:itemId/details",
	FARMER_SUPPORTED_ITEMS = "/farmer-supported-items",
	FARMER_SUPPORTED_ITEMS_LIST = "/farmer-supported-items/list",
	FARMER_SUPPORTED_ITEMS_CREATE = "/farmer-supported-items/create",
	FARMER_SUPPORTED_ITEMS_DETAILS = "/farmer-supported-items/:id/details",
	ADMIN_PRODUCTS_LIST = "/admin-product-list",
	ADMIN_PRODUCT_EDIT = "/admin-product-list/:batchId/details",
	GENERATE_TOKEN = "/token/user/:id",
}
