import React from "react";
import { Select } from "antd";
import { i18Get } from "../../../plugins/i18";
import { Config } from "../../../config";

interface UnitSelectProps {
	className?: string;
	value: string | null;
    disabled: boolean;
	valueChanged(value: string | null): void;
}

export const unitOptions = [
	{ value: i18Get("UNIT_KG_JP", Config.defaultLanguage), label: i18Get("UNIT_KG_JP", Config.defaultLanguage) },
	{ value: i18Get("UNIT_G_JP", Config.defaultLanguage), label: i18Get("UNIT_G_JP", Config.defaultLanguage) },
	{ value: i18Get("UNIT_KG", Config.defaultLanguage), label: i18Get("UNIT_KG", Config.defaultLanguage) },
	{ value: i18Get("UNIT_G", Config.defaultLanguage), label: i18Get("UNIT_G", Config.defaultLanguage) },
	{ value: i18Get("UNIT_ML", Config.defaultLanguage), label: i18Get("UNIT_ML", Config.defaultLanguage) },
	{ value: i18Get("UNIT_L", Config.defaultLanguage), label: i18Get("UNIT_L", Config.defaultLanguage) },
	{ value: i18Get("UNIT_PIECE_JP", Config.defaultLanguage), label: i18Get("UNIT_PIECE_JP", Config.defaultLanguage) }
];

export const UnitSelect: React.FunctionComponent<UnitSelectProps> = (props) => {

	return (
		<Select
			className={`${props.className ?? ""} w-100`}
			placeholder={i18Get("UNIT_PLACEHOLDER", Config.defaultLanguage)}
			disabled={props.disabled}
			value={props.value}
			options={unitOptions}
			onChange={props.valueChanged}/>
	);
};
