import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Footer } from "../../../components/mobile/footer";
import { Header } from "../../../components/mobile/header";
import { FarmerSupportedItemCard } from "../../../components/mobile/farmer-supported-item-card";
import { Locations } from "../../../constants/locations";
import { PaginationWithNumber } from "../../../modal/common";
import { useAppDispatch, useAppSelector } from "../../../modal/hooks";
import I18 from "../../../plugins/i18";
import { fetchAllFarmerSupportedItems } from "../../../services/farmer-supported-items/farmer-supported-items.service";
import {
	clearFarmerSupportedItemList,
	FarmerSupportedItem,
} from "../../../services/farmer-supported-items/farmer-supported-items.slice";
import "./settings-list.scss";

export const FarmerSupportedItemList: React.FunctionComponent = () => {
	const [pagination, setPagination] = useState<PaginationWithNumber>({ pageNumber: 0, numResults: 10 });
	const farmerSupportedItem = useAppSelector((store) => store.farmerSupportedItem);
	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	useEffect(() => {
		return () => {
			dispatch(clearFarmerSupportedItemList());
		};
	}, []);

	useEffect(() => {
		dispatch(
			fetchAllFarmerSupportedItems({
				pageNumber: pagination.pageNumber,
				numResults: pagination.numResults,
			})
		);
	}, [pagination.pageNumber]);

	const pageScrolled = (e: React.UIEvent<HTMLElement>) => {
		if (farmerSupportedItem.farmerSupportedItemList.length < farmerSupportedItem.farmerSupportedItemCount) {
			const tableViewHeight = e.currentTarget.offsetHeight;
			const tableScrollHeight = e.currentTarget.scrollHeight;
			const scrollLocation = e.currentTarget.scrollTop;
			const buffer = 200;
			const limit = tableScrollHeight - tableViewHeight - buffer;
			if (scrollLocation > limit) {
				setPagination((prevState) => ({ ...prevState, pageNumber: ++prevState.pageNumber }));
			}
		}
	};

	return (
		<div className="h-100 w-100">
			<Header class="user_img_header" headerText="SUPPORTED_ITEMS_LIST_MENU" />
			<div className="main_container main_container_navigate_footer">
				<div className="text-right px-3 pt-3">
					<button
						className="primary_btn min_width_120"
						onClick={() => navigate(Locations.FARMER_SUPPORTED_ITEMS_CREATE)}
					>
						<I18 tkey="CREATE_BTN"></I18>
					</button>
				</div>
				<div className="product_list_container item_list_container position-relative" onScroll={pageScrolled}>
					{farmerSupportedItem &&
						farmerSupportedItem.farmerSupportedItemList.map((data: FarmerSupportedItem) => (
							<FarmerSupportedItemCard key={data.id} farmerSupportedItem={data} />
						))}
					{(!farmerSupportedItem.farmerSupportedItemList || !farmerSupportedItem.farmerSupportedItemList.length) && !farmerSupportedItem.farmerSupportedItemListLoading ? (
						<div className="shade_loading">
							<I18 tkey="NO_DATA" />
						</div>
					) : null}
					{farmerSupportedItem.farmerSupportedItemListLoading ? (
						<div className="shade_loading">
							<div className="button_spinner">
								<div></div>
								<div></div>
								<div></div>
								<div></div>
							</div>
						</div>
					) : null}
				</div>
			</div>
			<Footer navigateFooter />
		</div>
	);
};
