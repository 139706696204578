import React from "react";
import { FooterProps } from "../modal";
import "./footer.scss";
import { NavigationalFooter } from "./navigation-footer";
import I18 from "../../../plugins/i18";

export const Footer: React.FunctionComponent<FooterProps> = (props) => {
	return (
		<>
			{props.navigateFooter ? (
				<NavigationalFooter />
			) : (
				<div className="custom_footer_container ">
					{props.markAsSold ? (
						<div className="mb-2 text-center">
							<button
								className="primary_border_btn min_width_255"
								hidden={props.showMarkAsSoldLoader}
								disabled={props.markAsSoldDisable}
								onClick={props.markAsSoldChange}
							>
								<I18 tkey={props.markAsSoldText ? props.markAsSoldText : ""} />
							</button>
							<button
								className={`primary_border_btn min_width_120 mr-3 ${props.markAsSoldDisable ? "disabled_btn" : ""}`}
								hidden={!props.showMarkAsSoldLoader}
								disabled={props.markAsSoldDisable}
							>
								<div className="button_spinner">
									<div></div>
									<div></div>
									<div></div>
									<div></div>
								</div>
							</button>
						</div>
					) : (
						""
					)}
					<div className="d-flex align-items-center justify-content-center">
						<button
							className={`primary_border_btn min_width_120 mr-3 ${props.secondaryBtnDisable ? "disabled_btn" : ""}`}
							hidden={props.showSecondaryLoader}
							disabled={props.secondaryBtnDisable}
							onClick={props.secondaryBtnChange}
						>
							<I18 tkey={props.secondaryText ? props.secondaryText : ""} />
						</button>
						<button
							className={`primary_border_btn min_width_120 mr-3 ${props.secondaryBtnDisable ? "disabled_btn" : ""}`}
							hidden={!props.showSecondaryLoader}
							disabled={props.secondaryBtnDisable}
						>
							<div className="button_spinner">
								<div></div>
								<div></div>
								<div></div>
								<div></div>
							</div>
						</button>
						{props.primaryText ? (
							<button
								className={`primary_btn min_width_120 ${props.primaryBtnDisable ? "disabled_btn" : ""}`}
								hidden={props.showPrimaryLoader}
								disabled={props.primaryBtnDisable}
								onClick={props.primaryBtnChange}
							>
								<I18 tkey={props.primaryText ? props.primaryText : ""} />
							</button>
						) : (
							""
						)}
						<button
							className={`primary_btn min_width_120 ${props.primaryBtnDisable ? "disabled_btn" : ""}`}
							hidden={!props.showPrimaryLoader}
							disabled={props.primaryBtnDisable}
						>
							<div className="button_spinner">
								<div></div>
								<div></div>
								<div></div>
								<div></div>
							</div>
						</button>
					</div>
				</div>
			)}
		</>
	);
};
