import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { Config } from "../../config";
import { ProductStatus } from "../../constants";
import { axiosConfig } from "../../utils/util.fns";

export interface AdminProductQuery {
	pageNumber: number;
	numResults: number;
	userId?: string;
}

export const fetchAdminProductById = createAsyncThunk("adminProduct/fetchById", async (id) => {
	const response = await axios.get(Config.apiSecureUrl + `/product/${id}`, axiosConfig());
	return response.data;
});

export const fetchAllAdminProducts = createAsyncThunk(
	"adminProduct/fetchAll",
	async (adminProductQuery: AdminProductQuery) => {
		const response = await axios.get(
			Config.apiSecureUrl +
				`/product?offset=${adminProductQuery.pageNumber}&numResults=${adminProductQuery.numResults}&status=${
					ProductStatus.READY_FOR_PUBLISH
				}${adminProductQuery.userId ? `&userId=${adminProductQuery.userId}` : ""}`,
			axiosConfig()
		);
		return response.data;
	}
);

export const publish = createAsyncThunk("adminProduct/publish", async (publishList: number[]) => {
	const response = await axios.post(Config.apiSecureUrl + "/product/publish", publishList, axiosConfig());
	return response.data;
});
