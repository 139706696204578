import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { APIStatus } from "../../../constants";
import { Locations } from "../../../constants/locations";
import { createLocation, showError } from "../../../utils/util.fns";
import { useAppDispatch, useAppSelector } from "../../../modal/hooks";
import I18 from "../../../plugins/i18";
import { uploadFile } from "../../../services/product/product.service";
import { clearUploadFile } from "../../../services/product/product.slice";
import "./image-capture.scss";

let streamData: MediaStream | null = null;

export const ImageCapture: React.FunctionComponent = () => {
	const [captureComplete, setCaptureComplete] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(true);
	const [dimension, setDimension] = useState<{ height: number; width: number }>({ height: 2160, width: 4096 });
	const [uploading, setUploading] = useState<boolean>(false);
	const videoRef = useRef<HTMLVideoElement>(null);
	const canvasRef = useRef<HTMLCanvasElement>(null);
	const imageRef = useRef<HTMLImageElement>(null);
	const navigate = useNavigate();
	const product = useAppSelector((store) => store.product);
	const dispatch = useAppDispatch();

	useEffect(() => {
		if (!window || !window.navigator || !window.navigator.mediaDevices) {
			showError("MEDIA_DEVICE_UNAVAILABLE");
			navigate(Locations.UPLOAD_MODE);
		}
		if (!captureComplete) {
			window.navigator.mediaDevices
				.getUserMedia({
					video: { width: { ideal: 4096 }, height: { ideal: 2160 } },
					audio: false,
				})
				.then(function (stream) {
					streamData = stream;
					if (videoRef.current) {
						videoRef.current.srcObject = stream;
						videoRef.current.autoplay = false;
						videoRef.current.muted = true;
						videoRef.current.playsInline = true;
						setDimension((prevState) => ({
							...prevState,
							width: videoRef.current?.offsetWidth ?? 4096,
							height: videoRef.current?.offsetHeight ?? 2160,
						}));
						videoRef.current
							.play()
							.then(() => {
								setLoading(false);
								console.log("video started");
							})
							.catch((e) => {
								console.log("error", e);
							});
					}
				})
				.catch(function (error) {
					if (error.name === "ConstraintNotSatisfiedError") {
						showError("UNSUPPORTED_RESOLUTION");
					} else if (error.name === "NotAllowedError") {
						showError("PERMISSION_NOT_GRANTED");
					} else {
						showError(`getUserMedia error: ${error.name},  ${error}`);
					}
				});
		}

		return () => {
			streamData?.getTracks().forEach(function (track) {
				track.stop();
			});
		};
	}, [captureComplete]);

	useEffect(() => {
		if (product.fileUploadComplete === APIStatus.FULFILLED) {
			navigate(createLocation(Locations.PRODUCT_CREATE, { batchId: product.batchId ? `${product.batchId}` : "" }));
			dispatch(clearUploadFile());
			setUploading(false);
		}
		if (product.fileUploadComplete === APIStatus.REJECTED) {
			setUploading(false);
		}
	}, [product.fileUploadComplete]);

	const takePicture = () => {
		if (canvasRef.current) {
			const context = canvasRef.current.getContext("2d");
			canvasRef.current.width = dimension.width;
			canvasRef.current.height = dimension.height;
			if (videoRef.current) {
				context?.drawImage(videoRef.current, 0, 0, dimension.width, dimension.height);
				const data = canvasRef.current.toDataURL("image/png");
				imageRef.current?.setAttribute("src", data);
				if (data) {
					setCaptureComplete(true);
					streamData?.getTracks().forEach(function (track) {
						track.stop();
					});
				}
			}
		}
	};

	const clearPhoto = () => {
		setLoading(true);
		const context = canvasRef.current?.getContext("2d");
		if (context) {
			setCaptureComplete(false);
			context.fillStyle = "#AAA";
			context.fillRect(0, 0, dimension.width, dimension.height);
			const data = canvasRef.current ? canvasRef.current.toDataURL("image/png") : "";
			imageRef.current?.setAttribute("src", data);
		}
	};

	const fileUpload = () => {
		const payload: FormData = new FormData();
		if (canvasRef.current) {
			const data = canvasRef.current.toDataURL("image/png");
			const blobBin = atob(data.split(",")[1]);
			const array = [];
			for (let i = 0; i < blobBin.length; i++) {
				array.push(blobBin.charCodeAt(i));
			}
			const blob: Blob = new Blob([new Uint8Array(array)], { type: "image/png" });
			const file: File = new File([blob], `capture-${new Date().getTime()}.png`, {
				lastModified: new Date().getTime(),
			});
			payload.append("file", file);
			dispatch(uploadFile(payload));
			setUploading(true);
		}
	};

	return (
		<div className="main_content_container">
			<div className="main_content_section_capture pt-0">
				<div className="main_content_heading">
					<I18 tkey="PRODUCT_REGISTRATION_HEADER_WEB" />
				</div>
				<div className="upload_mode_container py-1 d-flex justify-content-center align-items-start">
					<div className="upload_mode_image_capture_box">
						{!captureComplete ? (
							<div className="image_capture_container">
								<video ref={videoRef}>
									<I18 tkey="VIDEO_STREAM_UNAVAILABLE" />
								</video>
								<span
									className={`capture_button ${loading ? "disabled_capture_btn" : ""}`}
									onClick={() => (!loading ? takePicture() : null)}
								>
									<svg xmlns="http://www.w3.org/2000/svg" width="55" height="49" viewBox="0 0 55 49" fill="none">
										<g filter="url(#filter0_i_604_256)">
											<path
												d="M54.9962 13.9736C54.9962 23.8062 54.9962 33.64 54.9962 43.4726C54.8803 43.9058 54.7924 44.348 54.6431 44.7682C53.7186 47.3658 51.3234 48.9762 48.3819 48.9762C34.6032 48.9788 20.8246 48.9775 7.04589 48.9775C6.90334 48.9775 6.76213 48.9788 6.61959 48.971C4.60401 48.8721 2.97743 48.0448 1.73051 46.4995C1.0098 45.6072 0.671432 44.56 0.431641 43.4713C0.431641 33.6387 0.431641 23.8049 0.431641 13.9723C0.458284 13.9086 0.496917 13.8475 0.510239 13.7824C1.26958 10.3419 3.64751 8.46745 7.2577 8.46745C9.601 8.46745 11.9456 8.44403 14.2876 8.48176C15.0003 8.49346 15.3866 8.24632 15.6677 7.61934C16.2286 6.3693 16.86 5.14917 17.4635 3.91734C18.4147 1.97528 19.996 0.994498 22.2007 0.990596C25.9295 0.984092 29.6569 0.984092 33.3856 0.990596C35.5931 0.994498 37.1717 1.98569 38.1242 3.92124C38.753 5.1986 39.3844 6.47466 39.9866 7.76373C40.2224 8.26973 40.5607 8.48176 41.1402 8.47655C43.6261 8.45444 46.1119 8.44924 48.5964 8.47395C50.8051 8.49606 52.557 9.41701 53.8558 11.1548C54.4833 11.9978 54.7697 12.9746 54.9962 13.9736ZM27.7013 44.8228C34.5739 44.8228 41.4466 44.8241 48.3206 44.8215C49.878 44.8215 50.7319 43.9942 50.7332 42.484C50.7345 33.3109 50.7345 24.1366 50.7332 14.9635C50.7332 13.452 49.8793 12.626 48.3233 12.6234C45.8721 12.6208 43.4222 12.6273 40.971 12.6208C38.765 12.6156 37.181 11.64 36.2272 9.70058C35.5917 8.40631 34.9469 7.11724 34.3341 5.81257C34.1143 5.34429 33.8066 5.13486 33.2697 5.13747C29.6116 5.15047 25.9521 5.15047 22.294 5.13877C21.7797 5.13747 21.4747 5.33778 21.2615 5.78525C20.6527 7.07172 20.0133 8.34518 19.3925 9.62644C18.4227 11.6296 16.8107 12.6312 14.534 12.6247C12.1015 12.6182 9.66761 12.6221 7.23505 12.6234C5.50056 12.6234 4.6946 13.4078 4.69327 15.0962C4.69327 24.1834 4.69327 33.2706 4.69327 42.3578C4.69327 44.041 5.5019 44.8228 7.24172 44.8228C14.0611 44.8228 20.8819 44.8228 27.7013 44.8228Z"
												fill="#8FAF98"
											/>
											<path
												d="M27.7762 14.702C35.1338 14.7683 41.1006 20.64 41.0326 27.7461C40.9634 34.9173 34.9406 40.7318 27.6496 40.6654C20.292 40.5991 14.3252 34.7274 14.3932 27.6213C14.4611 20.4501 20.4852 14.6356 27.7762 14.702ZM36.7697 27.7214C36.7963 22.8812 32.7452 18.8931 27.7642 18.8567C22.8005 18.8215 18.7014 22.7642 18.6561 27.6213C18.6095 32.4771 22.6913 36.4939 27.6882 36.5121C32.6559 36.529 36.7417 32.5733 36.7697 27.7214Z"
												fill="#8FAF98"
											/>
											<path
												d="M42.2305 20.8951C42.2305 19.5175 42.2305 18.1764 42.2305 16.8054C43.6306 16.8054 45.0067 16.8054 46.4268 16.8054C46.4268 18.1478 46.4268 19.5019 46.4268 20.8951C45.0547 20.8951 43.6652 20.8951 42.2305 20.8951Z"
												fill="#8FAF98"
											/>
										</g>
										<defs>
											<filter
												id="filter0_i_604_256"
												x="0.431641"
												y="0.985718"
												width="54.5645"
												height="49.9921"
												filterUnits="userSpaceOnUse"
												colorInterpolationFilters="sRGB"
											>
												<feFlood floodOpacity="0" result="BackgroundImageFix" />
												<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
												<feColorMatrix
													in="SourceAlpha"
													type="matrix"
													values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
													result="hardAlpha"
												/>
												<feOffset dy="2" />
												<feGaussianBlur stdDeviation="2" />
												<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
												<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
												<feBlend mode="normal" in2="shape" result="effect1_innerShadow_604_256" />
											</filter>
										</defs>
									</svg>
								</span>
							</div>
						) : null}
						<canvas hidden ref={canvasRef}></canvas>
						<div
							className={` ${
								captureComplete ? "image_capture_container d-flex align-items-center justify-content-center" : ""
							}`}
						>
							<img
								className="image_capture_container_img"
								ref={imageRef}
								hidden={!captureComplete}
								alt="The screen capture will appear in this box."
							/>
						</div>
						<div className="main_footer_section_capture d-flex align-items-center">
							<div className={`w-100 d-flex align-items-center ${captureComplete ? "justify-content-end" : ""}`}>
								{captureComplete ? (
									<button
										className="primary_border_btn min_width_120 mr-3"
										disabled={uploading || loading}
										onClick={clearPhoto}
									>
										<I18 tkey="REUPLOAD_BTN" />
									</button>
								) : (
									<button
										className="primary_border_btn min_width_120 mr-3"
										disabled={uploading || loading}
										onClick={() => navigate(Locations.UPLOAD_MODE)}
									>
										<I18 tkey="CANCEL_BTN" />
									</button>
								)}
								{captureComplete ? (
									<>
										<button
											className="primary_btn min_width_120"
											hidden={uploading}
											disabled={uploading}
											onClick={fileUpload}
										>
											<I18 tkey="UPLOAD_BTN" />
										</button>
										<button className="primary_btn min_width_120" hidden={!uploading} disabled>
											<div className="button_spinner">
												<div></div>
												<div></div>
												<div></div>
												<div></div>
											</div>
										</button>
									</>
								) : (
									""
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
