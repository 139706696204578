import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { Config } from "../../config";
import { PaginationWithNumber } from "../../modal/common";
import { axiosConfig, axiosMultiPartConfig } from "../../utils/util.fns";

type UserCreatePayload = {
	firstName: string;
	lastName: string;
	email: string;
	password: string;
};

export type FarmerCreatePayload = {
	contact: string;
	yahooShoppingId: string;
	sellerId: string;
	yahooShoppingPassword: string;
	availableTimeForCall: string;
	user: UserCreatePayload;
	clientId: string;
	clientSecret: string;
	base64UserImg: string | undefined;
};

type UserUpdatePayload = {
	firstName: string;
	lastName: string;
	email: string;
	password: string;
};

export type FarmerUpdatePayload = {
	contact: string;
	yahooShoppingId: string;
	sellerId: string;
	yahooShoppingPassword: string;
	availableTimeForCall: string;
	user: UserUpdatePayload;
	clientId: string;
	clientSecret: string;
	base64UserImg: string | undefined;
};

type UpdatePayload = {
	farmerId: number;
	payload: FarmerUpdatePayload;
};

type TokenPayload = {
	userId: string;
	code: string;
	callbackUrl: string;
};

type PemUploadPayload = {
	id: number;
	formData: FormData;
};

export const fetchAllFarmers = createAsyncThunk("farmer/fetchAll", async (pagination: PaginationWithNumber) => {
	const response = await axios.get(
		Config.apiSecureUrl + `/farmer/fetch?offset=${pagination.pageNumber}&numResults=${pagination.numResults}`,
		axiosConfig()
	);
	return response.data;
});

export const createFarmer = createAsyncThunk("farmer/create", async (farmer: FarmerCreatePayload) => {
	const response = await axios.post(Config.apiSecureUrl + "/farmer", farmer, axiosConfig());
	return response.data;
});

export const updateFarmer = createAsyncThunk("farmer/update", async (farmer: UpdatePayload) => {
	const response = await axios.put(Config.apiSecureUrl + `/farmer/${farmer.farmerId}`, farmer.payload, axiosConfig());
	return response.data;
});

export const deleteFarmer = createAsyncThunk("farmer/delete", async (id: number) => {
	const response = await axios.delete(Config.apiSecureUrl + `/farmer/${id}`, axiosConfig());
	return response.data;
});

export const fetchOneFarmer = createAsyncThunk("farmer/fetchOne", async (id: string) => {
	const response = await axios.get(Config.apiSecureUrl + `/farmer/fetch/${id}`, axiosConfig());
	return response.data;
});

export const generateToken = createAsyncThunk("farmer/generateToken", async (tokenPayload: TokenPayload) => {
	const response = await axios.get(
		Config.apiSecureUrl +
			`/auth/yahoo/japan/token?code=${tokenPayload.code}&userId=${tokenPayload.userId}&callbackUrl=${tokenPayload.callbackUrl}`,
		axiosConfig()
	);
	return response.data;
});

export const uploadPemFile = createAsyncThunk("farmer/upload/pemFile", async (payload: PemUploadPayload) => {
	const response = await axios.post(
		Config.apiSecureUrl + `/farmer/key/${payload.id}`,
		payload.formData,
		axiosMultiPartConfig()
	);
	return response.data;
});
