import React, { useEffect } from "react";
import { Config } from "../../../config";
import { Locations } from "../../../constants/locations";
import { createLocation } from "../../../utils/util.fns";

declare global {
	interface Window {
		yconnectInit(): void;
		YAHOO: any;
	}
}

type YahooLoginBtnProps = {
	clientId: string;
	userId: number;
};

export const YahooLoginBtn: React.FunctionComponent<YahooLoginBtnProps> = (props) => {
	useEffect(() => {
		if (props.clientId) {
			window.yconnectInit = () => {
				window.YAHOO.JP.yconnect.Authorization.init({
					button: {
						format: "image",
						type: "a",
						textType: "a",
						width: 160,
						height: 32,
						className: "yconnectLogin",
					},
					authorization: {
						clientId: props.clientId,
						redirectUri: createLocation(Config.domain + Locations.GENERATE_TOKEN, { id: `${props.userId}` }),
						scope: "openid profile",
						responseType: "code",
						state: "bcd",
						nonce: "abc",
						windowWidth: 800,
						windowHeight: 800,
					},
					onError: function (res: any) {
						console.log(res);
					},
					onCancel: function (res: any) {
						console.log(res);
					},
				});
			};
			(function () {
				const fs = document.getElementsByTagName("script")[0] as HTMLScriptElement;
				const s = document.createElement("script");
				s.setAttribute("src", "https://s.yimg.jp/images/login/yconnect/auth/2.0.4/auth-min.js");
				if (fs && fs.parentNode) {
					fs.parentNode.insertBefore(s, fs);
				}
			})();
		}
	}, [props.clientId]);

	return <span className="d-flex align-items-center mr-3 yconnectLogin"></span>;
};
