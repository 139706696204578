import React, { useState, useEffect } from "react";
import { Footer } from "../../../components/mobile/footer";
import { Header } from "../../../components/mobile/header";
import { ProductCard } from "../../../components/mobile/product-card";
import { PaginationWithNumber } from "../../../modal/common";
import { useAppDispatch, useAppSelector } from "../../../modal/hooks";
import I18 from "../../../plugins/i18";
import { getYahooProducts } from "../../../services/product/product.service";
import { clearYahooProducts } from "../../../services/product/product.slice";
import "./product-list.scss";

export const ProductList: React.FunctionComponent = () => {
	const [pagination, setPagination] = useState<PaginationWithNumber>({ pageNumber: 1, numResults: 100 });
	const product = useAppSelector((store) => store.product);
	const dispatch = useAppDispatch();

	useEffect(() => {
		return () => {
			dispatch(clearYahooProducts());
		};
	}, []);

	useEffect(() => {
		dispatch(
			getYahooProducts({
				pageNumber: pagination.pageNumber,
				numResults: pagination.numResults,
			})
		);
	}, [pagination.pageNumber]);

	const pageScrolled = (e: React.UIEvent<HTMLElement>) => {
		if (product.yahooProductList.length < product.yahooProductCount) {
			const tableViewHeight = e.currentTarget.offsetHeight;
			const tableScrollHeight = e.currentTarget.scrollHeight;
			const scrollLocation = e.currentTarget.scrollTop;
			const buffer = 200;
			const limit = tableScrollHeight - tableViewHeight - buffer;
			if (scrollLocation > limit && !product.yahooProductLoading) {
				setPagination((prevState) => ({ ...prevState, pageNumber: ++prevState.pageNumber }));
			}
		}
	};

	return (
		<div className="h-100 w-100">
			<Header class="user_img_header" headerText="PRODUCTS_HEADER_MOBILE" />
			<div className="main_container main_container_navigate_footer">
				<div className="product_list_container position_relative" onScroll={pageScrolled}>
					{product && product.yahooProductList.map((data) => <ProductCard key={data.itemCode} product={data} />)}
					{(!product.yahooProductList || !product.yahooProductList.length) && !product.yahooProductLoading ? (
						<div className="shade_loading">
							<I18 tkey="NO_PRODUCT_DATA" />
						</div>
					) : null}
					{product.yahooProductLoading ? (
						<div className="shade_loading">
							<div className="button_spinner">
								<div></div>
								<div></div>
								<div></div>
								<div></div>
							</div>
						</div>
					) : null}
				</div>
			</div>
			<Footer navigateFooter />
		</div>
	);
};
