import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import FarmerPemUpload, { FarmerPemUploadHandles } from "../../../components/common/farmer-pem-upload";
import { Footer } from "../../../components/mobile/footer";
import { Header } from "../../../components/mobile/header";
import { APIStatus, emailRegex, phoneNumberRegex } from "../../../constants";
import { Locations } from "../../../constants/locations";
import { useAppDispatch, useAppSelector } from "../../../modal/hooks";
import I18 from "../../../plugins/i18";
import { ProfileUpload } from "../../../plugins/profile-upload";
import { createFarmer } from "../../../services/farmer/farmer.service";
import { clearCreateFarmer } from "../../../services/farmer/farmer.slice";
import { showError } from "../../../utils/util.fns";
import "./farmer-create.scss";

type InvalidProps = {
	name: boolean;
	email: boolean;
	contact: boolean;
	availableTime: boolean;
	sellerId: boolean;
	yahooPassword: boolean;
	yahooShoppingId: boolean;
	clientId: boolean;
	clientSecret: boolean;
	password: boolean;
	confirmPassword: boolean;
	pemFile: boolean;
};

export const FarmerCreate: React.FunctionComponent = () => {
	const [profileImage, setProfileImage] = useState<string>("");
	const nameRef = useRef<HTMLInputElement>(null);
	const emailRef = useRef<HTMLInputElement>(null);
	const contactRef = useRef<HTMLInputElement>(null);
	const availableTimeRef = useRef<HTMLInputElement>(null);
	const sellerIdRef = useRef<HTMLInputElement>(null);
	const yahooPasswordRef = useRef<HTMLInputElement>(null);
	const yahooShoppingIdRef = useRef<HTMLInputElement>(null);
	const clientIdRef = useRef<HTMLInputElement>(null);
	const clientSecretRef = useRef<HTMLInputElement>(null);
	const passwordRef = useRef<HTMLInputElement>(null);
	const confirmPasswordRef = useRef<HTMLInputElement>(null);
	const pemUploadRef = useRef<FarmerPemUploadHandles>(null);
	const [invalid, setInvalid] = useState<InvalidProps>({
		name: false,
		email: false,
		contact: false,
		availableTime: false,
		sellerId: false,
		yahooPassword: false,
		yahooShoppingId: false,
		clientId: false,
		clientSecret: false,
		password: false,
		confirmPassword: false,
		pemFile: false
	});
	const [loading, setLoading] = useState<boolean>(false);
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const farmer = useAppSelector((store) => store.farmer);

	useEffect(() => {
		if (farmer.createFarmerComplete === APIStatus.FULFILLED) {
			if(farmer.createdFarmerId) {
				pemUploadRef.current?.uploadPem(farmer.createdFarmerId);
			}
			dispatch(clearCreateFarmer());
		}
		if (farmer.createFarmerComplete === APIStatus.REJECTED) {
			setLoading(false);
			dispatch(clearCreateFarmer());
		}
	}, [farmer.createFarmerComplete]);

	const validate = (): boolean => {
		const prevState: InvalidProps = JSON.parse(JSON.stringify(invalid));
		if (!nameRef.current?.value || !nameRef.current.value.trim()) {
			prevState.name = true;
		}
		if (!emailRef.current?.value || !emailRef.current.value.trim() || !emailRegex.test(emailRef.current.value)) {
			prevState.email = true;
		}
		if (
			!contactRef.current?.value ||
			!contactRef.current.value.trim() ||
			!phoneNumberRegex.test(contactRef.current.value)
		) {
			prevState.contact = true;
		}
		if (!availableTimeRef.current?.value || !availableTimeRef.current.value.trim()) {
			prevState.availableTime = true;
		}
		if (!sellerIdRef.current?.value || !sellerIdRef.current.value.trim()) {
			prevState.sellerId = true;
		}
		if (!yahooPasswordRef.current?.value || !yahooPasswordRef.current.value.trim()) {
			prevState.yahooPassword = true;
		}
		if (!yahooShoppingIdRef.current?.value || !yahooShoppingIdRef.current.value.trim()) {
			prevState.yahooShoppingId = true;
		}
		if (!clientIdRef.current?.value || !clientIdRef.current.value.trim()) {
			prevState.clientId = true;
		}
		if (!clientSecretRef.current?.value || !clientSecretRef.current.value.trim()) {
			prevState.clientSecret = true;
		}
		if (!passwordRef.current?.value || !passwordRef.current.value.trim()) {
			prevState.password = true;
		}
		if (passwordRef.current && passwordRef.current.value.length < 8) {
			showError("PASSWORD_MIN_LENGTH");
			prevState.password = true;
		}
		if (
			!confirmPasswordRef.current?.value ||
			!confirmPasswordRef.current.value.trim() ||
			passwordRef.current?.value !== confirmPasswordRef.current.value
		) {
			prevState.confirmPassword = true;
		}
		prevState.pemFile = pemUploadRef.current && pemUploadRef.current?.validate() ? pemUploadRef.current?.validate() : false;
		setInvalid(prevState);
		return !(
			prevState.name ||
			prevState.email ||
			prevState.contact ||
			prevState.availableTime ||
			prevState.sellerId ||
			prevState.yahooPassword ||
			prevState.yahooShoppingId ||
			prevState.clientId ||
			prevState.clientSecret ||
			prevState.password ||
			prevState.confirmPassword ||
			prevState.pemFile
		);
	};

	const create = () => {
		if (validate()) {
			setLoading(true);
			dispatch(
				createFarmer({
					contact: contactRef.current?.value ?? "",
					sellerId: sellerIdRef.current?.value.trim() ?? "",
					yahooShoppingPassword: yahooPasswordRef.current?.value.trim() ?? "",
					yahooShoppingId: yahooShoppingIdRef.current?.value.trim() ?? "",
					availableTimeForCall: availableTimeRef.current?.value ?? "",
					clientId: clientIdRef.current?.value.trim() ?? "",
					clientSecret: clientSecretRef.current?.value.trim() ?? "",
					user: {
						firstName: nameRef.current?.value ?? "",
						lastName: "",
						email: emailRef.current?.value.trim() ?? "",
						password: passwordRef.current?.value.trim() ?? "",
					},
					base64UserImg: profileImage ? profileImage : "",
				})
			);
		}
	};

	return (
		<>
			<Header headerText="FARMER_CREATE_HEADER_MOB" />
			<div className="main_container">
				<div className="farmer_create_form p-4">
					<ProfileUpload image={profileImage} onImageChange={(image: string) => setProfileImage(image)} />
					<div className="mb-2">
						<div className="farmer_form_label font_weight_medium">
							<I18 tkey="NAME_INPUT" />
						</div>
						<div className="farmer_form_input_container">
							<input
								className={`${invalid.name ? "invalid_input" : ""}`}
								ref={nameRef}
								type="text"
								maxLength={100}
								onChange={() => setInvalid((prevState) => ({ ...prevState, name: false }))}
							/>
						</div>
					</div>
					<div className="mb-2">
						<div className="farmer_form_label font_weight_medium">
							<I18 tkey="EMAIL_INPUT" />
						</div>
						<div className="farmer_form_input_container">
							<input
								className={`${invalid.email ? "invalid_input" : ""}`}
								ref={emailRef}
								type="email"
								inputMode="email"
								maxLength={100}
								onChange={() => setInvalid((prevState) => ({ ...prevState, email: false }))}
							/>
						</div>
					</div>
					<div className="mb-2">
						<div className="farmer_form_label font_weight_medium">
							<I18 tkey="CONTACT_INPUT" />
						</div>
						<div className="farmer_form_input_container">
							<input
								className={`${invalid.contact ? "invalid_input" : ""}`}
								ref={contactRef}
								type="tel"
								inputMode="tel"
								maxLength={100}
								onChange={() => setInvalid((prevState) => ({ ...prevState, contact: false }))}
							/>
						</div>
					</div>
					<div className="mb-2">
						<div className="farmer_form_label font_weight_medium">
							<I18 tkey="AVAILABLE_TIME_INPUT" />
						</div>
						<div className="farmer_form_input_container">
							<input
								className={`${invalid.availableTime ? "invalid_input" : ""}`}
								ref={availableTimeRef}
								type="text"
								maxLength={100}
								onChange={() => setInvalid((prevState) => ({ ...prevState, availableTime: false }))}
							/>
						</div>
					</div>
					<div className="mb-2">
						<div className="farmer_form_label font_weight_medium">
							<I18 tkey="YAHOO_ID_INPUT" />
						</div>
						<div className="farmer_form_input_container">
							<input
								className={`${invalid.yahooShoppingId ? "invalid_input" : ""}`}
								ref={yahooShoppingIdRef}
								type="text"
								maxLength={100}
								onChange={() => setInvalid((prevState) => ({ ...prevState, yahooShoppingId: false }))}
							/>
						</div>
					</div>
					<div className="mb-2">
						<div className="farmer_form_label font_weight_medium">
							<I18 tkey="YAHOO_PASSWORD_INPUT" />
						</div>
						<div className="farmer_form_input_container">
							<input
								className={`${invalid.yahooPassword ? "invalid_input" : ""}`}
								ref={yahooPasswordRef}
								type="text"
								maxLength={100}
								onChange={() => setInvalid((prevState) => ({ ...prevState, yahooPassword: false }))}
							/>
						</div>
					</div>
					<div className="mb-2">
						<div className="farmer_form_label font_weight_medium">
							<I18 tkey="SELLER_ID_INPUT" />
						</div>
						<div className="farmer_form_input_container">
							<input
								className={`${invalid.sellerId ? "invalid_input" : ""}`}
								ref={sellerIdRef}
								type="text"
								maxLength={100}
								onChange={() => setInvalid((prevState) => ({ ...prevState, sellerId: false }))}
							/>
						</div>
					</div>
					<div className="mb-2">
						<div className="farmer_form_label font_weight_medium">
							<I18 tkey="CLIENT_ID_INPUT" />
						</div>
						<div className="farmer_form_input_container">
							<input
								className={`${invalid.clientId ? "invalid_input" : ""}`}
								ref={clientIdRef}
								type="text"
								maxLength={100}
								onChange={() => setInvalid((prevState) => ({ ...prevState, clientId: false }))}
							/>
						</div>
					</div>
					<div className="mb-2">
						<div className="farmer_form_label font_weight_medium">
							<I18 tkey="CLIENT_SECRET_INPUT" />
						</div>
						<div className="farmer_form_input_container">
							<input
								className={`${invalid.clientSecret ? "invalid_input" : ""}`}
								ref={clientSecretRef}
								type="text"
								maxLength={100}
								onChange={() => setInvalid((prevState) => ({ ...prevState, clientSecret: false }))}
							/>
						</div>
					</div>
					<div className="mb-2">
						<div className="farmer_form_label font_weight_medium">
							<I18 tkey="PASSWORD_INPUT" />
						</div>
						<div className="farmer_form_input_container">
							<input
								className={`${invalid.password ? "invalid_input" : ""}`}
								ref={passwordRef}
								type="text"
								maxLength={100}
								onChange={() => setInvalid((prevState) => ({ ...prevState, password: false }))}
							/>
						</div>
					</div>
					<div className="mb-2">
						<div className="farmer_form_label font_weight_medium">
							<I18 tkey="CONFIRM_PASSWORD_INPUT" />
						</div>
						<div className="farmer_form_input_container">
							<input
								className={`${invalid.confirmPassword ? "invalid_input" : ""}`}
								ref={confirmPasswordRef}
								type="text"
								maxLength={100}
								onChange={() => setInvalid((prevState) => ({ ...prevState, confirmPassword: false }))}
							/>
						</div>
					</div>
					<div className="mb-2">
						<div className="farmer_form_label font_weight_medium">
							<I18 tkey="PEM_FILE" />
						</div>
						<div className="farmer_form_input_container d-flex align-items-center">
							<FarmerPemUpload 
								ref={pemUploadRef}
								showInvalidMsg={false}
								keyUpdateTime=""
								loading={loading}
								sellerId={sellerIdRef.current ? sellerIdRef.current.value : ""}
								setLoading={setLoading}/>
						</div>
					</div>
				</div>
			</div>
			<Footer
				primaryText="CREATE_BTN"
				secondaryText="CANCEL_BTN"
				primaryBtnChange={create}
				secondaryBtnChange={() => navigate(Locations.FARMER_LIST)}
				primaryBtnDisable={loading}
				secondaryBtnDisable={loading}
				showPrimaryLoader={loading}
			/>
		</>
	);
};
