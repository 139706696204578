import React from "react";
import { useNavigate } from "react-router-dom";
import { Locations } from "../../../constants/locations";
import { FarmerSupportedItem } from "../../../services/farmer-supported-items/farmer-supported-items.slice";
import { createLocation } from "../../../utils/util.fns";

type FarmerSupportedItemProps = {
	farmerSupportedItem: FarmerSupportedItem;
};

export const FarmerSupportedItemCard: React.FunctionComponent<FarmerSupportedItemProps> = (props) => {
	const navigate = useNavigate();

	return (
		<div
			className="product_list_item_container"
			onClick={() => {
				navigate(createLocation(Locations.FARMER_SUPPORTED_ITEMS_DETAILS, { id: `${props.farmerSupportedItem.id}` }));
			}}
		>
			<div className="product_list_item_label_container">
				<div className="product_list_item_label_main font_weight_black">
					{props.farmerSupportedItem && props.farmerSupportedItem.item.itemName
						? props.farmerSupportedItem.item.itemName
						: ""}
				</div>
			</div>
			<div className="product_list_item_more ml-2">
				<i className="fas fa-arrow-right"></i>
			</div>
		</div>
	);
};
