import React, { useEffect, useRef, useState } from "react";
import { ProductCarousel } from "../../../components/mobile/product-carousel/product-carousel";
import ProductForm, { ProductFormHandles } from "../../../components/mobile/product-form";
import "./product-create.scss";
import { Header } from "../../../components/mobile/header";
import { Footer } from "../../../components/mobile/footer";
import I18 from "../../../plugins/i18";
import { useAppDispatch, useAppSelector } from "../../../modal/hooks";
import { useNavigate, useParams } from "react-router-dom";
import { createProduct, fetchProduct, uploadAdditionalFile } from "../../../services/product/product.service";
import { APIStatus } from "../../../constants";
import {
	clearCreateProduct,
	clearFetchProduct,
	clearImage,
	clearProduct,
	clearUploadAdditionalFiles,
} from "../../../services/product/product.slice";
import { Locations } from "../../../constants/locations";
import ProductCreateSuccessModal, {
	ProductCreateSuccessModalHandles,
} from "../../../components/mobile/product-create-success-modal";
import { createLocation, showInfo, showWarning, validateUploadFile } from "../../../utils/util.fns";
import { Config } from "../../../config";
import dayjs from "dayjs";

export const ProductCreate: React.FunctionComponent = () => {
	const [loading, setLoading] = useState<boolean>(false);
	const [uploadedImg, setUploadedImg] = useState<string | null>(null);
	const productFormRef = useRef<ProductFormHandles>(null);
	const productSuccessModalRef = useRef<ProductCreateSuccessModalHandles>(null);
	const inputRef = useRef<HTMLInputElement>(null);
	const product = useAppSelector((store) => store.product);
	const dispatch = useAppDispatch();
	const params = useParams();
	const navigate = useNavigate();

	useEffect(() => {
		if (params.batchId) {
			dispatch(fetchProduct(params.batchId));
			dispatch(clearImage());
		} else {
			navigate(-1);
		}
		return () => {
			dispatch(clearProduct());
		};
	}, [params.batchId]);

	useEffect(() => {
		if (product.fetchProductComplete === APIStatus.FULFILLED && productFormRef.current) {
			if (product.product && product.product.product && product.product.product.id) {
				showInfo("PRODUCT_ALREADY_CREATED");
				navigate(createLocation(Locations.PRODUCT_DETAILS, { batchId: `${product.product.batchId}` }));
			}
			productFormRef.current.setFormData({
				itemId: product.product.item.id,
				itemName: product.product.item.itemName,
				title: product.product.item.title,
				description: product.product.item.description,
				price: product.product.price ?? "",
				quantity: product.product.quantity,
				explanation: "",
				delivery: null,
				headline: "",
				keepStock: null,
				salePeriodStart: dayjs(),
				salePeriodEnd: product.product.item.itemNumberOfActiveDays
					? dayjs().add(product.product.item.itemNumberOfActiveDays, "days")
					: dayjs(),
				relevantLinks: [],
				quantityUnit: product.product.quantityUnit,
			});
			dispatch(clearFetchProduct());
		}
	}, [product.fetchProductComplete]);

	useEffect(() => {
		if (product.createProductComplete === APIStatus.FULFILLED) {
			setLoading(false);
			product.createMessage && showWarning(product.createMessage);
			productFormRef.current?.setLoading(false);
			productSuccessModalRef.current?.openModal();
			dispatch(clearCreateProduct());
		}
		if (product.createProductComplete === APIStatus.REJECTED) {
			setLoading(false);
			productFormRef.current?.setLoading(false);
			dispatch(clearCreateProduct());
		}
	}, [product.createProductComplete]);

	useEffect(() => {
		if (product.uploadAdditionalFileComplete === APIStatus.FULFILLED) {
			setUploadedImg(null);
			dispatch(clearUploadAdditionalFiles());
		}
		if (product.uploadAdditionalFileComplete === APIStatus.REJECTED) {
			setUploadedImg(null);
			dispatch(clearUploadAdditionalFiles());
		}
	}, [product.uploadAdditionalFileComplete]);

	const fileSelected = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (e.target.files && e.target.files.length && validateUploadFile(e.target.files[0])) {
			const payload = {
				file: new FormData(),
				batchId: params.batchId ? params.batchId : "",
			};
			payload.file.append("file", e.target.files[0]);
			setUploadedImg(URL.createObjectURL(e.target.files[0]));
			dispatch(uploadAdditionalFile(payload));
		}
	};

	const productCreate = () => {
		if (productFormRef.current?.validate()) {
			productFormRef.current.setLoading(true);
			setLoading(true);
			const formData = productFormRef.current.getFormData();
			dispatch(
				createProduct({
					itemName: formData.itemName ? formData.itemName : "",
					itemId: formData.itemId ? formData.itemId : 0,
					batchId: product.product.batchId,
					title: formData.title ? formData.title : "",
					description: formData.description ? formData.description : "",
					price: formData.price ? parseInt(formData.price) : 0,
					quantity: formData.quantity ? formData.quantity : "",
					delivery: formData.delivery,
					explanation: formData.explanation,
					headline: formData.explanation,
					keepStock: formData.keepStock,
					publishPeriodStart: dayjs(formData.salePeriodStart).toISOString(),
					publishPeriodEnd: dayjs(formData.salePeriodEnd).toISOString(),
					relevantLinks:
						formData.relevantLinks && formData.relevantLinks.length
							? formData.relevantLinks.map((el) => el.value).join()
							: "",
					quantityUnit: formData.quantityUnit ?? "",
					priceUnit: "円",
				})
			);
		}
	};

	return (
		<div className="h-100 w-100">
			<Header headerText="PRODUCT_CREATE_HEADER" />
			<div className="main_container p-3">
				<div className="h-100 overflow-auto">
					<ProductCarousel images={product.product.images} disable={loading} uploadedImg={uploadedImg} />
					<div className="py-3 text-center">
						<input
							type="file"
							accept="image/jpeg, image/jpg, image/png"
							hidden
							ref={inputRef}
							onChange={fileSelected}
						/>
						{product.product.images && product.product.images.length < Config.maxImagesPerProduct ? (
							<button
								className="primary_border_btn"
								disabled={loading}
								onClick={() => {
									inputRef.current?.click();
								}}
							>
								<I18 tkey="ADD_MORE_PHOTOS" />
							</button>
						) : (
							""
						)}
					</div>
					<ProductForm
						ref={productFormRef}
						disable={loading}
						productActiveDays={
							product.product && product.product.item && product.product.item.itemNumberOfActiveDays
								? product.product.item.itemNumberOfActiveDays
								: 0
						}
					/>
				</div>
			</div>
			<ProductCreateSuccessModal ref={productSuccessModalRef} />
			<Footer
				primaryText="CREATE_BTN"
				secondaryText="CANCEL_BTN"
				showPrimaryLoader={loading}
				primaryBtnDisable={loading}
				secondaryBtnDisable={loading}
				primaryBtnChange={productCreate}
				secondaryBtnChange={() => {
					navigate(Locations.UPLOAD_MODE);
				}}
			/>
		</div>
	);
};
