import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import FarmerSupportedItemForm, {
	FarmerSupportedItemHandles,
} from "../../../components/web/farmer-supported-item-form";
import { APIStatus } from "../../../constants";
import { Locations } from "../../../constants/locations";
import { useAppDispatch, useAppSelector } from "../../../modal/hooks";
import I18 from "../../../plugins/i18";
import { createFarmerSupportedItem } from "../../../services/farmer-supported-items/farmer-supported-items.service";
import { clearCreateFarmerSupportedItem } from "../../../services/farmer-supported-items/farmer-supported-items.slice";

export const FarmerSupportedItemCreate: React.FunctionComponent = () => {
	const [loading, setLoading] = useState<boolean>(false);
	const formRef = useRef<FarmerSupportedItemHandles>(null);
	const farmerSupportedItem = useAppSelector((store) => store.farmerSupportedItem);
	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	useEffect(() => {
		if (farmerSupportedItem.createFarmerSupportedItemComplete === APIStatus.FULFILLED) {
			setLoading(false);
			formRef.current?.setLoading(false);
			dispatch(clearCreateFarmerSupportedItem());
			navigate(Locations.FARMER_SUPPORTED_ITEMS_LIST);
		}
		if (farmerSupportedItem.createFarmerSupportedItemComplete === APIStatus.REJECTED) {
			setLoading(false);
			formRef.current?.setLoading(false);
			dispatch(clearCreateFarmerSupportedItem());
		}
	}, [farmerSupportedItem.createFarmerSupportedItemComplete]);

	const settingsCreate = () => {
		if (formRef.current?.validate()) {
			formRef.current.setLoading(true);
			setLoading(true);
			const formData = formRef.current.getFormData();
			dispatch(
				createFarmerSupportedItem({
					itemName: formData.itemName ? formData.itemName : "",
					id: formData.itemId ? formData.itemId : 0,
					title: formData.title ? formData.title : "",
					description: formData.description ? formData.description : "",
					itemCategory: { id: formData.categoryId },
					itemNumberOfActiveDays: formData.salesPeriod ? formData.salesPeriod : "",
				})
			);
		}
	};

	return (
		<>
			<div className="main_content_container">
				<div className="main_content_section ">
					<div className="main_content_heading">
						<I18 tkey="FARMER_SUPPORTED_ITEMS_CREATE" />
					</div>
					<FarmerSupportedItemForm ref={formRef} />
				</div>
				<div className="main_footer_section d-flex align-items-center justify-content-end">
					<button
						className="primary_border_btn min_width_120 mr-3"
						onClick={() => navigate(Locations.FARMER_SUPPORTED_ITEMS_LIST)}
					>
						<I18 tkey="CANCEL_BTN" />
					</button>
					<button className="primary_btn min_width_120" hidden={loading} onClick={settingsCreate}>
						<I18 tkey="CREATE_BTN" />
					</button>
					<button className="primary_btn min_width_120" hidden={!loading} disabled>
						<div className="button_spinner">
							<div></div>
							<div></div>
							<div></div>
							<div></div>
						</div>
					</button>
				</div>
			</div>
		</>
	);
};
