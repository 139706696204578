import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { Config } from "../../config";
import { PaginationWithNumber } from "../../modal/common";
import { axiosConfig } from "../../utils/util.fns";

type ItemCreatePayload = {
	itemName: string;
	title: string;
	description: string;
	itemConceptName: string;
	itemCategory: { id: number | undefined };
	itemNumberOfActiveDays: string;
};

type ItemUpdatePayload = {
	itemId: string;
	item: ItemCreatePayload;
};

export const fetchItem = createAsyncThunk("items/fetchAll", async (pagination: PaginationWithNumber) => {
	const response = await axios.get(
		Config.apiSecureUrl + `/item/fetchItemList?offset=${pagination.pageNumber}&numResults=${pagination.numResults}`,
		axiosConfig()
	);
	return response.data;
});

export const fetchOneItem = createAsyncThunk("items/fetchOne", async (itemName: string) => {
	const response = await axios.get(Config.apiSecureUrl + `/item/${itemName}`, axiosConfig());
	return response.data;
});

export const fetchOneItemById = createAsyncThunk("items/fetchOneById", async (itemId: string) => {
	const response = await axios.get(Config.apiSecureUrl + `/item/fetch/${itemId}`, axiosConfig());
	return response.data;
});

export const createItem = createAsyncThunk("item/create", async (product: ItemCreatePayload) => {
	const response = await axios.post(Config.apiSecureUrl + "/item/", product, axiosConfig());
	return response.data;
});

export const updateItem = createAsyncThunk("item/update", async (item: ItemUpdatePayload) => {
	const response = await axios.put(Config.apiSecureUrl + `/item/${item.itemId}`, item.item, axiosConfig());
	return response.data;
});

export const fetchCategory = createAsyncThunk("items/fetchCategory", async (pagination: PaginationWithNumber) => {
	const response = await axios.get(
		Config.apiSecureUrl +
			`/product/productCategory?offset=${pagination.pageNumber}&numResults=${pagination.numResults}`,
		axiosConfig()
	);
	return response.data;
});
