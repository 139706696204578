import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import ItemForm, { ItemFormHandles } from "../../../components/web/item-form";
import { APIStatus } from "../../../constants";
import { Locations } from "../../../constants/locations";
import { useAppDispatch, useAppSelector } from "../../../modal/hooks";
import I18 from "../../../plugins/i18";
import { createItem } from "../../../services/item/item.service";
import { clearCreateItem } from "../../../services/item/item.slice";
import "./item-create.scss";

export const ItemCreate: React.FunctionComponent = () => {
	const [loading, setLoading] = useState<boolean>(false);
	const itemFormRef = useRef<ItemFormHandles>(null);
	const item = useAppSelector((store) => store.item);
	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	useEffect(() => {
		if (item.createItemComplete === APIStatus.FULFILLED) {
			setLoading(false);
			itemFormRef.current?.setLoading(false);
			dispatch(clearCreateItem());
			navigate(Locations.ITEM_LIST);
		}
		if (item.createItemComplete === APIStatus.REJECTED) {
			setLoading(false);
			itemFormRef.current?.setLoading(false);
			dispatch(clearCreateItem());
		}
	}, [item.createItemComplete]);

	const itemCreate = () => {
		if (itemFormRef.current?.validate()) {
			itemFormRef.current.setLoading(true);
			setLoading(true);
			const formData = itemFormRef.current.getFormData();
			dispatch(
				createItem({
					itemName: formData.itemName ? formData.itemName.trim() : "",
					itemConceptName: formData.itemConceptName ? formData.itemConceptName.trim() : "",
					title: formData.title ? formData.title : "",
					description: formData.description ? formData.description : "",
					itemCategory: { id: formData.category.categoryId },
					itemNumberOfActiveDays: formData.salesPeriod ? formData.salesPeriod : "",
				})
			);
		}
	};

	return (
		<>
			<div className="main_content_container">
				<div className="main_content_section ">
					<div className="main_content_heading">
						<I18 tkey="ITEM_CREATE_HEADER" />
					</div>
					<ItemForm ref={itemFormRef} />
				</div>
				<div className="main_footer_section d-flex align-items-center justify-content-end">
					<button className="primary_border_btn min_width_120 mr-3" onClick={() => navigate(Locations.ITEM_LIST)}>
						<I18 tkey="CANCEL_BTN" />
					</button>
					<button className="primary_btn min_width_120" hidden={loading} onClick={itemCreate}>
						<I18 tkey="CREATE_BTN" />
					</button>
					<button className="primary_btn min_width_120" hidden={!loading} disabled>
						<div className="button_spinner">
							<div></div>
							<div></div>
							<div></div>
							<div></div>
						</div>
					</button>
				</div>
			</div>
		</>
	);
};
