import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { Config } from "../../config";
import { ProductStatus } from "../../constants";
import { PaginationWithNumber } from "../../modal/common";
import { axiosConfig, axiosMultiPartConfig } from "../../utils/util.fns";

type ProductCreatePayload = {
	itemName: string;
	itemId: number;
	quantity: string;
	price: number;
	title: string;
	description: string;
	batchId: number | null;
	delivery: number | null;
	keepStock: number | null;
	headline: string;
	explanation: string;
	publishPeriodStart: string;
	publishPeriodEnd: string;
	relevantLinks: string;
	quantityUnit: string;
	priceUnit: string;
};

type ProductSavePayload = {
	productId: number;
	product: ProductCreatePayload;
};

export type FileUploadPayload = {
	batchId: string;
	file: FormData;
};

export const fetchProduct = createAsyncThunk("product/fetchOne", async (batchId: string) => {
	const response = await axios.get(Config.apiSecureUrl + `/batch/${batchId}`, axiosConfig());
	return response.data;
});

export const fetchProductById = createAsyncThunk("product/fetchById", async (id) => {
	const response = await axios.get(Config.apiSecureUrl + `/product/${id}`, axiosConfig());
	return response.data;
});

export const uploadFile = createAsyncThunk("file/upload", async (file: FormData) => {
	const response = await axios.post(Config.apiSecureUrl + "/item/predict", file, axiosMultiPartConfig());
	return response.data;
});

export const fetchAllProducts = createAsyncThunk("product/fetchAll", async (pagination: PaginationWithNumber) => {
	const response = await axios.get(
		Config.apiSecureUrl + `/product?offset=${pagination.pageNumber}&numResults=${pagination.numResults}`,
		axiosConfig()
	);
	return response.data;
});

export const createProduct = createAsyncThunk("product/create", async (product: ProductCreatePayload) => {
	const response = await axios.post(Config.apiSecureUrl + "/product", product, axiosConfig());
	return response.data;
});

export const uploadAdditionalFile = createAsyncThunk("file/upload/additional", async (payload: FileUploadPayload) => {
	const response = await axios.put(
		Config.apiSecureUrl + `/item/addImages/${payload.batchId}`,
		payload.file,
		axiosMultiPartConfig()
	);
	return response.data;
});

export const saveProduct = createAsyncThunk("product/save", async (product: ProductSavePayload) => {
	const response = await axios.put(
		Config.apiSecureUrl + `/product/${product.productId}`,
		product.product,
		axiosConfig()
	);
	return response.data;
});

export const deleteProduct = createAsyncThunk("product/delete", async (productId: number) => {
	const response = await axios.delete(Config.apiSecureUrl + `/product/${productId}`, axiosConfig());
	return response.data;
});

export const removeImage = createAsyncThunk("file/delete", async (imageId: number) => {
	const response = await axios.delete(Config.apiSecureUrl + `/item/images/${imageId}`, axiosConfig());
	return response.data;
});

export const markProductAsSold = createAsyncThunk("product/markAsSold", async (productId: number) => {
	const response = await axios.post(Config.apiSecureUrl + `/product/markAsSold/${productId}`, {}, axiosConfig());
	return response.data;
});

export const getYahooProducts = createAsyncThunk("yahoo/product/list", async (pagination: PaginationWithNumber) => {
	const response = await axios.get(
		Config.apiSecureUrl +
			`/product/yahooJapan/fetch?offset=${pagination.pageNumber}&numResults=${pagination.numResults}`,
		axiosConfig()
	);
	return response.data;
});

export const fetchPublishedProducts = createAsyncThunk(
	"product/published/list",
	async (pagination: PaginationWithNumber) => {
		const response = await axios.get(
			Config.apiSecureUrl +
				`/product?offset=${pagination.pageNumber}&numResults=${pagination.numResults}&status=${ProductStatus.PUBLISHED}`,
			axiosConfig()
		);
		return response.data;
	}
);
