import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Locations } from "../../../constants/locations";
import { PaginationWithNumber } from "../../../modal/common";
import { useAppDispatch, useAppSelector } from "../../../modal/hooks";
import I18 from "../../../plugins/i18";
import { fetchItem } from "../../../services/item/item.service";
import { clearItems } from "../../../services/item/item.slice";
import { createLocation } from "../../../utils/util.fns";

export const ItemListTable: React.FunctionComponent = () => {
	const [pagination, setPagination] = useState<PaginationWithNumber>({ pageNumber: 0, numResults: 100 });
	const item = useAppSelector((store) => store.item);
	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	useEffect(() => {
		return () => {
			dispatch(clearItems());
		};
	}, []);

	useEffect(() => {
		dispatch(
			fetchItem({
				pageNumber: pagination.pageNumber,
				numResults: pagination.numResults,
			})
		);
	}, [pagination.pageNumber]);

	const pageScrolled = (e: React.UIEvent<HTMLDivElement>) => {
		if (item.items.length < item.itemCount) {
			const tableViewHeight = e.currentTarget.offsetHeight;
			const tableScrollHeight = e.currentTarget.scrollHeight;
			const scrollLocation = e.currentTarget.scrollTop;
			const buffer = 200;
			const limit = tableScrollHeight - tableViewHeight - buffer;
			if (scrollLocation > limit) {
				setPagination((prevState) => ({ ...prevState, pageNumber: ++prevState.pageNumber }));
			}
		}
	};

	return (
		<div className="h-100 overflow-auto position-relative" onScroll={pageScrolled}>
			<table className="custom_primary_table">
				<thead>
					<tr>
						<th>
							<div className="custom_primary_table_th font_weight_regular text-center">
								<I18 tkey="PRODUCT_TABLE_HEADER" />
							</div>
						</th>
						<th>
							<div className="custom_primary_table_th font_weight_regular text-center">
								<I18 tkey="TITLE_TABLE_HEADER" />
							</div>
						</th>
						<th>
							<div className="custom_primary_table_th font_weight_regular">
								<I18 tkey="DESCRIPTION_TABLE_HEADER" />
							</div>
						</th>
						<th>
							<div className="custom_primary_table_th font_weight_regular text-center">
								<I18 tkey="ACTION_TABLE_HEADER" />
							</div>
						</th>
					</tr>
				</thead>
				<tbody>
					{item &&
						item.items.map((data) => (
							<tr key={data.id}>
								<td>
									<div className="custom_primary_table_td line_height_30 text-center">{data.itemName}</div>
								</td>
								<td>
									<div className="custom_primary_table_td line_height_30 text-center">{data.title}</div>
								</td>
								<td>
									<div className="custom_primary_table_td line_height_30">{data.description}</div>
								</td>
								<td>
									<div className="custom_primary_table_td line_height_30 text-center">
										<button
											className="primary_btn"
											onClick={() => navigate(createLocation(Locations.ITEM_DETAILS, { itemId: `${data.id}` }))}
										>
											<I18 tkey="VIEW_OR_EDIT_BTN" />
										</button>
									</div>
								</td>
							</tr>
						))}
				</tbody>
			</table>
			{(!item.items || !item.items.length) && !item.itemListLoading ? (
				<div className="shade_loading">
					<I18 tkey="NO_ITEMS_DATA" />
				</div>
			) : null}
			{item.itemListLoading ? (
				<div className="shade_loading">
					<div className="button_spinner">
						<div></div>
						<div></div>
						<div></div>
						<div></div>
					</div>
				</div>
			) : null}
		</div>
	);
};
